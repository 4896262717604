<template>
  <center style="padding-top: 80px;">
    <div class="topBar">
      <img :src="organizationLogo" style="height: 40px;"/>
    </div>
      <div class="o-page c-login-page">
        <div class="c-login-page__content">
          <div></div>
          <div><img src="../assets/imgs/form-header.png" style="max-width: 320px;margin-bottom: 20px;"></div>
          <div class="logo_container"><img :src="organizationLogo" /></div>
          <div class="login_form" @keydown.enter="submitForm">
              <div v-if="log != ''" class="error">
                  <span style="white-space: pre-line">{{log}}</span>
              </div>
              <div class="o-form-group">
                <input v-model="username" class="form-control" name="login" type="text" :placeholder="$t('authN.email')" value="" required="" autofocus="">
              </div>
              <div class="o-form-group">
                <input v-model="password" class="form-control" name="password" type="password" :placeholder="$t('authN.password')" value="" required="">
              </div>
              <div class="o-form-group">
                <button v-on:click="submitForm" class="baseButton">
                  {{$t('authN.button.login')}}
                </button>
              </div>
              <br>
              <p>
                <router-link class="nav-link" to="/password-recovery" style="color:#999; text-decoration: underline;">
                  {{$t('authN.login.forgot')}}
                </router-link>
              </p>
          </div>
          <br>
          <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
        </div>
      </div>
  </center>
</template>

<script>
import Vue from 'vue'
import BaseInput from 'src/components/Inputs/BaseInput.vue'
import BaseButton from 'src/components/BaseButton.vue'
import LoginService from 'src/services/login'
import Utils from "src/mixins/utils";
import defaultLogo from 'src/assets/imgs/default/drumwave_logo.png'

export default {
  mixins: [Utils],
  components: {
    BaseInput,
    BaseButton
  },
  data() {
    return {
      username: '',
      password: '',
      log: '',
      organizationLogo: '',
      organizationPublicId: ''
    }
  },
  methods: {
    async submitForm() {
      try {
        this.log = ''
        if (this.username == '') {
          this.log += this.$t('authN.login.error.emailEmpty');
        }
        if (this.password == '') {
          if (this.log.length > 0) {
            this.log += '\n';
          }
          this.log += this.$t('authN.login.error.passwordEmpty');
        }
        if (this.log.length > 0) {
          return;
        }

        await LoginService.authenticate(this.organizationPublicId, this.username, this.password);

        const lastURL = this.getLastURL();
        if (lastURL) {
          this.removeLastURL();
          window.location = lastURL;
          window.location.reload();

        } else {
          window.location = '/';
        }

      } catch (err) {
        console.error('API ERROR', err, err.stack);
        this.log = err[1];
      }
    }
  },
  async beforeMount() {
    const subdomain = window.location.host.split('.')[0];

    try {
      const org = await LoginService.getOrganization(subdomain);
      this.organizationLogo = org.loginLogoUrl || defaultLogo;
      this.organizationPublicId = org.publicId;

    } catch (err) {
      console.error(err)
      this.$router.push({name: 'NotFound'});
    }
  }
}
</script>

<style scoped>

.topBar{
  position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    background-color: #252223;
    z-index: 100;
    padding: 5px;
    text-align: left;
}

.logo_container img{
      max-width: 400px;
    max-height: 200px;
}

.logo_container{
     background-color: #ddd;
    width: min-content;
    margin: 10px 40px 40px 40px;
    border-radius: 20px;
    padding: 10px;
    display: none;
}

.login_form{
  width: 300px;
  font-family: 'titillium_webregular';
}

.login_form input {
  margin-bottom: 20px;
  font: Arial, Helvetica, sans-serif;
  border: 1px solid #ccc;
}


.login_form input:hover{
  border-color: #333;
}

.login_form input:focus{
  border-color: #333;
}

.login_form input::placeholder {
  color: #999;
}

.baseButton{
  display: inline-block;
  min-width: fit-content;
  min-width: -moz-max-content;
  width: 10px;
  margin: 0.5em;
  padding: 1em 2em;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: gray;
  font-size: 1em;
  line-height: 1;
  transition: 0.3s;
}
.baseButton:hover{
  background: #e8e5e5;
  color: #555;
}

.error {
  padding: 5px;
  background-color: #f44336;
  color: white;
  margin-bottom: 20px !important;
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    padding="30px"
  >
    <v-card>
      <v-card-title class="headline">
        <div class="col-12 typeIcon">
          <img src="src/assets/imgs/dialog_icon_money.png" style="max-height: 70px;" alt="">
        </div>
        <div class="col-12 text" style="margin-top: 20px">
          {{ title }}
        </div>
      </v-card-title>

      <v-card-title primary title class="infoText">
        <div class="col-12 text">
          <p>
            {{ information }}
          </p>
        </div>
      </v-card-title>

      <v-card-title primary-title class="slotForm">
        <div>
          <slot name="form">
          </slot>
        </div>
      </v-card-title>

      <v-card-actions class="actions-buttons">
        <v-btn text @click="closeCallBack">{{closeLabel}}</v-btn>
        <v-btn text @click="confirmCallBack">{{confirmLabel}}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormDialog",
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    updateData(item) {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    }
  },
  props: {
    title: String,
    confirmLabel: String,
    closeLabel: String,
    information: String,
    confirmCallBack: Function,
    closeCallBack: Function
  }
}
</script>

<style scoped>
.text {
  text-align: center;
}
.typeIcon {
  text-align: center;
}
.slotForm {
  padding-top: 0px;
}
.infoText {
  padding-bottom: 0px;
}
.actions-buttons, .slotForm {
  align-content: center;
  justify-content: center;
}
.headline {
  padding-top: 30px;
}
.actions-buttons {
  padding-bottom: 15px;
}
</style>
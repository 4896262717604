<template>
  <div style="position: relative;width: min-content;" @mouseleave="hideOptionsPanel"  @mousewheel="hideOptionsPanel">
    <div class="sdasContainer">

      <!-- Just for data SETS -->
      <span v-if="item.type=='dataset'">
        <div v-for="(sda, index) in standardDataApps" class="iconContainer iconContainerRound" @mouseenter="displayOptionsPanel($event, sda)" >
          <a :href="sda.urlView" target="_blank" rel="noopener">
            <img class="dataAppIcon" :src="sda.icon" :title="sda.description" />
          </a>
        </div>
      </span>

      <!-- Just for data PRODUCTS -->
      <div v-if="item.type=='view'" class="iconContainer iconContainerRound">
        <!-- Link to View -->
        <a :href="base_view_url+item.publicId" target="_blank" rel="noopener">
          <img class="dataAppIcon" src="../assets/imgs/dataApps/launch.png" :title="this.$t('miscellania.data_apps.launch_dataproduct')"/>
        </a>
      </div>
    </div>
    <div class="arrow_box" v-if="showOptions" ref="tooltip">
      <div class="tooltipThumbContainer" :style="{ 'background-image': 'url('+overedSda.thumbnail+')' }"></div>
      <div style="padding: 10px;">
        <div class="tooltipTitle">{{overedSda.name}}</div>
        <div>
          {{overedSda.description}}
        </div>
        <div class="tooltipButtons">
          <a :href="overedSda.urlStudio" target="_blank" rel="noopener">
            <img src="../assets/imgs/edit_icon.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Utils from '../mixins/utils';

  export default {
    props: {
      item: {
        type: Object
      },
      transferCallback: Function,
      canTransfer: Boolean
    },
    mixins: [Utils],
    data() {
      return {
        base_view_url: '/#/views/',
        standardDataApps: this.getStandardDataApps(this.item),
        showOptions: false,
        overedSda: null
      };
    },
    methods:{
      displayOptionsPanel(e, sda) {
        if (this.standardDataApps && this.standardDataApps.length > 0){
          this.showOptions = true;
          this.overedSda = sda;

          let scoper = this;
          this.$nextTick(function () {
            var el = e.target;
            var rect = el.getBoundingClientRect();
            let tooltip = $( scoper.$refs.tooltip );
            let height = tooltip.height();
            tooltip.css({
              top: rect.top-height-3,
              left: rect.right-245
            });
          });
        }
      },
      hideOptionsPanel(){
        this.showOptions = false;
      },
      getStandardDataApps(item) {
        let result = [];

        if (item.standardDataApps) {
          const environmentHost = window.location.host.split(".")[1];
          const hasStudioAccess = this.isSectionAllowed('projects');

          item.standardDataApps.forEach(el => {
            let thumbnail = el.thumbnail.replace('http://moebio.com/data/', 'https://resources.lichen.com/');

            let sda = {
              icon: thumbnail.split('.png').join('20.png'),
              thumbnail: thumbnail.split('.png').join('BIG.png'),
              name: el.name,
              description: el.description,
              urlStudio: `https://studio.${environmentHost}.com/${el.project}#` + btoa(`[**dataset_id**->${item.publicId}]`)
            };

            if (el.openMode == 'studio') {
              if (!hasStudioAccess) {
                return;
              }
              sda.urlView = `https://studio.${environmentHost}.com/${el.project}#` + btoa(`[**dataset_id**->${item.publicId}]`);

            } else {
              sda.urlView = `/#/datasets/${item.publicId}/${el.id.toLowerCase()}`;
            }

            result.push(sda);
          });
        }

        return result;
      }
    }
  }
</script>

<style>
  .dataAppIcon{
    border-radius: 20px;
    margin: 0px 2px;
    width: 20px;
    height: 20px;
  }

  .shareBtn{
    cursor: pointer;
    float: right;
    padding: 5px 0px;
    opacity: 0.3;
  }
  .shareBtn:hover{
    opacity: 1;
  }

  .disabledDataApp{
    opacity: 0.2;
  }

  .iconContainer{
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 1px;
  }

  .iconContainerRound{
    margin-right: -1px;
  }

  .arrow_box {
    background: #ffffff;
    border: 1px solid #949494;
    border-radius: 5px;
    padding: 0px;
    position: fixed;
    z-index: 1000;
    width: 250px;
    box-shadow: 2px 4px 8px -4px #888;
  }
  .arrow_box:after, .arrow_box:before {
    top: 100%;
    right: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow_box:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 5px;
    margin-left: -5px;
  }
  .arrow_box:before {
    border-color: rgba(148, 148, 148, 0);
    border-top-color: #949494;
    border-width: 6px;
    margin-left: -6px;
  }

  .tooltipTitle{
    font-size: 16px;;
    font-weight: bold;
    margin-bottom: 2px;

  }
  .tooltipThumbContainer{
    width: 100%;
    height: 170px;
    overflow: hidden;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    border-radius: 4px;
  }

  .tooltipButtons{
    text-align: right;
  }
  .tooltipButtons img{
    width: 16px;
    height: 16px;
    opacity: 0.8;
    cursor: pointer;
  }

  .tooltipButtons img:hover{
    opacity: 1;
  }


  .sdasContainer{
    cursor: pointer;
    width: 145px;
    margin: 10px 0px;
  }
</style>

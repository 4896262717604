const api = require('./api-caller')

const baseEndpoint = '/data-products'

module.exports = {
  createDataProduct: (body) => api(baseEndpoint, 'POST', body),
  getDataProducts: () => api(baseEndpoint, 'GET'),
  getDataProductsForExchange: () => {
    const endpoint = `${baseEndpoint}/exchange`
    return api(endpoint, 'GET')
  },
  updateDataProduct: (body, id) => {
    const endpoint = `${baseEndpoint}/${id}`
    return api(endpoint, 'PATCH', body)
  },
  removeDataProduct: (id) => {
    const endpoint = `${baseEndpoint}/${id}`
    return api(endpoint, 'DELETE')
  },
  buyDataProduct: (id) => {
    const endpoint = `${baseEndpoint}/${id}/contract`
    return api(endpoint, 'PUT')
  }
}

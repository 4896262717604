var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "object",
      {
        staticStyle: {
          overflow: "hidden",
          "overflow-x": "hidden",
          "overflow-y": "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          left: "0px",
          right: "0px",
          bottom: "0px"
        },
        attrs: { data: _vm.appURL, width: "100%", height: "100%" }
      },
      [
        _c("embed", {
          attrs: { src: _vm.appURL, width: "100%", height: "100%" }
        }),
        _vm._v(_vm._s(_vm.$t("datasources.embed_error")) + "\n    ")
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
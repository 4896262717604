var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "nav nav-mobile-menu" }, [
    _c("li", { staticClass: "nav-item" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("a", { staticClass: "nav-link", attrs: { href: "#/settings" } }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("settings.title")))])
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: { href: "#" },
          on: { click: _vm.logout }
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("signOut")))])]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "nav-link", attrs: { href: "#/dwallet" } }, [
      _c("span", [_vm._v("dWallet")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
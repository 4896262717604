<template>
  <v-app>
  <div class="content" id="datawallets">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('datawallets.title')}}</div>
              <p class="card-category">{{$t('datawallets.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
              </form>
            </div>
            <BaseButton v-if="isCreateAllowed('data-wallets')" :buttonLabel="$t('datawallets.create')" style="padding: 12px 10px; margin: 0" addonLeftIcon="fa fa-folder-o" :onClick="callCreate"></BaseButton>
            <DataTable :headers="columns" :items="datawallets" :editDelete="true" page="datawallets" :linkBuilder="linktoStudio" linkTarget="_self" :editCallback="editCallback"></DataTable>
          </card>
        </div>
      </div>
    </div>
    <DelModal
      :onClickQuit="quitDelete"
      :onClickDelete="confirmDelete"
      :title="$t('datawallets.delete')"
    ></DelModal>
  </div>
  </v-app>
</template>


<script>
import Vue from "vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import DatawalletService from "src/services/datawallet";
import DataTable from "src/components/DataTable.vue";
import DelModal from "src/components/DelModal.vue";
import Swal from "sweetalert2";
import Utils from "src/mixins/utils";

export default {
  mixins: [Utils],
  components: {
    Card,
    BaseInput,
    BaseButton,
    DataTable,
    DelModal
  },
  data() {
    return {
      columns: [
        {
          text: this.$t("datawallets.table.name"),
          align: "left",
          value: "name"
        },
        { text: this.$t("datawallets.table.creator"), value: "userUsername" },
        {
          text: this.$t("datawallets.table.countDatasets"),
          value: "datasetsCount"
        },
        { text: this.$t("datawallets.table.status"), value: "status" }
      ],
      datawallets: [],
      data: [],
      editDelete: true
    };
  },
  methods: {
    async searchInputValue(e) {
      await this.loadDatawallets();
      let searchWord = this.$refs.searchInput.getValue().toUpperCase();
      this.datawallets = this.datawallets.filter(row =>
        row.name.toUpperCase().includes(searchWord)
      );
    },
    async loadDatawallets() {
      try {
        let ds = await DatawalletService.listDataWallets();
        this.data = ds;
        this.datawallets = ds;
      } catch (err) {
        console.error(err)
        Swal.fire({
          title: "Error!",
          text: "Datawallets not found",
          type: "error",
          confirmButtonText: "Ok"
        });
      }
    },

    callCreate() {
      this.$router.push({name: 'DatawalletCreate'});
    },
    editCallback(item) {
      this.$router.push({name: "DatawalletEdit", params: {publicId: item.publicId} });
    },
    quitDelete() {
      document.getElementById("deleteModal").classList.add("invisible");
    },
    async confirmDelete() {
      try {
        Swal.fire({
          title: 'Deleting',
          onOpen: () => Swal.showLoading()
        });
        let results = [];
        this.datawallets.map(async (row, index) => {
          if(row.publicId != document.getElementById("elementToBeDeleted").dataset.publicId) {
            results.push(this.datawallets[index]);
          }
          else {
            let res = await DatawalletService.deleteDataWallet(this.datawallets[index].publicId);
            Swal.fire({
              title: this.$t('datawallets.deleted'),
              type: 'success',
              showConfirmButton: false,
              timer: 1000,
              onBeforeOpen: () => Swal.hideLoading()
            });
            await this.loadDatawallets();
          }
          document.getElementById("deleteModal").classList.add("invisible");
        })
      } catch (err) {
        let error = err[1].erros[0];
        console.error(err)
        Swal.fire({
          title: 'Error!',
          text: error,
          type: 'error',
          confirmButtonText: 'Ok',
          onBeforeOpen: () => Swal.hideLoading()
        });
      }
    },
    onSelectedOrganizationChanged: function(e){
      this.loadDatawallets();
    },
    linktoStudio( header, propsItem){
      if(header != 'name') return;
      return '#/dataexplorer/wallets/edit/' + propsItem['publicId'];
    },
    quitDelete() {
      document.getElementById("deleteModal").classList.add("invisible");
    },
  },
  async mounted() {
    await this.loadDatawallets();
  },
  created() {
    var scoper = this;
    window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  },
  beforeDestroy() {
    window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  }
};
</script>


<style>
.container-search-bar-and-create-button {
  margin: 0px;
}

.searchContainer {
  float: right;
  margin-top: -50px;
  max-width: 300px;
  margin-bottom: 10px;
}
</style>

<template>
  <v-app>
    <div class="content screen-area" id="settings">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                        <h4 class="card-title mb-3">{{$t('settings.title')}} - {{$t('settings.security.title')}}</h4>
                        <form v-on:submit.prevent action="" method="post">
                            <div class="mb-3">
                                <label class="control-label">{{$t('settings.security.oldPassword')}}</label>
                                <input v-model="oldPassword" type="password" id="fieldOldPassword" name="oldPassword" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label">{{$t('settings.security.newPassword')}}</label>
                                <input v-model="newPassword" type="password" id="fieldNewPassword" name="newPassword" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label">{{$t('settings.security.confirmNewPassword')}}</label>
                                <input v-model="confirmPassword" type="password" id="fieldConfirmPassword" name="confirmPassword" class="form-control medium" />
                            </div>
                            <BaseButton style="float: left; color: green; border-color: green; margin-left: 0px;" class="btn btn-primary btn-md btn-block" :buttonLabel="$t('settings.security.update')" buttonType="submit" :onClick="updatePassword"></BaseButton>
                        </form>
                    </card>
                </div>
            </div>
        </div>
    </div>
  </v-app>
</template>

<script>
import BaseButton from 'src/components/BaseButton.vue'
import UserService from 'src/services/user'
import Utils from 'src/mixins/utils'
import Swal from 'sweetalert2'
export default {
    mixins: [Utils],
    components: {
        'BaseButton': BaseButton,
    },
    data() {
        return {
            confirmPassword: '',
            newPassword: '',
            oldPassword: ''
        }
    },
    methods: {
        async updatePassword() {
            try {
                Swal.fire({
                    title: 'Submitting',
                    onOpen: () => {
                        Swal.showLoading();
                    }
                });
                const userPublicId = this.getUserPublicId();
                const formJSON = {
                    confirmNewPassword: this.confirmPassword,
                    newPassword: this.newPassword,
                    oldPassword: this.oldPassword
                };
                const updPassword = await UserService.updatePassword(userPublicId, formJSON);
                Swal.fire({
                    title: this.$t('settings.security.success'),
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
                location.reload();
            } catch (error) {
                const message = error[1].errors[0];
                console.error(error)
                Swal.fire({
                    title: 'Error!',
                    text: message,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
    #settingsContent {
        padding: 0px 30px;
    }
</style>>
const api = require('./api-caller')

module.exports = {
  getContracts: () => {
    const endpoint = '/contracts/'
    return api(endpoint, 'GET')
  },
  updateStatus: (body, id) => {
    const endpoint = `/contracts/${id}`
    return api(endpoint, 'PATCH', body)
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "foldersDisplayConteiner" }, [
    _c("h4", { staticClass: "acessedFolder" }, [
      _c(
        "span",
        {
          staticClass: "folderName",
          attrs: { id: _vm.folderId },
          on: { click: _vm.onClick }
        },
        [_vm._v(_vm._s(_vm.acessedFolder))]
      ),
      _vm._v(" "),
      _c("i", { staticClass: "fa fa-chevron-right" }),
      _vm._v(" \n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
    <div class="content" id="createWallet">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">

              <template slot="header">
                <div class="page-title">{{$t('walletCreate.title')}} </div>
              </template>

              <div class="row panelsContainer">

                <div class="col-md-5 col-sm-12 commonPanel DetailsPanel">
                  <div class="row panelRow">
                    <div class="col-m-10 col-sm-12 panelTitle">{{ $t('walletCreate.details.title') }}</div>
                  </div>
                  <div class="panelBody">
                    <div class="container">
                      <div class="row">
                        <div class="col-6 col-md-4 labelRow">
                          <label class="control-label">{{ $t('walletCreate.details.name') }}:</label>
                        </div>
                        <div class="col-12 col-md-8 panelInput">
                          <input type="text" class="form-control pull-right" v-model="walletName" />
                        </div>
                        <div class="col-6 col-md-4 labelRow">
                          <label class="control-label">{{ $t('walletCreate.details.description') }}:</label>
                        </div>
                        <div class="col-12 col-md-8  panelInput">
                          <input type="text" class="form-control pull-right" v-model="walletDescription" />
                        </div>
                        <div class="col-6 col-md-4 labelRow">
                          <label class="control-label">{{ $t('walletCreate.details.status') }}:</label>
                        </div>
                        <div class="col-12 col-md-8 panelInput">
                          <v-select
                            :items="$t('walletCreate.status')"
                            v-model="statusSelected"
                            solo
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2"></div>

                <div class="col-md-5 col-sm-12 commonPanel pull-right" v-if="false">
                  <div class="row panelRow">
                    <div class="col-md-10 col-sm-12 panelTitle">{{ $t('walletCreate.subscription.title') }}</div>
                  </div>
                  <div class="panelBody">
                    <div class="container">
                      <div class="row panelRow">
                      </div>
                    </div>
                  </div>
                  <div class="panelFooter">
                    <div class="row">
                      <div class="col-md-9 col-sm-12 createNew">+ {{$t('walletCreate.add_new')}}</div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row panelsContainer">
                <div class="col-md-12 commonPanel">
                  <div class="row panelRow">
                    <div class="col-10 panelTitle">{{ $t('walletCreate.datasets.title') }}</div>
                    <div class="col-2 panelCounter">{{ totalDatasets }}</div>
                  </div>
                  <div class="panelBody">
                    <v-data-table :items="datasets" class="col-md-12 col-sm-12" item-key="publicId" v-model="selected" :headers="$t('walletCreate.datasets.headers')">
                      <template v-slot:items="props">
                        <td>
                          <v-checkbox
                            v-model="props.selected" style="height: 22px;"
                          ></v-checkbox>
                        </td>
                        <td>{{ props.item.name }}</td>
                        <td>{{ props.item.type }}</td>
                        <td>{{ props.item.source }}</td>
                      </template>
                    </v-data-table>
                    <!-- <div class="row panelRow columnNames">
                      <div class="col-md-2">Name</div>
                      <div class="col-md-2">Source</div>
                      <div class="col-md-2">Rows</div>
                      <div class="col-md-2">Columns</div>
                      <div class="col-md-2">Dimensions</div>
                      <div class="col-md-2">Data Products</div>
                    </div> -->
                  </div>
                  <div class="panelFooter">
                    <div class="row">
                      <BaseButton style="float: left;" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('walletCreate.create')" buttonType="submit" :onClick="submit"></BaseButton>
                    </div>
                  </div>
                </div>
              </div>

            </card>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import BaseButton from '../components/BaseButton.vue'
import DatasetService from '../services/dataset'
import WalletService from '../services/datawallet'
import Swal from 'sweetalert2'
export default {
  components: {
        'BaseButton': BaseButton,
  },
  methods: {
    async loadDatasets() {
      try {
        this.datasets = await DatasetService.getDatasets()
        this.totalDatasets = this.datasets.length
      } catch (error) {
        console.error(error)
        Swal.fire({
          title: 'Error!',
          text: 'Datasets not found',
          type: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    backToDataExplorer() {
      this.$router.push({name: 'Dataexplorer'})
    },
    async submit() {
      try {
        Swal.fire({
            title: 'Submitting',
            onOpen: () => {
                Swal.showLoading();
            }
        });
        const body = {
          name: this.walletName,
          description: this.walletDescription,
          status: this.statusSelected,
          datasets: this.selected.map(x => x.publicId)
        }
        const datawallet = await WalletService.createDataWallet(body)
        this.backToDataExplorer()
        Swal.fire({
            title: 'Wallet Created!',
            type: 'success',
            showConfirmButton: false,
            timer: 1000,
            onBeforeOpen: () => {
                Swal.hideLoading();
            }
        });
      } catch (error) {
        const err = error[1].errors[0]
        console.error(error)
        Swal.fire({
            title: 'Error!',
            text: err,
            type: 'error',
            confirmButtonText: 'Ok',
            onBeforeOpen: () => {
                Swal.hideLoading();
            }
        });
      }

    }
  },
  async mounted() {
    await this.loadDatasets()
  },
  data() {
    return {
      statusSelected: '',
      walletName: '',
      walletDescription: '',
      datasets: [],
      totalDatasets: 0,
      selected: []
    }
  }
}
</script>

<style scoped>
  #createWallet{
      color: #333;
  }

  .panelsContainer{
    position: relative;
    padding: 20px;
  }

  .DetailsPanel{
    /* max-width: 400px; */
  }

  .marketplacePanel{
    /* max-width: 400px; */
  }

  .columnNames{
    font-weight: bold;
  }

  .panelTitle{
    padding-left: 0px;
  }

  .labelRow {
    padding-left: 0px;
  }

  .panelCounter{
    padding: 10px 23px 0px 0px;
    text-align: right;
  }

  .panelFooter{

  }

  .panelInput {
    bottom: 10px;
    padding: 5px 0px 5px 0px;
  }

  .panelRow{
    border-bottom: solid 1px #ccc;
    padding: 10px 0px 10px 0px;
  }

  .createNew{
    color: #1dc7ea;
  }

  .seeMore{
    padding: 0px 23px 0px 0px;
    text-align: right;
  }
</style>

<template>
  <v-app>
  <div class="content" id="auditaccount">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('auditData.title')}}</div>
              <p class="card-category">{{$t('auditData.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
              </form>
            </div>
            <DataProductsSearch :onChange="searchInputValue" :advancedFilters="advancedFilters"/>
            <div style="clear: both;"/>

            <div style="margin-top: -40px;">
              <v-tabs v-model="selectedTab" slider-color="#EC008C">
                <v-tab>
                  <div class="tabName">Data Sources</div>
                  <div v-if="datasources_tableDataProvider"  class="tabCounter">{{datasources_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab>
                  <div class="tabName">Datasets</div>
                  <div v-if="datasets_tableDataProvider" class="tabCounter">{{datasets_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab>
                  <div class="tabName">Data Apps</div>
                  <div v-if="views_tableDataProvider" class="tabCounter">{{views_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab v-if="isSectionAllowed('dataProductsManagement')">
                  <div class="tabName">{{$t('dataProduct.published')}}</div>
                  <div v-if="submissions_tableDataProvider" class="tabCounter">{{submissions_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab v-if="isSectionAllowed('dataProductsManagement')">
                  <div class="tabName">{{$t('dataProduct.purchased')}}</div>
                  <div v-if="purchases_tableDataProvider" class="tabCounter">{{purchases_tableDataProvider.length}}</div>
                </v-tab>
              </v-tabs>
            </div>

            <div v-if="selectedTab==0">
              <div v-if="searchWord && !datasources_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && datasources_tableDataProvider.length)"
                :headers="columns_in_datasources"
                :items="datasources_tableDataProvider"
                page="auditaccount"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                :transferCallback="transferCallback"
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>

            <div v-if="selectedTab==1">
              <div v-if="filtersApplied && !datasets_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!filtersApplied || (filtersApplied && datasets_tableDataProvider.length)"
                :headers="columns_in_datasets"
                :items="datasets_tableDataProvider"
                page="auditaccount"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                defaultSortBy="updatedAt"
                :sortDesc="true" ></DataTable>
            </div>


            <div v-if="selectedTab==2">
              <div v-if="filtersApplied && !views_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!filtersApplied || (filtersApplied && views_tableDataProvider.length)"
                :headers="columns_in_views"
                :items="views_tableDataProvider"
                page="auditaccount"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                defaultSortBy="updatedAt"
                :sortDesc="true" ></DataTable>
            </div>

            <div v-if="selectedTab==3">
              <div v-if="searchWord && !submissions_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
                <DataTable
                  v-if="!searchWord || (searchWord && submissions_tableDataProvider.length)"
                  :headers="columns_in_submissions"
                  :items="submissions_tableDataProvider"
                  page="auditaccount"
                  :linkBuilder="linktoDetails"
                  linkTarget="_self"
                  defaultSortBy="createdAt"
                  :dataProductCallback="openDataProductDialog"
                  :dataProductStatusChangedCallback="changeStatus"
                  :deleteCallback="deleteProduct"
                  :sortDesc="true"
                >
                </DataTable>
            </div>

            <div v-if="selectedTab==4">
              <div v-if="searchWord && !purchases_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
                <DataTable
                  v-if="!searchWord || (searchWord && purchases_tableDataProvider.length)"
                  :headers="columns_in_purchases"
                  :items="purchases_tableDataProvider"
                  page="auditaccount"
                  :linkBuilder="linktoDetails"
                  linkTarget="_self"
                  defaultSortBy="createdAt"
                  :dataProductStatusChangedCallback="changePurchaseStatus"
                  :deleteCallback="deleteProduct"
                  :sortDesc="true"
                >
                </DataTable>
            </div>

          </card>
        </div>
      </div>
    </div>
    <FormDialog ref="formDialog"
      :title="dataProductName"
      :confirmCallBack="updateDataProduct"
      :closeCallBack="closeDataProductForm"
      confirmLabel="Update"
      closeLabel="Cancel "
    >
      <form slot="form">
        <label class="input-label" for="fieldDescription" style="margin-top: 20px;">Describe this data product</label>
        <textarea type="text" v-model="dataProductDescription" class="form-control" style="min-height: 90px;"></textarea>
        <label class="input-label" for="fieldTags" style="margin-top: 20px;">Choose one or more Tags (i.e. "people", "health", "business", "weather", ...)</label>
        <v-combobox
          v-model="dataProductTags"
          clearable
          solo
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              :selected="data.selected"
              close
              @input="removeTag(data.item)"
            >
              <strong>{{ data.item }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </form>
    </FormDialog>
  </div>
  </v-app>
</template>


<script>
  // npm modules
  import Vue from 'vue'
  import Swal from 'sweetalert2'
  // components
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import DataTable from 'src/components/DataTable.vue'
  import DelModal from 'src/components/DelModal.vue'
  import DataProductsSearch from 'src/components/DataProductsSearch.vue'
  import FormDialog from 'src/components/FormDialog.vue'
  // services
  import AuditService from 'src/services/audit'
  import DataProductService from 'src/services/dataproduct'
  import ContractService from 'src/services/contract'
  // mixins
  import Utils from 'src/mixins/utils'
  import WalletUtils from 'src/mixins/walletUtils'

  export default {
    mixins: [Utils, WalletUtils],
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable,
      DelModal,
      DataProductsSearch,
      FormDialog
    },
    data () {
      return {
        columns_in_datasets: [
          { text: this.$t('auditData.table.dimScore'), value: 'dimScoreTotal', sortable: true, tooltip: true, tooltipText: this.$t('datasets.dimscore_info') },
          { text: this.$t('auditData.table.name'), align: 'left', value: 'name'},
          { text: this.$t('dwallet.table.dataSource'), align: 'left', value: 'dataSourceName'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('auditData.table.size'), value: 'size' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('auditData.table.numTransfers'), value: 'numberOfTransfers' },
          { text: this.$t('auditData.table.pii'), value: 'pii' },
          { text: this.$t('auditData.table.openWith'), value: 'dataApps', sortable: false }
        ],
        columns_in_views: [
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('auditData.table.numTransfers'), value: 'numberOfTransfers' },
          { text: this.$t('dwallet.table.openWith'), value: 'dataApps', sortable: false }
        ],
        columns_in_datasources: [
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('auditData.table.num_datasets'), value: 'numberOfDatasets' },
          { text: this.$t('dwallet.table.size'), value: 'numberOfCells' },
          { text: this.$t('auditData.table.pii'), value: 'pii' }
          // { text: this.$t('dwallet.table.openWith'), value: 'dataApps', sortable: false }
        ],
        columns_in_submissions: [
          { text: this.$t('dwallet.table.dimScore'), align: 'center', value: 'dimScoreTotal', sortable: true, tooltip: true,  tooltipText: this.$t('datasets.dimscore_info') },
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.description'), align: 'left', value: 'description'},
          { text: this.$t('auditData.table.tags'), value: 'tag' },
          { text: this.$t('auditData.table.submitter'), value: 'userName' },
          { text: this.$t('auditData.table.date_submitted'), value: 'createdAt' },
          { text: this.$t('auditData.table.price'), value: 'price' },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        columns_in_purchases: [
          { text: this.$t('dwallet.table.dimScore'), align: 'center', value: 'dimScoreTotal', sortable: true, tooltip: true,  tooltipText: this.$t('datasets.dimscore_info') },
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.description'), align: 'left', value: 'description'},
          { text: this.$t('auditData.table.tags'), value: 'tag' },
          { text: this.$t('auditData.table.buyer'), value: 'userName' },
          { text: this.$t('auditData.table.purchased'), value: 'createdAt' },
          { text: this.$t('auditData.table.price'), value: 'price' },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        selectedTab: 1,
        datasets: [],
        views: [],
        datasources: [],
        submissions: [],
        purchases: [],
        datasets_tableDataProvider: null,
        views_tableDataProvider: null,
        datasources_tableDataProvider: null,
        submissions_tableDataProvider: [],
        purchases_tableDataProvider: [],
        dataLoaded: false,
        editDelete: true,
        advancedFilters: {
          dim: 'all',
          shared: 'all',
          pii: 'all',
        },
        searchWord: '',
        filtersApplied: false,
        dataProductDescription: '',
        dataProductName: '',
        dataProductTags: [],
        dataAsset: null
      }
    },
    methods: {
      applyFilters( list ){
        let results = list.filter(row => {
          let matchTxt = row.name.toUpperCase().includes(this.searchWord) || (row.wrangler && row.wrangler.toUpperCase().includes(this.searchWord) );
          let matchDim = this.advancedFilters.dim == "all" ? true : row.dim >= this.advancedFilters.dim;
          let matchShared;
            if( this.advancedFilters.shared == "all" )
              matchShared = true;
            else if( this.advancedFilters.shared == "shared" )
              matchShared = row.numTransfers >= 1;
            else if( this.advancedFilters.shared == "notshared" )
              matchShared = row.numTransfers == 0;

          let matchPII = this.advancedFilters.pii == "all" ? true : row.pii == this.advancedFilters.pii

          let passesFilters = matchTxt && matchDim && matchShared && matchPII;
          return passesFilters;
        });
        return results;
      },

      async searchInputValue(e) {
        if( !this.dataLoaded ) {
          await this.loadAllTables();
        }
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.filtersApplied = this.advancedFilters.dim != "all" ||
                              this.advancedFilters.shared != "all" ||
                              this.searchWord;

        this.datasets_tableDataProvider = this.applyFilters( this.datasets );
        this.views_tableDataProvider = this.applyFilters( this.views );
        this.datasources_tableDataProvider = this.applyFilters( this.datasources );
      },

      async loadAllTables() {
        await this.loadDatasets();
        await this.loadViews();
        await this.loadDatasources();
        await this.loadSubmissions();
        await this.loadPurchased();
        this.dataLoaded = true;
      },

      async loadDatasources() {
        // to-do: filter by savings/checking
        try{
          let datasources = await AuditService.listDatasources();
          this.parseDatasources( datasources );
          this.datasources_tableDataProvider = [...this.datasources];
        }catch(err){
          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: 'Error loading Datasources',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async loadSubmissions() {
        this.submissions = await DataProductService.getDataProducts();
        this.submissions_tableDataProvider = this.submissions.map(x => {
          x.type = 'dataproduct';
          x.userName = x.user.name;
          x.price = x.status == 'pending' ? '-' : this.$t('general.free');
          x.statusBefore = x.status;
          x.tag = x.tags.join(', ');
          if (x.view == null) {
            x.dimScore = x.dataset?.dimScore;
            x.dimScoreTotal = x.dimScore?.total !== null ? x.dimScore?.total?.toFixed(2) : null;
          } else {
            x.dimScore = null;
            x.dimScoreTotal = null;
          }
          return x;
        });
      },

      async loadPurchased() {
        this.purchases = await ContractService.getContracts();
        this.purchases_tableDataProvider = this.purchases.map(x => {
          x.type = 'purchase';
          x.name = x.dataProduct.name,
          x.userName = x.user.name;
          x.description = x.dataProduct.description,
          x.statusBefore = x.status
          x.tags = x.dataProduct.tags;
          x.tag = x.dataProduct.tags.join(', ');
          x.price = 'Free';
          if (x.view == null) {
            x.dimScore = x.dataProduct?.dataset?.dimScore;
            x.dimScoreTotal = x.dimScore?.total !== null ? x.dimScore?.total?.toFixed(2) : null;
          } else {
            x.dimScore = null;
            x.dimScoreTotal = null;
          }
          return x;
        });
      },

      async loadDatasets() {
        try {
          let datasets = await AuditService.listDatasets();
          this.parseDatasets( datasets );
          this.datasets_tableDataProvider = [...this.datasets];

        } catch (err) {
          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: 'Datasets not found',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async loadViews(){
          let currentUserPubId = this.getUserPublicId();
          let views = await AuditService.listViews();
          this.parseViews( views );
          this.views_tableDataProvider = [...this.views];
      },

      async deleteProduct(item) {
        try {
          const result = await Swal.fire({
              title: this.$t('dataProduct.warning'),
              html: this.$t('dataProduct.delete.msg', [item.name]),
              type: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('unborrowFromLibrary.confirm.confirmButton'),
              cancelButtonText: this.$t('unborrowFromLibrary.confirm.cancelButton')
          });
          if (result.value) {
            Swal.fire({
              title: this.$t('dataProduct.delete.title'),
              onOpen: () => {
                Swal.showLoading();
              }
            });

            await DataProductService.removeDataProduct(item.publicId);

            await this.loadSubmissions();

            Swal.fire({
              title: this.$t('dataProduct.success'),
              html: this.$t('dataProduct.delete.success_msg', [item.name]),
              type: 'success',
              showConfirmButton: true,
              onBeforeOpen: () => {
                Swal.hideLoading();
              }
            });
          }
        } catch (err) {
          Swal.fire({
            title: this.$t('dataProduct.error'),
            html: `<pre>${this.getErrorMessage(err)}</pre>`,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async changePurchaseStatus(item) {
        try {
          const body = {
            status: item.status,
            description: item.description,
            tags: item.tags
          };

          const questionMessage = body.status == 'approved' ?
          this.$t('dataProduct.status_purchase.approve_msg', [item.name])
          : this.$t('dataProduct.status_purchase.reject_msg', [item.name]);

          const result = await Swal.fire({
              title: this.$t('dataProduct.warning'),
              html: questionMessage,
              type: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('unborrowFromLibrary.confirm.confirmButton'),
              cancelButtonText: this.$t('unborrowFromLibrary.confirm.cancelButton')
          });
          if (result.value) {
            Swal.fire({
              title: this.$t('dataProduct.updating'),
              onOpen: () => {
                Swal.showLoading();
              }
            });

            await ContractService.updateStatus(body, item.publicId);

            await this.loadPurchased();

            Swal.fire({
              title: this.$t('dataProduct.success'),
              html: this.$t('dataProduct.status_purchase.update_msg', [item.name]),
              type: 'success',
              showConfirmButton: true,
              onBeforeOpen: () => {
                Swal.hideLoading();
              }
            });
          } else {
            item.status = item.statusBefore;
          }
        } catch (err) {
          Swal.fire({
            title: this.$t('dataProduct.erro'),
            html: `<pre>${this.getErrorMessage(err)}</pre>`,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async changeStatus(item) {
        try {
          const body = {
            status: item.status,
            description: item.description,
            tags: item.tags
          };
          const result = await Swal.fire({
              title: this.$t('dataProduct.warning'),
              html: this.$t('dataProduct.post.msg', [item.name]),
              type: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('unborrowFromLibrary.confirm.confirmButton'),
              cancelButtonText: this.$t('unborrowFromLibrary.confirm.cancelButton')
          });
          if (result.value) {
            Swal.fire({
              title: this.$t('dataProduct.updating'),
              onOpen: () => {
                Swal.showLoading();
              }
            });

            await DataProductService.updateDataProduct(body, item.publicId);

            await this.loadSubmissions();

            Swal.fire({
              title: this.$t('dataProduct.success'),
              html: this.$t('dataProduct.update.success_msg', [item.name]),
              type: 'success',
              showConfirmButton: true,
              onBeforeOpen: () => {
                Swal.hideLoading();
              }
            });
          } else {
            item.status = item.statusBefore;
          }
        } catch (err) {
          Swal.fire({
            title: this.$t('dataProduct.erro'),
            html: `<pre>${this.getErrorMessage(err)}</pre>`,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async updateDataProduct() {
        try {
          const body = {
            status: this.dataAsset.status,
            description: this.dataProductDescription,
            tags: this.dataProductTags
          };
          this.closeDataProductForm();
          Swal.fire({
            title: this.$t('dataProduct.update.title'),
            onOpen: () => {
              Swal.showLoading();
            }
          });

          await DataProductService.updateDataProduct(body, this.dataAsset.publicId);

          await this.loadSubmissions();

          Swal.fire({
            title: this.$t('dataProduct.update.success'),
            html: this.$t('dataProduct.update.success_msg', [this.dataAsset.name]),
            type: 'success',
            showConfirmButton: true,
            onBeforeOpen: () => {
              Swal.hideLoading();
            }
          });
        } catch (err) {
          Swal.fire({
            title: 'Error',
            html: `<pre>${this.getErrorMessage(err)}</pre>`,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      removeTag (item) {
        this.dataProductTags.splice(this.dataProductTags.indexOf(item), 1)
        this.dataProductTags = [...this.dataProductTags]
      },

      openDataProductDialog(item) {
        this.dataAsset = item;
        this.dataProductName = item.name;
        this.dataProductDescription = item.description;
        this.dataProductTags = item.tags;
        this.$refs.formDialog.updateData(item);
      },
      closeDataProductForm() {
        this.dataProductName = '';
        this.dataProductDescription = '';
        this.dataProductTags = [];
        this.$refs.formDialog.closeDialog();
      },

      onSelectedOrganizationChanged: function(e){
        this.loadAllTables();
      },
    },
    async mounted () {
      await this.loadAllTables()
    }
  }
</script>


<style>
  .container-search-bar-and-create-button{
    margin: 0px;
  }

  .searchContainer{
    float: right;
    margin-top: -50px;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .v-tabs__div{
    min-width: 160px;
  }
</style>

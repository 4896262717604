<template>
  <nav class="navbar navbar-expand-lg topbar">
    <div class="logo" v-if="!isSectionAllowed('organizations')">
      <div class="logo-img">
        <img v-bind:src="getOrganization().loginLogoUrl" />
      </div>
    </div>

    <div class="container-fluid">
      <div
        v-if="
          isSectionAllowed('organizations') &&
            organizations &&
            organizations.length > 1
        "
        class="filterContainer"
      >
        <div class="organizationSelectorContainer filterElement">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <a href="#/dwallet">
                <div class="logo-img">
                  <span class="label" v-if="!selectedOrganizationLogo">
                    {{ selectedOrganizationName }}
                  </span>
                  <img
                    v-if="selectedOrganizationLogo"
                    v-bind:src="selectedOrganizationLogo"
                  />
                </div>
              </a>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                v-for="(item, index) in organizations"
                class="dropdown-item"
                href="#"
                @click="changeSelectedOrganization($event, index)"
                >{{ item.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <a class="navbar-brand" href="#"></a>
      <!-- breaks mobile/responsive if removed -->
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style="display: none;"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">
              {{ $t("hamburgerMenu.home") }}
            </router-link>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :class="{ 'router-link-active': subIsActive('personal') }"
            >
              dWallet
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link to="/dwallet" class="dropdown-item nav-link">{{
                $t("hamburgerMenu.dWallet")
              }}</router-link>
              <router-link
                to="/projects"
                class="dropdown-item nav-link"
                v-if="isSectionAllowed('projects')"
                >{{ $t("hamburgerMenu.projects") }}</router-link
              >
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :class="{ 'router-link-active': subIsActive('apps') }"
            >
              Apps
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link to="/auditmap" class="dropdown-item nav-link">{{
                $t("hamburgerMenu.auditMap")
              }}</router-link>
              <a
                v-if="isCreateAllowed('projects')"
                class="dropdown-item nav-link"
                v-bind:href="studioUrl"
                target="_blank"
                rel="noopener"
                >Studio</a
              >
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :class="{ 'router-link-active': subIsActive('organization') }"
              v-if="isSectionAllowed('datasets')"
            >
              Organization
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                to="/auditdata"
                class="dropdown-item nav-link"
                >{{ $t("hamburgerMenu.auditCatalog") }}</router-link
              >
              <router-link
                to="/auditusers"
                class="dropdown-item nav-link"
                >{{ $t("hamburgerMenu.auditWallets") }}</router-link
              >
            </div>
          </li>

          <li class="nav-item" >
            <router-link to="/exchange" class="nav-link">
              {{$t('hamburgerMenu.exchange')}}
            </router-link>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Admin
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                to="/settings/organization"
                class="dropdown-item nav-link"
                >{{ $t("hamburgerMenu.myOrganization") }}</router-link
              >
              <router-link
                to="/users"
                class="dropdown-item nav-link"
                v-if="isSectionAllowed('users')"
                >{{ $t("hamburgerMenu.users") }}</router-link
              >
              <router-link
                to="/organizations"
                class="dropdown-item nav-link"
                v-if="isSectionAllowed('organizations')"
                >{{ $t("hamburgerMenu.organizations") }}</router-link
              >
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle dropdown-nocaret"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="padding: 0px 0px 0px 10px !important;"
            >
              <img :src="getUserProfilePhoto()" class="userAvatar" />
            </a>
            <div
              class="dropdown-menu userDropDown"
              aria-labelledby="navbarDropdown"
            >
              <div class="dropdown-item">
                <div style="font-size: 1.2em; color: #fff;">
                  {{ getUserName() }}
                </div>
                <div style="font-size: 0.9em; opacity: 0.8;">
                  {{ getUserEmail() }}
                </div>
              </div>
              <router-link
                to="/settings/profile"
                class="dropdown-item nav-link"
                >{{ $t("settings.profile.title") }}</router-link
              >
              <router-link
                to="/settings/security"
                class="dropdown-item nav-link"
                >{{ $t("settings.security.title") }}</router-link
              >
              <a v-on:click="logout" href="#" class="dropdown-item nav-link">{{
                $t("signOut")
              }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
const config = require("config");
const baseUrl = config.apiUrl.split(".")[1];
import LoginService from "src/services/login";
import Utils from "src/mixins/utils";
import OrgService from "src/services/organization";

export default {
  mixins: [Utils],
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      studioUrl: `https://studio.${baseUrl}.com/`,
      selectedOrganizationName: null,
      selectedOrganizationLogo: null,
      organizations: []
    };
  },
  methods: {
    subIsActive(input) {
      let currentPath = this.$route.path;
      if (input == "personal") {
        let personalSections = ["/dwallet", "/projects"];
        return personalSections.indexOf(currentPath) >= 0;
      } else if (input == "organization") {
        let orgSections = [
          "/auditdata",
          "/auditusers"
        ];
        return orgSections.indexOf(currentPath) >= 0;
      }
      return false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async logout() {
      try {
        await LoginService.logout();
        window.location = "/";
      } catch (err) {
        console.error(err);
      }
    },
    updateOrganization(index) {
      const org = this.organizations[index];

      // update logo and name
      this.selectedOrganizationLogo = null;
      this.selectedOrganizationName = null;

      if (org) {
        this.setOrganizationPublicId(org.publicId);
        this.selectedOrganizationLogo = org.loginLogoUrl;
        this.selectedOrganizationName = org.name;
      }

      // and dispatch event to all pages
      var event = new Event("selectedOrganizationChanged");
      window.dispatchEvent(event);
    },
    changeSelectedOrganization(event, index) {
      event.preventDefault();
      this.updateOrganization(index);
    },
    populateOrganizations: function() {
      const index = this.organizations.findIndex(
        el => el.publicId == this.getOrganizationPublicId()
      );
      this.updateOrganization(index);
    }
  },
  beforeMount() {
    if (this.isSectionAllowed("organizations")) {
      let scoper = this;
      OrgService.listOrganizations()
        .then(function(results) {
          results.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          scoper.organizations = results;
          scoper.populateOrganizations();
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>

<style scoped>
.userAvatar {
  border-radius: 50%;
  height: 35px;
  width: auto;
}

.username {
  pointer-events: none;
  cursor: default;
}

.topbar {
  background: #000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  padding: 0px;
}

.filterContainer {
  text-align: center;
  left: 15px;
}
.filterElement {
  text-align: center;
  margin-top: 0px;
  width: min-content;
}

.logo {
  text-align: center;
}
.logo-img {
  display: inline-block;
}

.logo-img img {
  max-width: 180px;
  max-height: 39px;
  margin-right: 10px;
}

.logo-img .label {
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 10px;
}

.dropdown-menu {
  max-height: 355px;
  overflow: auto;
  top: 15px !important;
  margin-top: 30px !important;
  top: 19px !important;
  border-radius: 0px !important;
  background-color: #000000 !important;
}

.dropdown-menu:after {
  display: none !important;
}

.dropdown-menu .dropdown-item {
  padding: 8px 16px !important;
  color: #dddddd;
  background-color: #000000 !important;
  border-bottom: solid 1px #444;
}

.userDropDown {
  top: 17px !important;
  left: auto !important;
  right: -16px;
}

#dropdownMenuButton {
  border: none;
  margin: 0px;
  padding: 0px;
  opacity: 1;
}

.navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: #aaaaaa;
  border-top: solid 3px #000000;
  padding: 14px 15px 13px !important;
}
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #fff !important;
}

.router-link-exact-active,
.router-link-active {
  color: #ffffff !important;
  border-top: solid 3px #ec008c !important;
}

.dropdown-item.nav-link.router-link-exact-active {
  border-top: solid 3px #000000 !important;
}

.dropdown-nocaret:after {
  border: none !important;
}
</style>

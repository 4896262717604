var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: {
        "input-group": _vm.hasIcon
      }
    },
    [
      _vm._t("label", [
        _vm.label
          ? _c("label", { staticClass: "control-label" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("addonLeft", [
        _vm.addonLeftIcon
          ? _c("span", { staticClass: "input-group-prepend" }, [
              _c("div", { staticClass: "input-group-text" }, [
                _c("i", { class: _vm.addonLeftIcon })
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default", [
        _c(
          "input",
          _vm._g(
            {
              staticClass: "form-control",
              attrs: {
                id: _vm.inputID,
                "aria-describedby": "addon-right addon-left",
                placeholder: _vm.placeholder,
                "data-class": _vm.listItemClass,
                required: _vm.required,
                pattern: _vm.pattern,
                label: _vm.label,
                type: _vm.type
              },
              domProps: { value: _vm.value }
            },
            _vm.listeners
          )
        )
      ]),
      _vm._v(" "),
      _vm._t("addonRight", [
        _vm.addonRightIcon
          ? _c("span", { staticClass: "input-group-append" }, [
              _c("div", { staticClass: "input-group-text" }, [
                _c("i", {
                  class: _vm.addonRightIcon,
                  on: { click: _vm.onClick }
                })
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("helperText")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
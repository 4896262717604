const api = require('./api-caller')

module.exports = {
  retrieveEmbed: (embedCode) => {
    const endpoint = `/embed/${embedCode}`
    return api(endpoint, 'GET')
  },
  createEmbed: (obj) => {
    const endpoint = `/embed`
    return api(endpoint, 'POST', obj)
  }
}

<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <div class="page-title">{{$t('landscapes.title')}}</div>
              <p class="card-category">{{$t('landscapes.subTitle')}}</p>
              <div class="container-search-bar-and-create-button searchContainer">
                <form @submit.prevent="searchInputValue" style="padding: 15px">
                  <BaseInput
                    ref="searchInput"
                    addonRightIcon="fa fa-search"
                    placeholder="Search"
                    :onKeyUp="searchInputValue"
                    :onClick="searchInputValue"
                  ></BaseInput>
                </form>
              </div>
            </template>
          </card>
          <ViewCard :viewData="landscapes"></ViewCard>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8"></div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
.viewList {
  list-style: none none;
  padding: 0;
  li {
    margin: 0 30px 15px 0;
    max-width: 200px;
    vertical-align: top;
    display: inline-block;
    padding: 0;
    a {
      font-size: 0.8em;
      font-weight: bold;
      color: #333;
    }
    img {
      width: 200px;
      height: 200px;
      border: 1px solid #dfdfdf;
    }
  }
}

.container-search-bar-and-create-button {
  margin: 0px;
}

.searchContainer {
  float: right;
  margin-top: -50px;
  max-width: 300px;
  margin-bottom: 10px;
}
</style>


<script>
import Vue from "vue";
import ViewCard from "src/components/ViewCard.vue";
import LandscapeService from "src/services/landscape";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      landscapes: []
    };
  },
  components: {
    ViewCard,
    BaseButton,
    BaseInput
  },
  methods: {
    async searchInputValue(e) {
      await this.loadLandscapes();
      let searchWord = this.$refs.searchInput.getValue().toUpperCase();
      this.landscapes.map((landscape, index) => {
        this.landscapes[index].views = landscape.views.filter(
          view =>
            view.name.toUpperCase().includes(searchWord) ||
            landscape.name.toUpperCase().includes(searchWord)
        );
      });
      this.landscapes = this.landscapes.filter(
        landscape => landscape.views.length > 0
      );
    },
    async loadLandscapes() {
      const sorter = (a, b) => (a.name < b.name ? -1 : 1);
      return LandscapeService.listLandscapes()
        .then(result => {
          this.landscapes = result;
          this.landscapes.forEach(landscape => landscape.views.sort(sorter));
        })
        .catch(err => {
        console.error(err)
          Swal.fire({
            title: "Error!",
            text: "Landscapes not found",
            type: "error",
            confirmButtonText: "Ok"
          });
        });
    },
    onSelectedOrganizationChanged: function(e) {
      this.loadLandscapes();
    }
  },
  mounted() {
    this.loadLandscapes();
  },
  created() {
    var scoper = this;
    window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  },
  beforeDestroy() {
    window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  }
};
</script>


<style lang="scss">
.viewList {
  list-style: none none;
  padding: 0;
  li {
    margin: 0 30px 15px 0;
    max-width: 200px;
    vertical-align: top;
    display: inline-block;
    padding: 0;
    a {
      font-size: 0.8em;
      font-weight: bold;
      color: #333;
    }
    img {
      width: 200px;
      height: 200px;
      border: 1px solid #dfdfdf;
    }
  }
}
</style>

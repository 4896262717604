var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "content", attrs: { id: "datawallets" } },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("div", { staticClass: "page-title" }, [
                        _vm._v(_vm._s(_vm.$t("datawallets.title")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-category" }, [
                        _vm._v(_vm._s(_vm.$t("datawallets.subTitle")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "container-search-bar-and-create-button searchContainer"
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.searchInputValue($event)
                              }
                            }
                          },
                          [
                            _c("BaseInput", {
                              ref: "searchInput",
                              attrs: {
                                addonRightIcon: "fa fa-search",
                                placeholder: "Search",
                                onKeyUp: _vm.searchInputValue,
                                onClick: _vm.searchInputValue
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isCreateAllowed("data-wallets")
                      ? _c("BaseButton", {
                          staticStyle: { padding: "12px 10px", margin: "0" },
                          attrs: {
                            buttonLabel: _vm.$t("datawallets.create"),
                            addonLeftIcon: "fa fa-folder-o",
                            onClick: _vm.callCreate
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("DataTable", {
                      attrs: {
                        headers: _vm.columns,
                        items: _vm.datawallets,
                        editDelete: true,
                        page: "datawallets",
                        linkBuilder: _vm.linktoStudio,
                        linkTarget: "_self",
                        editCallback: _vm.editCallback
                      }
                    })
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("DelModal", {
          attrs: {
            onClickQuit: _vm.quitDelete,
            onClickDelete: _vm.confirmDelete,
            title: _vm.$t("datawallets.delete")
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
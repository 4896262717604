var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "relative", width: "min-content" },
      on: { mouseleave: _vm.hideOptionsPanel, mousewheel: _vm.hideOptionsPanel }
    },
    [
      _c("div", { staticStyle: { width: "max-content" } }, [
        _vm.page == "dwallet" && !_vm.item.borrowedByMe
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("i", {
                staticClass: "fa fa-times",
                staticStyle: { cursor: "pointer" },
                attrs: { title: "Remove" },
                on: {
                  click: function($event) {
                    return _vm.deleteRow(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "dwallet" && _vm.canTransfer
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("i", {
                staticClass: "fa fa-share-alt",
                staticStyle: { cursor: "pointer" },
                attrs: { title: this.$t("miscellania.data_apps.transfer") },
                on: {
                  click: function($event) {
                    return _vm.openShareDialog(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.type == "dataproduct" &&
        _vm.page == "dwallet" &&
        _vm.isCurrentUser
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("img", {
                staticClass: "dataAppIcon",
                staticStyle: { cursor: "pointer" },
                attrs: {
                  src: require("../assets/imgs/dataApps/embed.png"),
                  title: "Embed code"
                },
                on: {
                  click: function($event) {
                    return _vm.openEmbedDialog(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "dwallet" && !_vm.item.post && _vm.canTransfer
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("img", {
                staticClass: "libraryIcon",
                attrs: {
                  src: require("../assets/imgs/library_post.png"),
                  title: "Post to Organization Library"
                },
                on: {
                  click: function($event) {
                    return _vm.postRow(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.post && _vm.canTransfer
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("img", {
                staticClass: "libraryIcon",
                attrs: {
                  src: require("../assets/imgs/library_unpost.png"),
                  title: "Remove from Organization Library"
                },
                on: {
                  click: function($event) {
                    return _vm.unpostRow(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "dwallet" && _vm.canTransfer
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("i", {
                staticClass: "fa fa-usd",
                staticStyle: { cursor: "pointer" },
                attrs: { title: "Create Data Product" },
                on: {
                  click: function($event) {
                    return _vm.openDataProductDialog(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.type === "datasource" && _vm.item.status == "error"
          ? _c("div", { staticClass: "iconContainer col-2" }, [
              _c("img", {
                staticClass: "dataAppIcon",
                staticStyle: { cursor: "pointer" },
                attrs: {
                  src: require("../assets/imgs/edit_icon.png"),
                  title: "Edit Data Source"
                },
                on: {
                  click: function($event) {
                    return _vm.callEditPage(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.type === "datasource" && _vm.item.status !== "error"
          ? _c("div", { staticClass: "iconContainer col-2" })
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "orgLibrary" &&
        !_vm.item.isCurrentUser &&
        !_vm.item.borrowedByMe &&
        !_vm.isItInMydWallet(_vm.item)
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("img", {
                staticClass: "libraryIcon",
                attrs: {
                  src: require("../assets/imgs/library_borrow.png"),
                  title: "Borrow"
                },
                on: {
                  click: function($event) {
                    return _vm.borrowItem(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.item.isCurrentUser && _vm.item.borrowedByMe
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("img", {
                staticClass: "libraryIcon",
                attrs: {
                  src: require("../assets/imgs/library_unborrow.png"),
                  title: "Return to library"
                },
                on: {
                  click: function($event) {
                    return _vm.unborrowCallback(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "auditaccount"
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.status,
                      expression: "item.status"
                    }
                  ],
                  class: _vm.changeClass(_vm.item),
                  attrs: { name: "status", id: "statusAction" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.item,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.dataProductStatusChangedCallback(_vm.item)
                      }
                    ]
                  }
                },
                [
                  _c(
                    "option",
                    {
                      style: _vm.item.status != "pending" ? "display:none" : "",
                      attrs: {
                        value: "pending",
                        "v-show": _vm.item.status == "pending"
                      }
                    },
                    [_vm._v("PENDING")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "approved" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.item.status == "approved" ? "APPROVED" : "APPROVE"
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "rejected" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.item.status == "rejected" ? "REJECTED" : "REJECT"
                      )
                    )
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "auditaccount" && _vm.item.type != "purchase"
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("i", {
                staticClass: "fa fa-cog",
                staticStyle: { cursor: "pointer" },
                attrs: { "aria-hidden": "true", title: "Edit Data Product" },
                on: {
                  click: function($event) {
                    return _vm.openDataProductDialog(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "auditaccount" &&
        _vm.item.status != "pending" &&
        _vm.item.type != "purchase"
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("i", {
                staticClass: "fa fa-times",
                staticStyle: { cursor: "pointer" },
                attrs: { title: "Remove" },
                on: {
                  click: function($event) {
                    return _vm.deleteRow(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "exchange" &&
        !_vm.isItFromMyOrg(_vm.item.organization.publicId)
          ? _c("div", [
              _c("i", {
                staticClass: "fa fa-shopping-cart",
                staticStyle: { cursor: "pointer" },
                attrs: { "aria-hidden": "true", title: "Buy" },
                on: {
                  click: function($event) {
                    return _vm.buyDataProductCallback(_vm.item)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.page == "exchange" &&
        _vm.isSectionAllowed("dataProductsManagement") &&
        _vm.isItFromMyOrg(_vm.item.organization.publicId)
          ? _c("div", { staticClass: "iconContainer iconAction" }, [
              _c("i", {
                staticClass: "fa fa-times",
                staticStyle: { cursor: "pointer" },
                attrs: { title: "Remove" },
                on: {
                  click: function($event) {
                    return _vm.deleteRow(_vm.item)
                  }
                }
              })
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
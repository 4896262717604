<template>
  <ul class="nav nav-mobile-menu">
    <li class="nav-item">
      <a href="#/dwallet" class="nav-link">
        <span>dWallet</span>
      </a>
      <a href="#/settings" class="nav-link">
        <span>{{$t('settings.title')}}</span>
      </a>
      <a class="nav-link" v-on:click="logout" href="#">
        <span>{{$t('signOut')}}</span>
      </a>
    </li>
  </ul>
</template>


<script>
import LoginService from "src/services/login";

export default {
  methods: {
    async logout() {
      try {
        await LoginService.logout();
        window.location = "/";

      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

const api = require('./api-caller')
const { getOrganizationPublicId } = require('../mixins/utils').methods

module.exports = {
  listUsers: (includeDeleted = false) => {
    let endpoint = `/users?organizationPublicId=${getOrganizationPublicId()}`
    if (includeDeleted) {
      endpoint += `&includeDeleted=true`
    }
    return api(endpoint, 'GET')
  },
  getUserById: (userID) => {
    const endpoint = `/users/${userID}`
    return api(endpoint, 'GET')
  },
  deleteUser: (userID) => {
    const endpoint = `/users/${userID}`
    return api(endpoint, 'DELETE')
  },
  createUser: (createUser) => {
    const endpoint = `/users`
    return api(endpoint, 'POST', Object.assign({}, { organizationPublicId: getOrganizationPublicId() }, createUser))
  },
  editUser: (userID, editUser) => {
    const endpoint = `/users/${userID}`
    return api(endpoint, 'PUT', Object.assign({}, { organizationPublicId: getOrganizationPublicId() }, editUser))
  },
  reactivateUser: (userID) => {
    const endpoint = `/users/${userID}/restore`
    return api(endpoint, 'PATCH', { organizationPublicId: getOrganizationPublicId() })
  },
  updatePassword: (userID, password) => {
    const endpoint = `/users/${userID}/password`
    return api(endpoint, 'PATCH', Object.assign({}, { organizationPublicId: getOrganizationPublicId() }, password))
  }
}

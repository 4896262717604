const api = require('./api-caller')
const { getOrganizationPublicId, getUserScreenName } = require('../mixins/utils').methods

module.exports = {
  listProjects: (groupBy = 'name') => {
    const endpoint = `/projects?groupBy=${groupBy}`
    return api(endpoint, 'GET')
  },

  getCurrentUserProjects: () => {
    const endpoint = `/projects?user=${getUserScreenName()}`
    return api(endpoint, 'GET')
  }
}

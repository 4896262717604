var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "content", attrs: { id: "orgLibrary" } },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("div", { staticClass: "page-title" }, [
                        _vm._v(_vm._s(_vm.$t("organizationLibrary.title")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "container-search-bar-and-create-button searchContainer"
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.searchInputValue($event)
                              }
                            }
                          },
                          [
                            _c("BaseInput", {
                              ref: "searchInput",
                              attrs: {
                                addonRightIcon: "fa fa-search",
                                placeholder: "Search",
                                onKeyUp: _vm.searchInputValue,
                                onClick: _vm.searchInputValue
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "fieldGroup",
                                staticStyle: {
                                  "margin-top": "10px",
                                  "text-align": "right",
                                  color: "#666"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [_vm._v("Show: ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.inWallet,
                                            expression: "filters.inWallet"
                                          }
                                        ],
                                        staticClass: "form-control small",
                                        attrs: {
                                          name: "filter",
                                          id: "fieldFilterInWallet"
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.filters,
                                                "inWallet",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function($event) {
                                              return _vm.onFilterChanged($event)
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { name: "", value: "all" } },
                                          [_vm._v("All")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              name: "",
                                              value: "notInWallet"
                                            }
                                          },
                                          [_vm._v("Not in my dWallet")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { clear: "both" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          "margin-bottom": "20px"
                        }
                      },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: { "slider-color": "#EC008C" },
                            model: {
                              value: _vm.selectedTab,
                              callback: function($$v) {
                                _vm.selectedTab = $$v
                              },
                              expression: "selectedTab"
                            }
                          },
                          [
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Datasets")
                              ]),
                              _vm._v(" "),
                              _vm.datasets_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.datasets_tableDataProvider.length
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Data Apps")
                              ]),
                              _vm._v(" "),
                              _vm.views_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      _vm._s(_vm.views_tableDataProvider.length)
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedTab == 0
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.datasets_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(_vm._s(_vm.$t("general.no_results")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.datasets_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_datasets,
                                    items: _vm.datasets_tableDataProvider,
                                    page: "orgLibrary",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    unpostCallback: _vm.unpostRow,
                                    borrowCallback: _vm.borrowCallback,
                                    unborrowCallback: _vm.unborrowRow,
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTab == 1
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.views_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(_vm._s(_vm.$t("general.no_results")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.views_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_views,
                                    items: _vm.views_tableDataProvider,
                                    page: "orgLibrary",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    unpostCallback: _vm.unpostRow,
                                    borrowCallback: _vm.borrowCallback,
                                    unborrowCallback: _vm.unborrowRow,
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("TransferDialog", {
          ref: "transferDialog",
          attrs: { onConfirmTransfer: _vm.onConfirmTransfer }
        }),
        _vm._v(" "),
        _c("EmbedDialog", { ref: "embedDialog" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
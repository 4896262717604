import { render, staticRenderFns } from "./DelModal.vue?vue&type=template&id=219c16dc&scoped=true&"
import script from "./DelModal.vue?vue&type=script&lang=js&"
export * from "./DelModal.vue?vue&type=script&lang=js&"
import style0 from "./DelModal.vue?vue&type=style&index=0&id=219c16dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219c16dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/__w/ddv-home/ddv-home/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('219c16dc')) {
      api.createRecord('219c16dc', component.options)
    } else {
      api.reload('219c16dc', component.options)
    }
    module.hot.accept("./DelModal.vue?vue&type=template&id=219c16dc&scoped=true&", function () {
      api.rerender('219c16dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DelModal.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content", attrs: { id: "datasourcesCreate" } }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(
                        _vm._s(
                          this.isUpload
                            ? _vm.$t("dsCreateEdit.uploadTitle")
                            : this.isAPI
                            ? _vm.$t("dsCreateEdit.apiTitle")
                            : _vm.$t("dsCreateEdit.connectDbTitle")
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "raw-content" },
                    [
                      _c("CreateEditModal", {
                        ref: "createEditModal",
                        attrs: {
                          onClickQuit: _vm.quitCreateEdit,
                          onFormValidated: _vm.onFormValidated,
                          isEdit: false
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "card",
        {
          staticClass: "strpied-tabled-with-hover grid-container",
          staticStyle: { margin: "0px" },
          attrs: {
            "header-classes": "col-6 pull-left",
            "body-classes": "table-full-width table-responsive "
          }
        },
        [
          _c("div", { staticClass: "col-6 pull-left containerRight" }, [
            _c("div", { staticClass: "row info-row ds-info-text" }, [
              _c(
                "div",
                {
                  staticClass: "col-2 infoCol2",
                  staticStyle: { padding: "0px" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("transactions.is_public")) +
                      ":\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-3 infoCol3 pull-left",
                  staticStyle: { padding: "0px" }
                },
                [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.dataAssetIsPublic == true
                          ? _vm.$t("transactions.yes")
                          : _vm.$t("transactions.no")
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.dataAssetIsPublic
                ? _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("transactions.posted_to_library_at")) +
                          ":\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataAssetIsPublic
                ? _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3 pull-left",
                      staticStyle: { padding: "0px" }
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.postedAt))])]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row info-row ds-info-text" }, [
              _c(
                "div",
                {
                  staticClass: "col-2 infoCol2",
                  staticStyle: { padding: "0px" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("transactions.is_in_your_wallet")) +
                      ":\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-3 infoCol3 pull-left",
                  staticStyle: { padding: "0px" }
                },
                [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.dataAssetIsInYourWallet == true
                          ? _vm.$t("transactions.yes")
                          : _vm.$t("transactions.no")
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.youHaveBorrowIt || _vm.youHaveReceivedATransfer
                ? _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("transactions.type_of_licensing")) +
                          ":\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.youHaveBorrowIt
                ? _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3 pull-left",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _c("b", [
                        _vm._v(_vm._s(_vm.$t("transactions.you_borrowed_it")))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.youHaveReceivedATransfer
                ? _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3 pull-left",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _c("b", [
                        _vm._v(_vm._s(_vm.$t("transactions.you_received_it")))
                      ])
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.youHaveBorrowIt
              ? _c("div", { staticClass: "row info-row ds-info-text" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-2 infoCol2",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("transactions.borrowed_at")) +
                          ":\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3 pull-left",
                      staticStyle: { padding: "0px" }
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.borrowedAt))])]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.youHaveReceivedATransfer
              ? _c("div", { staticClass: "row info-row ds-info-text" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-2 infoCol2",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("transactions.transfer_starting_at")) +
                          ":\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3 pull-left",
                      staticStyle: { padding: "0px" }
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.yourTransfer.startAt))])]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("transactions.transfer_ending_at")) +
                          ":\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3 pull-left",
                      staticStyle: { padding: "0px" }
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.yourTransfer.endAt))])]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.youHaveReceivedATransfer
              ? _c("div", { staticClass: "row info-row ds-info-text" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-2 infoCol2",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("transactions.intent")) +
                          ":\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-3 infoCol3 pull-left",
                      staticStyle: { padding: "0px" }
                    },
                    [_c("b", [_vm._v(_vm._s(_vm.yourTransfer.intent))])]
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 pull-left containerRight" }, [
            _c("div", { staticClass: "row info-row ds-info-text" }, [
              _c(
                "div",
                {
                  staticClass: "col-5 infoCol5 pull-right",
                  staticStyle: { padding: "0px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col-5 pull-left",
                      staticStyle: {
                        "padding-right": "0px",
                        "text-align": "right"
                      }
                    },
                    [
                      _vm.dataAssetIsPublic && _vm.loggedUserIsTheOwner
                        ? _c("BaseButton", {
                            staticStyle: {
                              padding: "12px 30px",
                              margin: "0px",
                              float: "right"
                            },
                            attrs: {
                              onClick: _vm.unpostDataAsset,
                              buttonLabel: _vm.$t(
                                "transactions.remove_from_org_lib"
                              )
                            }
                          })
                        : !_vm.dataAssetIsPublic && _vm.loggedUserIsTheOwner
                        ? _c("BaseButton", {
                            staticStyle: {
                              padding: "12px 30px",
                              margin: "0px",
                              float: "right"
                            },
                            attrs: {
                              onClick: _vm.postDataAsset,
                              buttonLabel: _vm.$t(
                                "transactions.post_to_org_lib"
                              )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.youHaveReceivedATransfer &&
                      _vm.youHaveBorrowIt &&
                      !_vm.loggedUserIsTheOwner &&
                      _vm.dataAssetIsPublic
                        ? _c("BaseButton", {
                            staticStyle: {
                              padding: "12px 30px",
                              margin: "0px",
                              float: "right"
                            },
                            attrs: {
                              onClick: _vm.unborrowDataAsset,
                              buttonLabel: _vm.$t("transactions.return_to_lib")
                            }
                          })
                        : !_vm.youHaveReceivedATransfer &&
                          !_vm.youHaveBorrowIt &&
                          !_vm.loggedUserIsTheOwner &&
                          _vm.dataAssetIsPublic
                        ? _c("BaseButton", {
                            staticStyle: {
                              padding: "12px 30px",
                              margin: "0px",
                              float: "right"
                            },
                            attrs: {
                              onClick: _vm.borrowDataAsset,
                              buttonLabel: _vm.$t("transactions.borrow")
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.loggedUserIsTheOwner
        ? _c(
            "card",
            {
              staticClass: "strpied-tabled-with-hover",
              attrs: { "body-classes": "table-full-width table-responsive" }
            },
            [
              _vm.hasBorrows
                ? _c("div", { staticClass: "row panelRow" }, [
                    _c("div", { staticClass: "col-10 panelTitle" }, [
                      _vm._v(_vm._s(_vm.$t("transactions.borrows")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.transactionsLoaded
                ? _c("div", { staticClass: "loadingMessage" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("general.loading")) + "\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.transactionsLoaded && _vm.hasBorrows
                ? _c("v-data-table", {
                    attrs: {
                      headers: _vm.columnsBorrows,
                      items: _vm.borrows,
                      pagination: _vm.paginationBorrows,
                      "rows-per-page-items": _vm.rowsPerPageItems
                    },
                    on: {
                      "update:pagination": function($event) {
                        _vm.paginationBorrows = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "items",
                          fn: function(props) {
                            return [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(props.item.user.email))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.user.name))
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(props.item.updatedAt))])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2265880978
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasTransfers
                ? _c("div", { staticClass: "row panelRow" }, [
                    _c("div", { staticClass: "col-10 panelTitle" }, [
                      _vm._v(_vm._s(_vm.$t("transactions.transfers")))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.transactionsLoaded
                ? _c("div", { staticClass: "loadingMessage" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("general.loading")) + "\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.transactionsLoaded && _vm.hasTransfers
                ? _c("v-data-table", {
                    attrs: {
                      headers: _vm.columnsTransfers,
                      items: _vm.transfers,
                      pagination: _vm.paginationTransfers,
                      "rows-per-page-items": _vm.rowsPerPageItems
                    },
                    on: {
                      "update:pagination": function($event) {
                        _vm.paginationTransfers = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "items",
                          fn: function(props) {
                            return [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(props.item.user.email))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.user.name))
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(props.item.startAt))]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(props.item.endAt))]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(props.item.intent))])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      264469073
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
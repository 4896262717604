<template>
  <v-app>
    <div class="content screen-area" id="settings">
        <div class="container-fluid">
            <div class="row">
                <input
                    ref="input"
                    type="file"
                    name="image"
                    accept="image/*"
                    @change="setImage"
                />
                <div class="col-12">
                    <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                        <h4 class="card-title mb-3">{{$t('settings.title')}} - {{$t('settings.profile.title')}}</h4>
                        <form v-on:submit.prevent action="" method="post">
                            <div class="col-6 pull-right" style="height: 250px">
                                <div class="mb-3">
                                    <div class="col-6 pull-left"  style="height: 250px">
                                        <label class="control-label">{{$t('settings.profile.picture')}}</label>
                                        <div class="mb-3">
                                            <img v-show="imgSrc && !imgChosen" :src="imgSrc" width="200" height="200" style="border-radius: 50%"/>
                                        </div>
                                        <vue-cropper
                                        v-show="imgSrc && imgChosen"
                                        ref="cropper"
                                        :src="imgSrc"
                                        alt="Source Image"
                                        :cropmove="cropMoveEvent"
                                        :cropend="cropImage"
                                        style="width: 200px !important; height: 200px !important"
                                        >
                                        </vue-cropper>
                                    </div>
                                    <div class="col-6 pull-right" style="height: 250px">
                                        <label class="control-label" v-show="imgSrc && imgChosen">Preview</label>
                                        <div class="mb-3" v-show="imgSrc && imgChosen">
                                            <img :src="imgSrc" width="200" height="200" style="border-radius: 50%" />
                                        </div>
                                    </div>
                                    <div class="subNote logoSize" v-bind:class="{logoSizeOk: sizeOk}" v-show="imgChosen">{{ $t('organizationCreate.form.cropSize') + ': ' + cropSize }}</div>
                                    <div>
                                        <BaseButton class="btn btn-primary btn-lg btn-block pull-left" :buttonLabel="$t('basebutton.choose_image')" :onClick="showFileChooser"></BaseButton>
                                        <BaseButton v-show="imgSrc && imgChosen" class="btn btn-primary btn-lg btn-block pull-right" :buttonLabel="$t('settings.profile.cropImage')" :onClick="useCropImage"></BaseButton>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 pull-left"  style="height: 250px">
                                <div class="mb-3">
                                    <label class="control-label">{{$t('settings.profile.name')}}</label>
                                    <input v-model="name" type="text" id="fieldName" name="name" class="form-control medium" />
                                </div>
                                <div class="mb-3">
                                    <label class="control-label">{{$t('settings.profile.notification')}}</label>
                                    <v-switch v-model="emailNotification"></v-switch>
                                </div>
                                <div class="mb-3">
                                    <label class="control-label">{{$t('settings.profile.role')}}</label>
                                    <h5>{{role}}</h5>
                                </div>
                            </div>
                            <BaseButton style="float: left; color: green; border-color: green; margin-left: 0px;" class="btn btn-primary btn-md btn-block" :buttonLabel="$t('settings.profile.update')" buttonType="submit" :onClick="updateUser"></BaseButton>
                        </form>
                    </card>
                </div>
            </div>
        </div>
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue'
import BaseButton from 'src/components/BaseButton.vue'
import UserService from 'src/services/user'
import SignedService from 'src/services/signed'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import Utils from 'src/mixins/utils'
import Swal from 'sweetalert2'
export default {
    mixins: [Utils],
    components: {
        BaseButton,
        VueCropper
    },
    data() {
        return {
            emailNotification: true,
            role: '',
            rolePublicId: '',
            name: '',
            userName: '',
            email: '',
            locale: '',
            imgChosen: false,
            imgSrc: '',
            cropImg: null,
            cropSize: '',
            sizeOk: false
        }
    },
    methods: {
        async loadUser() {
            const userPublicId = this.getUserPublicId();
            const user = await UserService.getUserById(userPublicId);
            this.emailNotification = user.notifications.all;
            this.role = user.role.name;
            this.rolePublicId = user.role.publicId;
            this.name = user.name;
            this.userName = user.userName;
            this.email = user.email;
            this.locale = user.locale;
            this.imgSrc = user.photoUrl;
        },
        async updateUser() {
            try {
                Swal.fire({
                    title: 'Submitting',
                    onOpen: () => {
                        Swal.showLoading();
                    }
                });
                const userPublicId = this.getUserPublicId();
                const photoUrl = this.cropImg == null ? this.imgSrc : await this.uploadImage();
                const formJSON = {
                    name: this.name,
                    userName: this.userName,
                    email: this.email,
                    locale: this.locale,
                    rolePublicId: this.rolePublicId,
                    notifications: this.emailNotification,
                    photoUrl
                };
                const updUser = await UserService.editUser(userPublicId, formJSON);
                Swal.fire({
                    title: this.$t('settings.profile.success'),
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
                this.loadUser();
            } catch (e) {
                const message = e[1].errors[0];
                console.error(e)
                Swal.fire({
                    title: 'Error!',
                    text: message,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        },
        setImage(e) {
            const file = e.target.files[0];

            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');
                return;
            }

            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                let scoper = this;

                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result);
                    this.imgChosen = true;
                    // setTimeout(function(){
                    // scoper.cropImage();
                    // }, 500);
                };

                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        },
        showFileChooser() {
            this.$refs.input.click();
        },
        cropImage() {
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.imgSrc = this.cropImg;
        },
        useCropImage() {
            this.imgChosen = false;
        },
        cropMoveEvent(e){
            this.updateCropSize();
        },
        updateCropSize(){
            var cropData = this.$refs.cropper.getData();
            this.cropSize = Math.floor(cropData.width) + ' x ' + Math.floor(cropData.height) + ' px';
            this.sizeOk = cropData.width >= 600 && cropData.height >= 100;
        },
        async uploadImage(){
            try{
                const userPublicId = this.getUserPublicId();
                const subdomain = this.getSubdomain();
                let params = {
                    name: subdomain,
                    fileName: `${userPublicId}.png`,
                    content: this.cropImg,
                    fileType: 'image/png',
                    url: '',
                    profileImage: true
                }
                params.url = await SignedService.upload(params);
                let blobData = this.dataURItoBlob(params.content);
                let uploaded = await this.uploadToS3(params.url, blobData, params.fileType);
                let fileUrl = uploaded.url.split("?")[0];
                return fileUrl;
            }catch(err){
                return false;
            }
        },
        dataURItoBlob(dataURI) {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type: 'image/png'});
        }
    },
    async mounted () {
      await this.loadUser();
    }
}
</script>

<style scoped>
    #settingsContent {
        padding: 0px 30px;
    }
    .invisible {
        display: none;
    }
    input[type="file"] {
        display: none;
    }
    .subNote{
        color: #999;
    }
    .logoSize{
        color: #e03030;
        text-align: right;
    }
    .logoSizeOk{
        color: #509050 !important;
    }
</style>>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("div", { staticClass: "content", attrs: { id: "users" } }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("div", { staticClass: "page-title" }, [
                        _vm._v(_vm._s(_vm.$t("users.title")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-category" }, [
                        _vm._v(_vm._s(_vm.$t("dwallet.subTitle")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "container-search-bar-and-create-button searchContainer"
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.searchInputValue($event)
                              }
                            }
                          },
                          [
                            _c("BaseInput", {
                              ref: "searchInput",
                              attrs: {
                                addonRightIcon: "fa fa-search",
                                placeholder: "Search",
                                onClick: _vm.searchInputValue,
                                onKeyUp: _vm.searchInputValue
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "fieldGroup",
                                staticStyle: {
                                  "margin-top": "10px",
                                  "text-align": "right",
                                  color: "#666"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [_vm._v(_vm._s(_vm.$t("users.show")) + ": ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filter,
                                            expression: "filter"
                                          }
                                        ],
                                        staticClass: "form-control small",
                                        attrs: {
                                          name: "filter",
                                          id: "fieldFilterUsers"
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.filter = $event.target
                                                .multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            },
                                            _vm.onFilterChanged
                                          ]
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              name: "activeOption",
                                              value: "active"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("users.active"))
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              name: "inactiveOption",
                                              value: "inactive"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("users.inactive"))
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.isCreateAllowed("users")
                                      ? _c("BaseButton", {
                                          staticStyle: {
                                            padding: "12px 10px",
                                            margin: "10px 0px",
                                            float: "right"
                                          },
                                          attrs: {
                                            buttonLabel:
                                              "+ " + _vm.$t("users.create"),
                                            onClick: _vm.createUser
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { clear: "both" } }),
                    _vm._v(" "),
                    _vm.searchWord && !_vm.searchResults.length
                      ? _c("div", { staticClass: "loadingMessage" }, [
                          _vm._v(_vm._s(_vm.$t("general.no_results")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.table || !_vm.table.data
                      ? _c("div", { staticClass: "loadingMessage" }, [
                          _vm._v(_vm._s(_vm.$t("general.loading")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.table.data &&
                    (!_vm.searchWord ||
                      (_vm.searchWord && _vm.searchResults.length))
                      ? _c("data-table", {
                          attrs: {
                            items: _vm.table.data,
                            headers: _vm.table.columns,
                            editDelete: true,
                            page: "user",
                            editCallback: _vm.editCallback
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("DelModal", {
        attrs: {
          onClickQuit: _vm.quitDelete,
          onClickDelete: _vm.confirmDelete,
          title: _vm.$t("users.delete")
        }
      }),
      _vm._v(" "),
      _c("ReactivateModal", {
        attrs: {
          onClickQuit: _vm.quitReactivate,
          onClickReactivate: _vm.confirmReactivate,
          title: _vm.$t("users.reactive")
        }
      }),
      _vm._v(" "),
      _c("CreateEditUser", {
        ref: "createEditUser",
        attrs: {
          onClickQuit: _vm.quitCreateEdit,
          onFormValidated: _vm.onFormValidated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
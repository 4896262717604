<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
  >

    <v-card>
        <v-card-title class="headline">
            <div class="col-6 pull-left">{{$t('general.error_message_title')}}</div>
            <div class="col-6">
                <v-icon class="pull-right" @click="dialog = false">close</v-icon>
            </div>
        </v-card-title>
        <v-card-title class="headline">
            <div class="col-12">
                <textarea :readonly="true" v-model="message" type="text" id="errorMessage" name="errorMessage" class="form-control" style="min-height: 180px;"></textarea>
            </div>
        </v-card-title>
    </v-card>

  </v-dialog>
</template>

<script>
import moment from 'moment';
import { deserializeError } from 'serialize-error';

export default {
    name: "ErrorMessageDialog",
    data() {
        return {
            dialog: false,
            message: '',
        }
    },
    methods: {
        async updateData (item) {
            const statusInfo = item.statusInfo;

            item.statusInfo.forEach(item => {
                const formattedDate = moment(item.at).format('LLL');
                this.message += `Attempt #${item.attempt} at ${formattedDate}\n`;
                this.message += deserializeError(item.error) + '\n';
                this.message += '----\n';
            });

            this.dialog = true;
        }
    }
}
</script>

<style>

</style>

const api = require('./api-caller')

module.exports = {
  listDatasources: () => {
    const endpoint = `/audit/data-sources`
    return api(endpoint, 'GET')
  },

  listDatasets: () => {
    const endpoint = `/audit/datasets`
    return api(endpoint, 'GET')
  },

  listViews: () => {
    const endpoint = `/audit/data-apps`
    return api(endpoint, 'GET')
  },

  listUsers: () => {
    const endpoint = `/audit/users`
    return api(endpoint, 'GET')
  }
}

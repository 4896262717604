const api = require('./api-caller')

module.exports = {
  transferView: (body) => {
    const endpoint = '/shares'
    return api(endpoint, 'PUT', body)
  },
  deleteTransferFromMe: (type, publicId) => {
    const endpoint = `/shares/${type}/${publicId}`
    return api(endpoint, 'DELETE')
  }
}

<template>
  <v-app>
    <div class="content" id="datasets">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <card
              class="strpied-tabled-with-hover grid-container"
              header-classes="col-6 pull-left"
              body-classes="table-full-width table-responsive "
              style="margin: 0px"
            >
              <template slot="header">
                <div class="page-title">{{ $t("datasets.title") }}</div>

                <div class="pull-left" style="padding: 0px">
                  <h4 class="boldText" style="margin: 0px">{{ name }}</h4>
                </div>
                <div class="col-4 pull-left" style="padding-top: 5px">
                  <p class="card-category">by {{ organization }}</p>
                </div>
              </template>

              <div class="col-6 pull-left containerRight">
                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("datasets.provenance") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ provenance }}</b>
                  </div>
                  <div class="col-3 infoCol3" style="padding: 0px">
                    {{ $t("datasets.rightsHolder") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ enumRightsHolder[rightsHolder] }}</b>
                  </div>
                </div>
                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("datasets.type") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ type }}</b>
                  </div>
                  <div class="col-3 infoCol3" style="padding: 0px">
                    {{ $t("datasets.connectionType") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ connectionType }}</b>
                  </div>
                </div>

                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("datasets.user") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ user }}</b>
                  </div>
                  <div class="col-3 infoCol3" style="padding: 0px">
                    {{ $t("datasets.updated_date") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ createdAt }}</b>
                  </div>
                </div>

                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("datasets.version") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ version }}</b>
                  </div>
                  <div class="col-3 infoCol3" style="padding: 0px">
                    {{ $t("datasets.numberOfRows") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ numberOfRows }}</b>
                  </div>
                </div>
              </div>

              <DataAssetDIMContainer
                v-if="fieldsLoaded"
                :dimScore="dataAssetDimScore"
                :strPII="strPII"
                :publicId="publicId"
                :dataAssetType="dataAssetType"
              ></DataAssetDIMContainer>
            </card>

           <DataAssetTransactions
              v-if="fieldsLoaded"
              :dataAssetPublicId="publicId"
              :dataAssetName="name"
            ></DataAssetTransactions>

            <card
              class="strpied-tabled-with-hover"
              body-classes="table-full-width table-responsive"
            >
              <div class="row panelRow">
                <div class="col-10 panelTitle">{{ $t("datasets.fields") }}</div>
              </div>
              <div v-if="!fieldsLoaded" class="loadingMessage">
                {{ $t("general.loading") }}
              </div>
              <v-data-table
                v-if="fieldsLoaded"
                :headers="columns"
                :items="fields"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPageItems"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td>{{ props.item.position }}</td>

                    <td>{{ props.item.name }}</td>

                    <td>{{ props.item.dataType }}</td>

                    <td>{{ props.item.includesPII }}</td>

                    <td>
                      {{
                        props.item.piiDataFormat
                          ? props.item.piiDataFormat
                          : "-"
                      }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </card>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>


<script>
import Vue from "vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import DataTable from "src/components/DataTable.vue";
import Swal from "sweetalert2";
import DatasetService from "src/services/dataset";
import CertificatesService from "src/services/certificates";
import TransactionsService from "src/services/transactions";
import Utils from "src/mixins/utils";
import OrganizationLibraryService from "src/services/organizationLibrary";
import DataAssetTransactions from "src/components/DataAssetTransactions.vue";
import DataAssetDIMContainer from "src/components/DataAssetDIMContainer.vue";

export default {
  mixins: [Utils],
  components: {
    Card,
    BaseInput,
    BaseButton,
    DataTable,
    DataAssetTransactions,
    DataAssetDIMContainer,
  },
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        { text: this.$t("datasets.table.position"), value: "position" },
        { text: this.$t("datasets.table.name"), value: "name" },
        { text: this.$t("datasets.table.type"), value: "dataType" },
        { text: this.$t("datasets.table.includesPII"), value: "includesPII" },
        {
          text: this.$t("datasets.table.piiDataFormat"),
          value: "piiDataFormat",
        },
      ],
      columnsBorrows: [
        { text: this.$t("transactions.tableBorrows.email"), value: "email" },
        {
          text: this.$t("transactions.tableBorrows.userName"),
          value: "name",
        },
        {
          text: this.$t("transactions.tableBorrows.borrowedAt"),
          value: "borrowedAt",
        },
      ],
      columnsTransfers: [
        { text: this.$t("transactions.tableTransfers.email"), value: "email" },
        {
          text: this.$t("transactions.tableTransfers.userName"),
          value: "name",
        },
        {
          text: this.$t("transactions.tableTransfers.startAt"),
          value: "startAt",
        },
        { text: this.$t("transactions.tableTransfers.endAt"), value: "endAt" },
        {
          text: this.$t("transactions.tableTransfers.intent"),
          value: "intent",
        },
      ],
      rowsPerPageItems: [10, 50, 100],
      pagination: {
        sortBy: "position",
        rowsPerPage: 100,
      },
      paginationBorrows: {
        sortBy: "borrowedAt",
        rowsPerPage: 10,
      },
      paginationTransfers: {
        sortBy: "startAt",
        rowsPerPage: 10,
      },
      enumRightsHolder: this.$t("dsCreateEdit.form.rightsHolderOptions").reduce(
        (acc, cur) => {
          if (cur.value !== "") {
            acc[cur.value] = cur.text;
          }
          return acc;
        },
        {}
      ),

      type: "",
      provenance: "",
      connectionType: "",

      publicId: "",
      name: "",
      numberOfRows: "-",
      rightsHolder: "",
      score: "",
      data_score: "",
      info_score: "",
      meaning_score: "",
      version: "",
      diffDays: "",
      createdAt: "",

      organization: "",
      user: "",

      fields: [],
      fieldsToBeEdited: [],
      fieldsLoaded: false,
      strPII: "",

      dataAssetType: "dataset",
      dataAssetDimScore: {
        score: 0,
        data_score: 0,
        meaning_score: 0,
        info_score: 0,
      }
    };
  },
  methods: {
    async loadDatasetInfo(id) {
      try {
        let c = await CertificatesService.getCertificate(id);

        this.type = c.type;
        this.provenance = c.provenance;
        this.connectionType = c.connectionType;

        this.publicId = c.publicId;
        this.name = c.name;
        this.numberOfRows = c.numberOfRows;
        this.rightsHolder = c.rightsHolder;
        this.version = c.version;

        if (c.dimScore) {
          this.dataAssetDimScore.data_score =
            c.dimScore.D.total == null ? 0 : c.dimScore.D.total;
          this.dataAssetDimScore.info_score =
            c.dimScore.I.total == null ? 0 : c.dimScore.I.total;
          this.dataAssetDimScore.meaning_score =
            c.dimScore.D.total == null ? 0 : c.dimScore.M.total;
          this.dataAssetDimScore.score =
            c.dimScore.total == null ? 0 : c.dimScore.total;
        }

        this.diffDays = this.diffDate(c.createdAt);
        this.createdAt = this.formatDate(c.createdAt);

        this.organization = c.organizationName;
        this.user = c.userName;

        this.fields = c.fields;
        this.fieldsToBeEdited = c.fields;

        if (this.fields !== null) {
          const arrPII = [];
          this.fields.map((x) => arrPII.push(x.includesPII));

          if (arrPII.includes("true")) {
            this.strPII = "Yes";
          } else if (arrPII.includes("unknown") || arrPII.includes(null)) {
            this.strPII = "Unknown";
          } else {
            this.strPII = "No";
          }
        }
        this.fieldsLoaded = true;
      } catch (err) {
        console.error(err)
        Swal.fire({
          title: "Error!",
          text:
            "It was not possible to load the Certificate information. Try again later",
          type: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    formatDate(date_str) {
      return date_str ? date_str.replace("T", " ").split(".")[0] : date_str;
    },
    diffDate(date_str) {
      if (date_str) {
        const update_date = new Date(date_str.replace("T", " ").split(".")[0]);
        const date = new Date();
        const diffTime = Math.abs(date - update_date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      }
    },
    changedIncludes(event) {
      let publicId = event.target.value.split("|")[0];
      let includes = event.target.value.split("|")[1];
      this.fieldsToBeEdited = this.fieldsToBeEdited.map((x) => {
        if (x.publicId == publicId) {
          x.includesPII = includes;
        }
        if (x.includesPII != "true" && x.piiDataFormat != "") {
          x.piiDataFormat = "";
        }
        return x;
      });
    },
    changedDataFormat(event) {
      let publicId = event.target.value.split("|")[0];
      let format = event.target.value.split("|")[1];
      this.fieldsToBeEdited = this.fieldsToBeEdited.map((x) => {
        if (x.publicId == publicId) {
          x.piiDataFormat =
            x.includesPII != "true"
              ? ""
              : format == ""
              ? x.piiDataFormat
              : format;
        }
        return x;
      });
    },
    async updateFields() {
      try {
        Swal.fire({
          title: "Submiting",
          onOpen: () => {
            Swal.showLoading();
          },
        });
        await DatasetService.updateFields(this.publicId, this.fieldsToBeEdited);
        this.$router.go(0);
        Swal.fire({
          title: "Fields Updated!",
          type: "success",
          showConfirmButton: false,
          timer: 1000,
          onBeforeOpen: () => {
            Swal.hideLoading();
          },
        });
      } catch (err) {
        let error = err[1].errors[0];
        console.error(err)
        Swal.fire({
          title: "Error!",
          text: error,
          type: "error",
          confirmButtonText: "Ok",
          onBeforeOpen: () => Swal.hideLoading(),
        });
      }
    },
    getWindowWidth(event) {
      const GET = document.querySelectorAll.bind(document);
      let windowWidth = document.documentElement.clientWidth;
      if (windowWidth <= 1400) {
        let containers = GET(".containerRight");
        Array.from(containers).map((x) => {
          if (x.classList.contains("col-6")) {
            x.classList.remove("col-6");
            x.classList.add("col-8");
          } else if (x.classList.contains("col-8")) {
            x.classList.remove("col-8");
            x.classList.add("col-6");
          }
        });
        let labels = GET(".infoCol2");
        Array.from(labels).map((x) => {
          if (x.classList.contains("col-2")) {
            x.classList.remove("col-2");
            x.classList.add("col");
          } else if (x.classList.contains("col-2")) {
            x.classList.remove("col");
            x.classList.add("col-2");
          }
        });
        let data = GET(".infoCol3");
        Array.from(data).map((x) => {
          if (x.classList.contains("col-3")) {
            x.classList.remove("col3");
            x.classList.add("col");
          } else if (x.classList.contains("col-3")) {
            x.classList.remove("col");
            x.classList.add("col3");
          }
        });
      }
    },
  },
  async mounted() {
    await this.loadDatasetInfo(this.$route.params.publicId);
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>


<style scoped>
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: unset;
  margin-left: unset;
}
.info-row {
  padding: 2px 0px;
}
.panelRow {
  border-bottom: solid 1px #ccc;
  padding: 10px 0px 10px 0px;
  width: 100%;
}

.boldText {
  font-weight: bold;
}

.panelCounter {
  padding: 10px 23px 0px 0px;
  text-align: right;
}

.ds-info-text {
  color: #999;
}

.ds-info-text b {
  color: #000;
  font-weight: normal;
}

.score {
  position: absolute;
  right: 10px;
  top: 10px;
}

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.viewData, function(item, index) {
      return _c(
        "card",
        { key: index },
        [
          _c("template", { slot: "header" }, [
            _c("h4", { staticClass: "card-title" }, [_vm._v(_vm._s(item.name))])
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "viewList" },
            _vm._l(item.views, function(views, index) {
              return views.visible === undefined || views.visible === true
                ? _c("li", { key: index }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#/" + item.name + "/" + views.name,
                          target: "_blank",
                          rel: "noopener"
                        }
                      },
                      [
                        _c("img", { attrs: { src: views.thumbnailUrl } }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n          " + _vm._s(views.name) + "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
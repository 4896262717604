const api = require('./api-caller')
const { setAuthZToken, setOrganization, removeUserInfo, removeAuthZToken } = require('../mixins/utils').methods

export default {
  authenticate: async (organizationPublicId, username, password) => {
    const authNBody = {
      orgPubId: organizationPublicId,
      username,
      password
    }
    const { token } = await api('/authn', 'POST', authNBody)
    setAuthZToken(token)
    return token
  },

  getOrganization: async (subdomain) => {
    const org = await api(`/subdomain/${subdomain}`, 'GET')
    setOrganization(org)
    return org
  },

  logout: async () => {
    await api('/authn/revoke', 'POST')
    removeUserInfo()
    removeAuthZToken()
  },

  getUserRecovery: async (recoveryId) => {
    const response = await api(`/user-recovery/${recoveryId}`, 'GET')
    return response.userRecovery
  },

  createPassword: async (recoveryId, username, password, confirmPassword, acceptedTerms) => {
    await api(`/user-recovery/${recoveryId}`, 'POST', {
      user_name: username,
      password,
      confirm_password: confirmPassword,
      accepted_terms: acceptedTerms
    })
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content", attrs: { id: "datasets" } }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover grid-container",
                  staticStyle: { margin: "0px" },
                  attrs: {
                    "header-classes": "col-6 pull-left",
                    "body-classes": "table-full-width table-responsive "
                  }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("datasets.title")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "pull-left",
                        staticStyle: { padding: "0px" }
                      },
                      [
                        _c(
                          "h4",
                          {
                            staticClass: "boldText",
                            staticStyle: { margin: "0px" }
                          },
                          [_vm._v(_vm._s(_vm.name))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-4 pull-left",
                        staticStyle: { "padding-top": "5px" }
                      },
                      [
                        _c("p", { staticClass: "card-category" }, [
                          _vm._v("by " + _vm._s(_vm.organization))
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 pull-left containerRight" }, [
                    _c("div", { staticClass: "row info-row ds-info-text" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-2 infoCol2",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.provenance")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.provenance))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.rightsHolder")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.enumRightsHolder[_vm.rightsHolder])
                            )
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row info-row ds-info-text" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-2 infoCol2",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.type")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.type))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.connectionType")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.connectionType))])]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row info-row ds-info-text" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-2 infoCol2",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.user")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.user))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.updated_date")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.createdAt))])]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row info-row ds-info-text" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-2 infoCol2",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.version")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.version))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3",
                          staticStyle: { padding: "0px" }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("datasets.numberOfRows")) +
                              ":\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-3 infoCol3 pull-left",
                          staticStyle: { padding: "0px" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.numberOfRows))])]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.fieldsLoaded
                    ? _c("DataAssetDIMContainer", {
                        attrs: {
                          dimScore: _vm.dataAssetDimScore,
                          strPII: _vm.strPII,
                          publicId: _vm.publicId,
                          dataAssetType: _vm.dataAssetType
                        }
                      })
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.fieldsLoaded
                ? _c("DataAssetTransactions", {
                    attrs: {
                      dataAssetPublicId: _vm.publicId,
                      dataAssetName: _vm.name
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("div", { staticClass: "row panelRow" }, [
                    _c("div", { staticClass: "col-10 panelTitle" }, [
                      _vm._v(_vm._s(_vm.$t("datasets.fields")))
                    ])
                  ]),
                  _vm._v(" "),
                  !_vm.fieldsLoaded
                    ? _c("div", { staticClass: "loadingMessage" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.loading")) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fieldsLoaded
                    ? _c("v-data-table", {
                        attrs: {
                          headers: _vm.columns,
                          items: _vm.fields,
                          pagination: _vm.pagination,
                          "rows-per-page-items": _vm.rowsPerPageItems
                        },
                        on: {
                          "update:pagination": function($event) {
                            _vm.pagination = $event
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.position))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(props.item.name))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.dataType))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.includesPII))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            props.item.piiDataFormat
                                              ? props.item.piiDataFormat
                                              : "-"
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4111201059
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
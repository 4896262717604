<template>
  <div>
    <div id="watermark">
      <img src="../assets/imgs/dw_logo_gradient.png" style="height: 100%" />
    </div>
    <div id="qrcode"></div>
    <div id="dimContainer">
      <div>
        {{ $t("datasets.score") }}

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon
                small
                data-toggle="tooltip"
                data-placement="top"
                style="opacity: unset; transition: none"
                >info</v-icon
              >
            </span>
          </template>
          <div v-html="dimscoreInfoText" style="padding: 15px" />
        </v-tooltip>
      </div>
      <div v-if="dimScoreIsTreated" class="dimScoreValue">
        {{ score }}
      </div>
    </div>

    <div v-if="dimScoreIsTreated" id="dimPartsContainer">
      <div>
        <div class="dimPartValue">{{ data_score }}</div>
        {{ $t("datasets.data_score") }}
      </div>
      <div>
        <div class="dimPartValue">{{ info_score }}</div>
        {{ $t("datasets.info_score") }}
      </div>
      <div>
        <div class="dimPartValue">{{ meaning_score }}</div>
        {{ $t("datasets.meaning_score") }}
      </div>
    </div>

    <div id="piiContainer">
      <div style="color: #999">{{ $t("datasets.pii") }}:</div>
      <div style="color: #000">{{ strPII }}</div>
    </div>

    <div id="certificateNumber">
      <span style="color: #999">{{ $t("datasets.certificate_num") }}:</span>
      <span style="color: #000">{{ publicId }}</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Card from "src/components/Cards/Card.vue";
import Swal from "sweetalert2";
import QRCode from "davidshimjs-qrcodejs";
import Utils from "src/mixins/utils";

export default {
  mixins: [Utils],
  name: "data-asset-dim-containet",
  components: {
    Card,
  },
  props: {
    dimScore: Object,
    strPII: String,
    publicId: String,
    dataAssetType: String,
  },
  data() {
    return {
      dimScoreIsTreated: false,
      dimscoreInfoText: this.$t("datasets.dimscore_info"),

      score: "",
      data_score: "",
      info_score: "",
      meaning_score: "",
    };
  },
  methods: {},
  async mounted() {
    if (this.dataAssetType == "dataapp")
      this.dimscoreInfoText = this.$t("dataApps.dimscore_info");

    this.score =
      this.dimScore.score == null ? 0 : this.dimScore.score.toFixed(2);
    this.data_score =
      this.dimScore.data_score == null
        ? 0
        : this.dimScore.data_score.toFixed(2);
    this.info_score =
      this.dimScore.info_score == null
        ? 0
        : this.dimScore.info_score.toFixed(2);
    this.meaning_score =
      this.dimScore.meaning_score == null
        ? 0
        : this.dimScore.meaning_score.toFixed(2);

    this.dimScoreIsTreated = true;

    this.qrcode = new QRCode("qrcode", {
      text: window.location.href,
      width: 160,
      height: 160,
      colorDark: "#000000",
      colorLight: "#ffffff",
    });
  },
};
</script>

<style>
.score {
  position: absolute;
  right: 10px;
  top: 10px;
}

#qrcode {
  position: absolute;
  right: 10px;
  top: 10px;
}

#certificate-num {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
#dimContainer {
  color: #999;
  position: absolute;
  top: 10px;
  right: 340px;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
}
.dimScoreValue {
  color: #f7941e;
  font-size: 31px;
  font-weight: bold;
  line-height: 40px;
}
#dimPartsContainer {
  color: #999;
  position: absolute;
  top: 10px;
  right: 200px;
  line-height: 19px;
  border-left: solid 1px #ccc;
}
.dimPartValue {
  width: 40px;
  text-align: right;
  margin-right: 10px;
  display: inline-block;
  color: #000;
}

#certificateNumber {
  position: absolute;
  top: 180px;
  right: 12px;
  font-size: 14px;
}
#piiContainer {
  position: absolute;
  top: 87px;
  right: 205px;
  font-size: 12px;
  text-align: center;
  width: 200px;
  border-top: solid 1px #ccc;
  padding-top: 10px;
  font-size: 16px;
}
#watermark {
  position: absolute;
  top: 0px;
  right: 245px;
  width: 300px;
  height: 210px;
}
.v-tooltip__content:after, .v-tooltip__content:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 5px;
    margin-left: -5px;
  }
</style>
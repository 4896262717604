var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "content screen-area", attrs: { id: "createEditUser" } },
      [
        _c("div", { staticClass: "container-fluid " }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("h4", {
                      staticClass: "mb-3",
                      attrs: { id: "createEditUserTitle" }
                    }),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        attrs: {
                          id: "createEditForm",
                          action: "",
                          method: "post"
                        },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "fieldName" }
                            },
                            [_vm._v(_vm._s(_vm.$t("userCreate.form.name")))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nameValue,
                                expression: "nameValue"
                              }
                            ],
                            staticClass: "form-control medium",
                            attrs: {
                              type: "text",
                              id: "fieldName",
                              name: "name",
                              required: ""
                            },
                            domProps: { value: _vm.nameValue },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.nameValue = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("userCreate.validName")) +
                                "\n                                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "fieldUsername" }
                            },
                            [_vm._v(_vm._s(_vm.$t("userCreate.form.userName")))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userNameValue,
                                expression: "userNameValue"
                              }
                            ],
                            staticClass: "form-control medium",
                            attrs: {
                              readonly: _vm.isEdit ? true : false,
                              type: "text",
                              id: "fieldUserName",
                              name: "userName",
                              required: ""
                            },
                            domProps: { value: _vm.userNameValue },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.userNameValue = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "fieldEmail" }
                            },
                            [_vm._v(_vm._s(_vm.$t("userCreate.form.email")))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailValue,
                                expression: "emailValue"
                              }
                            ],
                            staticClass: "form-control medium",
                            attrs: {
                              type: "email",
                              id: "fieldEmail",
                              name: "email",
                              required: ""
                            },
                            domProps: { value: _vm.emailValue },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.emailValue = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "fieldRoles" }
                            },
                            [_vm._v(_vm._s(_vm.$t("userCreate.form.role")))]
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _vm.roles.length
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.rolePublicId,
                                      expression: "rolePublicId"
                                    }
                                  ],
                                  staticClass: "form-control small",
                                  attrs: { name: "roles", id: "fieldRoles" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.rolePublicId = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                _vm._l(_vm.roles, function(policy) {
                                  return _c(
                                    "option",
                                    { domProps: { value: policy.publicId } },
                                    [_vm._v(_vm._s(policy.name))]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.roles.length
                            ? _c("div", { staticClass: "noLicensesWarning" }, [
                                _vm._v(_vm._s(_vm.$t("userCreate.noLicenses")))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.rolesDict && _vm.rolePublicId
                            ? _c("div", { staticClass: "seatsWarning" }, [
                                _vm._v(
                                  "(Only " +
                                    _vm._s(
                                      _vm.rolesDict[_vm.rolePublicId].available
                                    ) +
                                    " seats left for this type!)"
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "fieldLocale" }
                            },
                            [_vm._v(_vm._s(_vm.$t("userCreate.form.locale")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.localeValue,
                                  expression: "localeValue"
                                }
                              ],
                              staticClass: "form-control small",
                              attrs: { name: "locale", id: "fieldLocale" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.localeValue = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "pt-br" } }, [
                                _vm._v("Brazilian Portuguese")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "en" } }, [
                                _vm._v("English")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "mb-4" }),
                        _vm._v(" "),
                        _c("BaseButton", {
                          staticClass: "btn btn-primary btn-lg btn-block",
                          staticStyle: {
                            float: "right",
                            color: "green",
                            "border-color": "green"
                          },
                          attrs: {
                            buttonLabel: _vm.$t("basebutton.confirm"),
                            onClick: _vm.validateForm,
                            buttonType: "submit"
                          }
                        }),
                        _vm._v(" "),
                        _c("BaseButton", {
                          staticClass: "btn btn-primary btn-lg btn-block",
                          staticStyle: { float: "right" },
                          attrs: {
                            buttonLabel: _vm.$t("basebutton.cancel"),
                            onClick: _vm.onClickQuit
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const api = require('./api-caller')
const { getOrganizationPublicId } = require('../mixins/utils').methods

module.exports = {
  upload: (params) => {
    let endpoint = `/signed-urls/upload?name=${params.name}&fileName=${params.fileName}&fileType=${params.fileType}`
    if (params.orgImage) {
      endpoint += '&orgImage=true'
    } else if (params.profileImage) {
      endpoint += '&profileImage=true'
    }

    return api(endpoint, 'GET')
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "card",
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "page-title" }, [
                    _vm._v(_vm._s(_vm.$t("landscapes.title")))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "card-category" }, [
                    _vm._v(_vm._s(_vm.$t("landscapes.subTitle")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "container-search-bar-and-create-button searchContainer"
                    },
                    [
                      _c(
                        "form",
                        {
                          staticStyle: { padding: "15px" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.searchInputValue($event)
                            }
                          }
                        },
                        [
                          _c("BaseInput", {
                            ref: "searchInput",
                            attrs: {
                              addonRightIcon: "fa fa-search",
                              placeholder: "Search",
                              onKeyUp: _vm.searchInputValue,
                              onClick: _vm.searchInputValue
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              2
            ),
            _vm._v(" "),
            _c("ViewCard", { attrs: { viewData: _vm.landscapes } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
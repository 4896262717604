const api = require('./api-caller')
const { getOrganizationPublicId } = require('../mixins/utils').methods

const baseEndpoint = '/organizations'

module.exports = {
  listOrganizations: () => {
    const endpoint = baseEndpoint
    return api(endpoint, 'GET')
  },
  getOrganization: (organizationId) => {
    const endpoint = `${baseEndpoint}/${organizationId}`
    return api(endpoint, 'GET')
  },
  deleteOrganization: (organizationId) => {
    const endpoint = `${baseEndpoint}/${organizationId}`
    return api(endpoint, 'DELETE')
  },
  createOrganization: (formData) => {
    const endpoint = baseEndpoint
    return api(endpoint, 'POST', formData)
  },
  editOrganization: (organizationId, editDataset) => {
    const endpoint = `${baseEndpoint}/${organizationId}`
    return api(endpoint, 'PUT', editDataset)
  },
  listLicenseTypes: () => {
    const endpoint = '/license-types'
    return api(endpoint, 'GET')
  },
  listRoles: () => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/roles`
    return api(endpoint, 'GET')
  }

}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen-area ", attrs: { id: "createEditModal" } },
    [
      _c("div", { staticClass: "modal-container " }, [
        _c("div", { staticClass: "col-md-12 order-md-1" }, [
          _c("h4", {
            staticClass: "mb-3",
            attrs: { id: "createEditModalTitle" }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { id: "createEditForm", action: "", method: "post" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _vm.mode == "upload"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-3 dropbox",
                          style: { opacity: _vm.fileUrl == "" ? 1 : 0.3 }
                        },
                        [
                          _c("input", {
                            ref: "file",
                            attrs: {
                              type: "file",
                              id: "fieldFile",
                              name: "file",
                              accept: ".csv, .xls, .xlsx"
                            },
                            on: { change: _vm.fileChanged }
                          }),
                          _vm._v(" "),
                          this.fileName == ""
                            ? _c(
                                "p",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "fieldFile" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("dsCreateEdit.form.csv.title")
                                    ) + " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("dsCreateEdit.form.csv.subTitle")
                                      )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.fileName != ""
                            ? _c(
                                "p",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "fieldFile" }
                                },
                                [_vm._v(_vm._s(this.fileName))]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _vm.fileName
                        ? _c("BaseButton", {
                            staticClass: "btn btn-primary btn-lg btn-block",
                            staticStyle: {
                              float: "right",
                              color: "#999",
                              "border-color": "#999",
                              "padding-top": "7px",
                              "padding-bottom": "7px",
                              "margin-top": "-10px"
                            },
                            attrs: {
                              buttonLabel: _vm.$t("basebutton.clear"),
                              onClick: _vm.resetUpload,
                              buttonType: "submit"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "fileUrl" }
                          },
                          [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.fileUrl")))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.fileUrl,
                              expression: "fileUrl"
                            }
                          ],
                          staticClass: "form-control medium",
                          style: {
                            "border-color":
                              _vm.fileUrl !== "" ? "#999" : "#E3E3E3"
                          },
                          attrs: {
                            type: "text",
                            id: "fileUrl",
                            name: "name",
                            placeholder: _vm.$t(
                              "dsCreateEdit.form.fileUrl_placeholder"
                            )
                          },
                          domProps: { value: _vm.fileUrl },
                          on: {
                            keyup: _vm.fileUrlChanged,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.fileUrl = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.type === "csv" && _vm.type !== ""
                        ? _c("span", [
                            _c("div", { staticClass: "mb-3" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "fieldSeparator" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("dsCreateEdit.form.separator")
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.separator,
                                      expression: "separator"
                                    }
                                  ],
                                  staticClass: "form-control small",
                                  attrs: {
                                    name: "rowNames",
                                    id: "fieldSeparator"
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.separator = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.updateExampleCsvTemplate
                                    ]
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "," } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dsCreateEdit.form.delimiters.comma"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: ";" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dsCreateEdit.form.delimiters.semicolon"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "\t" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dsCreateEdit.form.delimiters.tabular"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "|" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dsCreateEdit.form.delimiters.pipe"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mb-3" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "fieldHeaders" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("dsCreateEdit.form.headers.title")
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.rowNames,
                                      expression: "rowNames"
                                    }
                                  ],
                                  staticClass: "form-control small",
                                  attrs: {
                                    name: "rowNames",
                                    id: "fieldHeaders"
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.rowNames = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.updateExampleCsvTemplate
                                    ]
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "auto" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("dsCreateEdit.form.headers.auto")
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "yes" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("dsCreateEdit.form.headers.yes")
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "no" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("dsCreateEdit.form.headers.no")
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            !_vm.isEdit
                              ? _c("div", { staticClass: "mb-3" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "fieldFile" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "dsCreateEdit.form.example_csv"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "csv_example",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.exampleCsv)
                                    }
                                  })
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      ["excel"].includes(_vm.type) && _vm.type !== ""
                        ? _c("span", [
                            !_vm.isEdit
                              ? _c("div", { staticClass: "mb-3" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "fileUrl" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("dsCreateEdit.form.fileUrl")
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.fileUrl,
                                        expression: "fileUrl"
                                      }
                                    ],
                                    staticClass: "form-control medium",
                                    attrs: {
                                      type: "text",
                                      id: "fileUrl",
                                      name: "name",
                                      placeholder: _vm.$t(
                                        "dsCreateEdit.form.fileUrl_placeholder"
                                      )
                                    },
                                    domProps: { value: _vm.fileUrl },
                                    on: {
                                      keydown: _vm.fileUrlChanged,
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.fileUrl = $event.target.value
                                      }
                                    }
                                  })
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.mode == "database"
                ? _c("div", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("dsCreateEdit.form.tech_info")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fieldType" }
                        },
                        [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.type")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.type,
                              expression: "type"
                            }
                          ],
                          staticClass: "form-control small",
                          attrs: { name: "type", id: "fieldType" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.type = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { name: "", value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("datasources.choose_ds_type")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "redshift" } },
                            [_vm._v("AWS RedShift")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "mariadb" } },
                            [_vm._v("MariaDB")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "mysql" } },
                            [_vm._v("MySQL")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "oracle" } },
                            [_vm._v("Oracle")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "postgres" } },
                            [_vm._v("PostgreSQL")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "sqlserver" } },
                            [_vm._v("SQL Server")]
                          ),
                          _vm._v(" "),
                          _c("option", { attrs: { name: "", value: "db2" } }, [
                            _vm._v("IBM DB2")
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "saphana" } },
                            [_vm._v("SAP HANA")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "bigquery" } },
                            [_vm._v("Google BigQuery")]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    !["", "csv", "excel", "bigquery"].includes(_vm.type)
                      ? _c("span", [
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldHost" }
                              },
                              [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.host")))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.host,
                                  expression: "host"
                                }
                              ],
                              staticClass: "form-control medium",
                              attrs: {
                                type: "text",
                                id: "fieldHost",
                                name: "host"
                              },
                              domProps: { value: _vm.host },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.host = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldCloduProvider" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("dsCreateEdit.form.cloudProvider")
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cloudProvider,
                                    expression: "cloudProvider"
                                  }
                                ],
                                staticClass: "form-control small",
                                attrs: {
                                  name: "cloduProvider",
                                  id: "cloduProvider"
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.cloudProvider = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              _vm._l(
                                _vm.$t(
                                  "dsCreateEdit.form.cloudProvider_options"
                                ),
                                function(cloud) {
                                  return _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: cloud.value,
                                        selected:
                                          cloud.value == _vm.cloudProvider
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(cloud.text) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "contryServer" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dsCreateEdit.form.country"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-autocomplete", {
                                staticClass: "form-control small",
                                attrs: {
                                  items: _vm.countriesOption,
                                  solo: "",
                                  label: "Choose Country"
                                },
                                model: {
                                  value: _vm.country,
                                  callback: function($$v) {
                                    _vm.country = $$v
                                  },
                                  expression: "country"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldPort" }
                              },
                              [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.port")))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.port,
                                  expression: "port"
                                }
                              ],
                              staticClass: "form-control micro",
                              attrs: {
                                type: "number",
                                id: "fieldPort",
                                name: "port"
                              },
                              domProps: { value: _vm.port },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.port = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldUser" }
                              },
                              [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.user")))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user,
                                  expression: "user"
                                }
                              ],
                              staticClass: "form-control small",
                              attrs: {
                                type: "text",
                                id: "fieldUser",
                                name: "user"
                              },
                              domProps: { value: _vm.user },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.user = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldPassword" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("dsCreateEdit.form.password"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password"
                                }
                              ],
                              staticClass: "form-control small",
                              attrs: {
                                type: "password",
                                id: "fieldPassword",
                                name: "password"
                              },
                              domProps: { value: _vm.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.password = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldDatabase" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.type == "oracle"
                                        ? "dsCreateEdit.form.serviceName"
                                        : "dsCreateEdit.form.database"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.database,
                                  expression: "database"
                                }
                              ],
                              staticClass: "form-control medium",
                              attrs: {
                                type: "text",
                                id: "fieldDatabase",
                                name: "database"
                              },
                              domProps: { value: _vm.database },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.database = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldSchema" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("dsCreateEdit.form.schema"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.schema,
                                  expression: "schema"
                                }
                              ],
                              staticClass: "form-control medium",
                              attrs: {
                                type: "text",
                                id: "fieldSchema",
                                name: "schema"
                              },
                              domProps: { value: _vm.schema },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.schema = $event.target.value
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    ["bigquery"].includes(_vm.type)
                      ? _c("span", [
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldProjectId" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("dsCreateEdit.form.projectId"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.projectId,
                                  expression: "projectId"
                                }
                              ],
                              staticClass: "form-control medium",
                              attrs: {
                                type: "text",
                                id: "fieldProjectId",
                                name: "projectId"
                              },
                              domProps: { value: _vm.projectId },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.projectId = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldEmail" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("dsCreateEdit.form.email"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email,
                                  expression: "email"
                                }
                              ],
                              staticClass: "form-control medium",
                              attrs: {
                                type: "text",
                                id: "fieldEmail",
                                name: "email"
                              },
                              domProps: { value: _vm.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.email = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldPrivateKey" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("dsCreateEdit.form.privateKey"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.privateKey,
                                  expression: "privateKey"
                                }
                              ],
                              staticClass: "form-control medium",
                              staticStyle: {
                                "min-height": "200px",
                                "min-width": "700px"
                              },
                              attrs: {
                                type: "text",
                                id: "fieldPrivateKey",
                                name: "privateKey"
                              },
                              domProps: { value: _vm.privateKey },
                              on: {
                                paste: _vm.parseEscapeNewline,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.privateKey = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldBigQueryProject" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("dsCreateEdit.form.bigQueryProject")
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.bigQueryProject,
                                  expression: "bigQueryProject"
                                }
                              ],
                              staticClass: "form-control medium",
                              attrs: {
                                type: "text",
                                id: "fieldBigQueryProject",
                                name: "bigQueryProject"
                              },
                              domProps: { value: _vm.bigQueryProject },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.bigQueryProject = $event.target.value
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "fieldBigQueryDataset" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("dsCreateEdit.form.bigQueryDataset")
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.bigQueryDataset,
                                  expression: "bigQueryDataset"
                                }
                              ],
                              staticClass: "form-control medium",
                              attrs: {
                                type: "text",
                                id: "fieldBigQueryDataset",
                                name: "bigQueryDataset"
                              },
                              domProps: { value: _vm.bigQueryDataset },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.bigQueryDataset = $event.target.value
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.mode == "api"
                ? _c("div", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("dsCreateEdit.form.tech_info")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fieldType" }
                        },
                        [_vm._v(_vm._s(_vm.$t("dsAPI.plataform")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.type,
                              expression: "type"
                            }
                          ],
                          staticClass: "form-control small",
                          attrs: { name: "type", id: "fieldType" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.type = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { name: "", value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("dsAPI.choose_plataform")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { name: "", value: "shopify" } },
                            [_vm._v("Shopify")]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fieldHost" }
                        },
                        [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.host")))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.host,
                            expression: "host"
                          }
                        ],
                        staticClass: "form-control medium",
                        attrs: { type: "text", id: "fieldHost", name: "host" },
                        domProps: { value: _vm.host },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.host = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fieldUser" }
                        },
                        [_vm._v(_vm._s(_vm.$t("dsAPI.apiKey")))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user,
                            expression: "user"
                          }
                        ],
                        staticClass: "form-control small",
                        attrs: { type: "text", id: "fieldUser", name: "user" },
                        domProps: { value: _vm.user },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.user = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "fieldPassword" }
                        },
                        [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.password")))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password"
                          }
                        ],
                        staticClass: "form-control small",
                        attrs: {
                          type: "password",
                          id: "fieldPassword",
                          name: "password"
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.password = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("dsCreateEdit.form.general_info")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "fieldName" } },
                  [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.name")))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.nameValue,
                      expression: "nameValue"
                    }
                  ],
                  staticClass: "form-control medium",
                  attrs: {
                    readonly: _vm.isEdit ? true : false,
                    type: "text",
                    id: "fieldName",
                    name: "name"
                  },
                  domProps: { value: _vm.nameValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.nameValue = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "fieldDescription" }
                  },
                  [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.description")))]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description"
                    }
                  ],
                  staticClass: "form-control medium",
                  staticStyle: { "min-height": "100px" },
                  attrs: {
                    type: "text",
                    id: "fieldDescription",
                    name: "description"
                  },
                  domProps: { value: _vm.description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.description = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "fieldName" } },
                  [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.provenance")))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.provenance,
                      expression: "provenance"
                    }
                  ],
                  staticClass: "form-control medium",
                  attrs: {
                    readonly: _vm.isEdit ? true : false,
                    type: "text",
                    id: "fieldName",
                    name: "name",
                    placeholder: _vm.$t(
                      "dsCreateEdit.form.provenance_placeholder"
                    )
                  },
                  domProps: { value: _vm.provenance },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.provenance = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("dsCreateEdit.form.rightHolder")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rightHolder,
                        expression: "rightHolder"
                      }
                    ],
                    staticClass: "form-control small",
                    attrs: {
                      disabled: _vm.isEdit ? true : false,
                      name: "rightHolder",
                      id: "fieldrightHolder"
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.rightHolder = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.rightsHolderOptions, function(holder) {
                    return _c("option", { domProps: { value: holder.value } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(holder.text) +
                          "\n                        "
                      )
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "fieldDomain" }
                  },
                  [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.domain")))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.domain,
                      expression: "domain"
                    }
                  ],
                  staticClass: "form-control medium",
                  attrs: { type: "text", id: "fieldDomain", name: "domain" },
                  domProps: { value: _vm.domain },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.domain = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.rightHolder == "other_company"
                ? _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        staticStyle: { display: "block" }
                      },
                      [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.cost")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currency,
                            expression: "currency"
                          }
                        ],
                        staticClass: "form-control small",
                        staticStyle: {
                          display: "inline",
                          width: "150px !important"
                        },
                        attrs: {
                          disabled: _vm.isEdit ? true : false,
                          name: "currency",
                          id: "fieldCurrency"
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.currency = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(
                        _vm.$t("dsCreateEdit.form.currencyOptions"),
                        function(currency) {
                          return _c(
                            "option",
                            { domProps: { value: currency.value } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(currency.text) +
                                  "\n                        "
                              )
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cost,
                          expression: "cost"
                        }
                      ],
                      staticClass: "form-control medium",
                      staticStyle: {
                        display: "inline",
                        width: "347px !important"
                      },
                      attrs: {
                        type: "text",
                        id: "fieldCost",
                        name: "cost",
                        placeholder: _vm.$t(
                          "dsCreateEdit.form.const_placeholder"
                        )
                      },
                      domProps: { value: _vm.cost },
                      on: {
                        keyup: _vm.fieldCostChanged,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.cost = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _c("h4", [_vm._v(_vm._s(_vm.$t("dsCreateEdit.form.pii_info")))]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("dsCreateEdit.form.includesPII")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.includesPII,
                        expression: "includesPII"
                      }
                    ],
                    staticClass: "form-control small",
                    attrs: {
                      disabled: _vm.isEdit ? true : false,
                      name: "includesPII",
                      id: "fieldincludesPII"
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.includesPII = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(
                    _vm.$t("dsCreateEdit.form.includesPIIOptions"),
                    function(option) {
                      return _c(
                        "option",
                        { domProps: { value: option.value } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(option.text) +
                              "\n                        "
                          )
                        ]
                      )
                    }
                  ),
                  0
                )
              ]),
              _vm._v(" "),
              _vm.includesPII == "true"
                ? _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("dsCreateEdit.form.piiDataFormat")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.piiDataFormat,
                            expression: "piiDataFormat"
                          }
                        ],
                        staticClass: "form-control small",
                        attrs: {
                          disabled: _vm.isEdit ? true : false,
                          name: "piiDataFormat",
                          id: "fieldpiiDataFormat"
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.piiDataFormat = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(
                        _vm.$t("dsCreateEdit.form.piiDataFormatOptions"),
                        function(format) {
                          return _c(
                            "option",
                            { domProps: { value: format.value } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(format.text) +
                                  "\n                        "
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("hr", { staticClass: "mb-4" }),
              _vm._v(" "),
              _c("BaseButton", {
                staticClass: "btn btn-primary btn-lg btn-block",
                staticStyle: {
                  float: "right",
                  color: "green",
                  "border-color": "green"
                },
                attrs: {
                  buttonLabel: _vm.$t(
                    _vm.mode == "upload"
                      ? "dsCreateEdit.upload"
                      : "dsCreateEdit.connect"
                  ),
                  onClick: _vm.validateForm,
                  buttonType: "submit"
                }
              }),
              _vm._v(" "),
              _c("BaseButton", {
                staticClass: "btn btn-primary btn-lg btn-block",
                staticStyle: { float: "right" },
                attrs: {
                  buttonLabel: _vm.$t("basebutton.cancel"),
                  onClick: _vm.onClickQuit
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
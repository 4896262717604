const api = require('./api-caller')
const { getOrganizationPublicId } = require('../mixins/utils').methods

const baseEndpoint = '/analytics'

function buildFinalEndpointAndCallAPI (urlPath, createdAfter) {
  let endpoint = `${baseEndpoint}/${urlPath}?`
  if (createdAfter != null) { endpoint += '&createdAfter=' + createdAfter }
  endpoint += '&organizationPublicId=' + getOrganizationPublicId()

  return api(endpoint, 'GET')
}
module.exports = {
  listOrganizations: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('list/organizations', createdAfter)
  },
  listUsers: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('list/users', createdAfter)
  },
  listProjects: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('list/projects', createdAfter)
  },
  listViews: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('list/data-apps', createdAfter)
  },
  listLandscapes: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('list/landscapes', createdAfter)
  },
  listDatasources: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('list/datasources', createdAfter)
  },
  listDatasets: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('list/datasets', createdAfter)
  },

  timeseriesOrganizations: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('timeseries/organizations', createdAfter)
  },
  timeseriesUsers: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('timeseries/users', createdAfter)
  },
  timeseriesProjects: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('timeseries/projects', createdAfter)
  },
  timeseriesViews: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('timeseries/data-apps', createdAfter)
  },
  timeseriesLandscapes: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('timeseries/landscapes', createdAfter)
  },
  timeseriesDatasources: (createdAfter) => {
    return buildFinalEndpointAndCallAPI('timeseries/datasources', createdAfter)
  }

}

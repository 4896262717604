var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("center", { staticStyle: { "padding-top": "80px" } }, [
    _c("div", { staticClass: "topBar" }, [
      _c("img", {
        staticStyle: { height: "40px" },
        attrs: { src: _vm.organizationLogo }
      })
    ]),
    _vm._v(" "),
    _vm.submitSuccess == false
      ? _c("div", [
          _c("div", { staticClass: "o-page c-login-page" }, [
            _c("div", { staticClass: "c-login-page__content" }, [
              _c("div"),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticStyle: {
                    "max-width": "320px",
                    "margin-bottom": "20px"
                  },
                  attrs: { src: require("../assets/imgs/form-header.png") }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "logo_container" }, [
                _c("img", { attrs: { src: _vm.organizationLogo } })
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "c-login-page-title" }, [
                _vm._v(_vm._s(_vm.$t("authN.forgotPassword.title")))
              ]),
              _vm._v(" "),
              _c("small", { staticStyle: { "font-size": "14px" } }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("authN.forgotPassword.subTitle")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "login_form",
                  on: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _vm.log != ""
                    ? _c("div", { staticClass: "error" }, [
                        _c(
                          "span",
                          { staticStyle: { "white-space": "pre-line" } },
                          [_vm._v(_vm._s(_vm.log))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "o-form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "300px", margin: "10px" },
                      attrs: {
                        name: "email",
                        type: "email",
                        placeholder: _vm.$t("authN.email"),
                        value: "",
                        required: "",
                        autofocus: ""
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "o-form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "baseButton",
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(_vm._s(_vm.$t("authN.button.confirm")))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("br")
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "c-login-page__footer__text" }, [
                _vm._v(_vm._s(_vm.$t("general.copyright")))
              ])
            ])
          ])
        ])
      : _c("div", [
          _c("div", { staticClass: "o-page c-login-page" }, [
            _c("div", { staticClass: "c-login-page__content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: {
                    "max-width": "320px",
                    "margin-bottom": "20px"
                  },
                  attrs: { src: require("../assets/imgs/form-header.png") }
                })
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "c-login-page-title" }, [
                _vm._v(_vm._s(_vm.$t("authN.forgotPassword.success.title")))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("authN.forgotPassword.success.p1")))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("authN.forgotPassword.success.p2")))
              ]),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "c-login-page__footer__text" }, [
                _vm._v(_vm._s(_vm.$t("general.copyright")))
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <router-view></router-view>
  </div>
</template>


<script>
import LoginService from "./services/login";
import './assets/font-awesome-4.7.0/css/font-awesome.min.css';
const $ = require('jquery')
window.$ = $

export default {
  methods:{
    async setPageTitle(){
      let pageTitle = "dWallet";
      let subdomain = window.location.hostname.split(".")[0];
      const organization = await LoginService.getOrganization( subdomain );
      if( organization )
        pageTitle += " " + organization.name;
      document.title = pageTitle;
    }
  },
  mounted(){
    this.setPageTitle();
  }
};
</script>


<style lang="scss">


@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto/Roboto-Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto/Roboto-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Material Icons are used in some components such as Data Table 
(for column icons and pagination buttons) */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(./assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}







.main-panel > .application > .application--wrap {
  top: 50px;
}
.main-panel > .application > .application--wrap > .content {
  padding: 30px 15px;
  min-height: calc(100% - 123px);
}

.page-title {
    font-size: 23px;
    color: #666;
    font-weight: 300;
    font-family: Roboto !important;
}

.section-title {
  font-size: 24px;
  color: #666;
  font-weight: 300;
  font-family: Roboto !important;
}

.panelTitle {
  font-size: 24px;
  color: #666;
  font-weight: 300;
  font-family: Roboto !important;
}

.commonPanel {
  display: inline-block;
  width: 100%;
  padding: 0px 10px;
}

hr.gradientCenter{ 
  border: 0;
  height: 1px;
  background: #999;
  background-image: linear-gradient(to right, #fff, #333, #fff);
}

h3 {
  font-size: 14px;
}

h4 {
  font-size: 12px;
}

.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
select {
  -webkit-appearance: menulist-button !important;
  width: min-content !important;
}

.nano {
  width: 50px !important;
}

.micro {
  width: 100px !important;
}

.small {
  width: 200px !important;
}

.medium {
  width: 500px !important;
}

.large {
  width: 700px !important;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.theme--light.application {
    background: #fff !important;
    color: rgba(0,0,0,.87);
}
.main-panel {
    background: #fff !important;
    width: 100% !important;
    max-width: 1200px;
}
.card{
  border: none !important;
}
.v-tabs__slider {
    height: 1px !important;
}
.breadcrumbDivider{
  opacity: 0.5;
  padding: 0px 5px 0px;
  font-size: 10px;
  position: relative;
  bottom: 3px;
}
.v-tabs__div {
  text-transform: capitalize !important;
}

@media (max-width: 991.98px){
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
      padding-top: 0px;
  }
  .application--wrap > .content{
    padding: 30px 0px;
  }
  .application--wrap > .content{
    padding: 30px 0px !important;
  }
  .application--wrap > .content > .container-fluid{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .v-tabs__div {
    min-width: unset !important;
}
}

.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  float: unset;
}
</style>

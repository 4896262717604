var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(_vm._s(_vm.$t("transfers.title", { assetName: this.name })))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0px 20px" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.users,
                  "item-text": "email",
                  "item-value": "self",
                  label: "Search",
                  solo: "",
                  "hide-selected": true
                },
                on: {
                  change: _vm.onUserSelectedToShare,
                  keyup: _vm.onAutoCompleteKeyUp
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(data) {
                      return [
                        data.item.in_list
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(data.item.email) +
                                  "   "
                              ),
                              _c("b", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.$t("transfers.email_already_in_list")
                                    ) +
                                    ")"
                                )
                              ])
                            ]
                          : data.item.invalid_email
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(data.item.email) +
                                  "   "
                              ),
                              _c("b", [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.$t("transfers.invalid_email")) +
                                    ")"
                                )
                              ])
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(data.item.email) +
                                  "\n          "
                              )
                            ]
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.autocompleteSelectedItem,
                  callback: function($$v) {
                    _vm.autocompleteSelectedItem = $$v
                  },
                  expression: "autocompleteSelectedItem"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "0px 20px" } }, [
            _c(
              "table",
              { staticStyle: { width: "100%" } },
              [
                _c("th", { staticClass: "row" }, [
                  _c("td", { staticClass: "col-4" }, [
                    _vm._v(_vm._s(_vm.$t("transfers.email")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-2" }, [
                    _vm._v(_vm._s(_vm.$t("transfers.start")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-2" }, [
                    _vm._v(_vm._s(_vm.$t("transfers.end")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-3" }, [
                    _vm._v(_vm._s(_vm.$t("transfers.intent")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-1" })
                ]),
                _vm._v(" "),
                _vm._l(_vm.internalItems, function(item, index) {
                  return _c("tr", { key: item.publicId, staticClass: "row" }, [
                    _c("td", { staticClass: "col-4 vertical-centered" }, [
                      _vm._v(_vm._s(item.email) + "\n          ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "nudge-right": 40,
                              transition: "scale-transition",
                              "offset-y": "",
                              "min-width": "290px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "prepend-icon": "event",
                                              readonly: ""
                                            },
                                            model: {
                                              value: item.startAt,
                                              callback: function($$v) {
                                                _vm.$set(item, "startAt", $$v)
                                              },
                                              expression: "item.startAt"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.startDateMenu,
                              callback: function($$v) {
                                _vm.$set(item, "startDateMenu", $$v)
                              },
                              expression: "item.startDateMenu"
                            }
                          },
                          [
                            _vm._v(" "),
                            _c("v-date-picker", {
                              attrs: { max: item.endAt },
                              on: {
                                input: function($event) {
                                  item.startDateMenu = false
                                }
                              },
                              model: {
                                value: item.startAt,
                                callback: function($$v) {
                                  _vm.$set(item, "startAt", $$v)
                                },
                                expression: "item.startAt"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "nudge-right": 40,
                              transition: "scale-transition",
                              "offset-y": "",
                              "min-width": "290px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "prepend-icon": "event",
                                              readonly: ""
                                            },
                                            model: {
                                              value: item.endAt,
                                              callback: function($$v) {
                                                _vm.$set(item, "endAt", $$v)
                                              },
                                              expression: "item.endAt"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.endDateMenu,
                              callback: function($$v) {
                                _vm.$set(item, "endDateMenu", $$v)
                              },
                              expression: "item.endDateMenu"
                            }
                          },
                          [
                            _vm._v(" "),
                            _c("v-date-picker", {
                              attrs: { min: item.startAt },
                              on: {
                                input: function($event) {
                                  item.endDateMenu = false
                                }
                              },
                              model: {
                                value: item.endAt,
                                callback: function($$v) {
                                  _vm.$set(item, "endAt", $$v)
                                },
                                expression: "item.endAt"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-3 vertical-centered" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.intent,
                              expression: "item.intent"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                item,
                                "intent",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", disabled: "" },
                              domProps: { selected: item.intent != "" }
                            },
                            [_vm._v(_vm._s(_vm.$t("transfers.choose_intent")))]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.intents, function(intentName) {
                            return _c(
                              "option",
                              {
                                domProps: {
                                  value: intentName,
                                  selected: intentName == item.intent
                                }
                              },
                              [_vm._v(_vm._s(intentName))]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-1" }, [
                      _c("i", {
                        staticClass: "fa fa-trash shareBtn",
                        on: {
                          click: function($event) {
                            return _vm.revokeTransfer(index)
                          }
                        }
                      })
                    ])
                  ])
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("transfers.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.onConfirmCallback } },
                [_vm._v(_vm._s(_vm.$t("transfers.confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
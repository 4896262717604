var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("center", { staticStyle: { "padding-top": "80px" } }, [
    _c("div", { staticClass: "topBar" }, [
      _c("img", {
        staticStyle: { height: "40px" },
        attrs: { src: _vm.organizationLogo }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "o-page c-login-page" }, [
      _c("div", { staticClass: "c-login-page__content" }, [
        _c("div"),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticStyle: { "max-width": "320px", "margin-bottom": "20px" },
            attrs: { src: require("../assets/imgs/form-header.png") }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "logo_container" }, [
          _c("img", { attrs: { src: _vm.organizationLogo } })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "login_form",
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.submitForm($event)
              }
            }
          },
          [
            _vm.log != ""
              ? _c("div", { staticClass: "error" }, [
                  _c("span", { staticStyle: { "white-space": "pre-line" } }, [
                    _vm._v(_vm._s(_vm.log))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "o-form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  name: "login",
                  type: "text",
                  placeholder: _vm.$t("authN.email"),
                  value: "",
                  required: "",
                  autofocus: ""
                },
                domProps: { value: _vm.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.username = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "o-form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  name: "password",
                  type: "password",
                  placeholder: _vm.$t("authN.password"),
                  value: "",
                  required: ""
                },
                domProps: { value: _vm.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "o-form-group" }, [
              _c(
                "button",
                { staticClass: "baseButton", on: { click: _vm.submitForm } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("authN.button.login")) +
                      "\n              "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "p",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    staticStyle: {
                      color: "#999",
                      "text-decoration": "underline"
                    },
                    attrs: { to: "/password-recovery" }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("authN.login.forgot")) +
                        "\n              "
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "c-login-page__footer__text" }, [
          _vm._v(_vm._s(_vm.$t("general.copyright")))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
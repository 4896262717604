var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "content", attrs: { id: "dwallet" } },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("div", { staticClass: "page-title" }, [
                        _vm._v(_vm._s(_vm.$t("dwallet.title")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-category" }, [
                        _vm._v(_vm._s(_vm.$t("dwallet.subTitle")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "container-search-bar-and-create-button searchContainer"
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.searchInputValue($event)
                              }
                            }
                          },
                          [
                            _c("BaseInput", {
                              ref: "searchInput",
                              attrs: {
                                addonRightIcon: "fa fa-search",
                                placeholder: "Search",
                                onKeyUp: _vm.searchInputValue,
                                onClick: _vm.searchInputValue
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "processingContainer" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.processingDatasources.length > 0,
                              expression: "processingDatasources.length > 0"
                            }
                          ]
                        },
                        [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("datasources.processing")))
                          ]),
                          _vm._v(
                            "\n                : " +
                              _vm._s(_vm.processingDatasources.join(", ")) +
                              "\n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errorDatasources.length > 0,
                              expression: "errorDatasources.length > 0"
                            }
                          ]
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.$t("datasources.error_processed"))
                            )
                          ]),
                          _vm._v(
                            "\n                : " +
                              _vm._s(_vm.errorDatasources.join(", ")) +
                              "\n              "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.isTheSameUserOrg
                      ? _c(
                          "div",
                          { staticClass: "connectButtonsContainer" },
                          [
                            _vm.isCreateAllowed("data-sources")
                              ? _c("BaseButton", {
                                  staticClass: "connectButton",
                                  attrs: {
                                    buttonLabel:
                                      "+ " + _vm.$t("dwallet.connectAPI"),
                                    onClick: _vm.callIntegrate
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCreateAllowed("data-sources")
                              ? _c("BaseButton", {
                                  staticClass: "connectButton",
                                  attrs: {
                                    buttonLabel:
                                      "+ " + _vm.$t("dwallet.createDB"),
                                    onClick: _vm.callConnect
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCreateAllowed("data-sources")
                              ? _c("BaseButton", {
                                  staticClass: "connectButton",
                                  attrs: {
                                    buttonLabel:
                                      "+ " + _vm.$t("dwallet.createWKC"),
                                    onClick: _vm.callWKC
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCreateAllowed("data-sources")
                              ? _c("BaseButton", {
                                  staticClass: "connectButton",
                                  attrs: {
                                    buttonLabel:
                                      "+ " + _vm.$t("dwallet.createCSV"),
                                    onClick: _vm.callUpload
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticStyle: { clear: "both" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          "margin-bottom": "20px"
                        }
                      },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: { "slider-color": "#EC008C" },
                            model: {
                              value: _vm.selectedTab,
                              callback: function($$v) {
                                _vm.selectedTab = $$v
                              },
                              expression: "selectedTab"
                            }
                          },
                          [
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Data Sources")
                              ]),
                              _vm._v(" "),
                              _vm.datasources_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.datasources_tableDataProvider
                                            .length
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isThereErrorInDataSources,
                                      expression: "isThereErrorInDataSources"
                                    }
                                  ],
                                  staticStyle: {
                                    "margin-left": "6px",
                                    padding: "2px 7px"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      src: require("../../assets/imgs/attention_icon.png"),
                                      title: "Registration Error"
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Datasets")
                              ]),
                              _vm._v(" "),
                              _vm.datasets_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.datasets_tableDataProvider.length
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Data Apps")
                              ]),
                              _vm._v(" "),
                              _vm.views_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.views_tableDataProvider.length
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedTab == 0
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.datasources_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("general.no_results")) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.datasources_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_datasources,
                                    items: _vm.datasources_tableDataProvider,
                                    page: "dwallet",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    transferCallback: _vm.transferCallback,
                                    deleteCallback: _vm.deleteRow,
                                    postCallback: _vm.postRow,
                                    unpostCallback: _vm.unpostRow,
                                    unborrowCallback: _vm.unborrowRow,
                                    dataProductCallback:
                                      _vm.openDataProductDialog,
                                    embedCallback: _vm.openEmbedDialog,
                                    errorMessageCallback: _vm.openErrorMessage,
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTab == 1
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.datasets_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("general.no_results")) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.datasets_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_datasets,
                                    items: _vm.datasets_tableDataProvider,
                                    page: "dwallet",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    transferCallback: _vm.transferCallback,
                                    deleteCallback: _vm.deleteRow,
                                    postCallback: _vm.postRow,
                                    unpostCallback: _vm.unpostRow,
                                    unborrowCallback: _vm.unborrowRow,
                                    dataProductCallback:
                                      _vm.openDataProductDialog,
                                    embedCallback: _vm.openEmbedDialog,
                                    errorMessageCallback: _vm.openErrorMessage,
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTab == 2
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.views_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("general.no_results")) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.views_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_views,
                                    items: _vm.views_tableDataProvider,
                                    page: "dwallet",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    transferCallback: _vm.transferCallback,
                                    deleteCallback: _vm.deleteRow,
                                    postCallback: _vm.postRow,
                                    unpostCallback: _vm.unpostRow,
                                    unborrowCallback: _vm.unborrowRow,
                                    dataProductCallback:
                                      _vm.openDataProductDialog,
                                    embedCallback: _vm.openEmbedDialog,
                                    errorMessageCallback: _vm.openErrorMessage,
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("TransferDialog", {
          ref: "transferDialog",
          attrs: { onConfirmTransfer: _vm.onConfirmTransfer }
        }),
        _vm._v(" "),
        _c("EmbedDialog", { ref: "embedDialog" }),
        _vm._v(" "),
        _c("ErrorMessageDialog", { ref: "errorMessageDialog" }),
        _vm._v(" "),
        _c(
          "FormDialog",
          {
            ref: "formDialog",
            attrs: {
              title: _vm.$t("dataProduct.title", [_vm.dataProductName]),
              information: _vm.$t("dataProduct.detail", [_vm.dataProductName]),
              confirmCallBack: _vm.createDataProduct,
              closeCallBack: _vm.closeDataProductForm,
              confirmLabel: "Yes, Please",
              closeLabel: "Cancel "
            }
          },
          [
            _c(
              "form",
              { attrs: { slot: "form" }, slot: "form" },
              [
                _c(
                  "label",
                  { staticClass: "input-label", attrs: { for: "fieldName" } },
                  [_vm._v("Give a name to your data product")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dataProductName,
                      expression: "dataProductName"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "fieldName",
                    name: "name",
                    required: ""
                  },
                  domProps: { value: _vm.dataProductName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.dataProductName = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "input-label",
                    staticStyle: { "margin-top": "20px" },
                    attrs: { for: "fieldDescription" }
                  },
                  [_vm._v("Describe this data product")]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dataProductDescription,
                      expression: "dataProductDescription"
                    }
                  ],
                  staticClass: "form-control",
                  staticStyle: { "min-height": "90px" },
                  attrs: { type: "text" },
                  domProps: { value: _vm.dataProductDescription },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.dataProductDescription = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "input-label",
                    staticStyle: { "margin-top": "20px" },
                    attrs: { for: "fieldTags" }
                  },
                  [
                    _vm._v(
                      'Choose one or more Tags (i.e. "people", "health", "business", "weather", ...)'
                    )
                  ]
                ),
                _vm._v(" "),
                _c("v-combobox", {
                  attrs: { clearable: "", solo: "", multiple: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function(data) {
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: { selected: data.selected, close: "" },
                              on: {
                                input: function($event) {
                                  return _vm.removeTag(data.item)
                                }
                              }
                            },
                            [_c("strong", [_vm._v(_vm._s(data.item))])]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dataProductTags,
                    callback: function($$v) {
                      _vm.dataProductTags = $$v
                    },
                    expression: "dataProductTags"
                  }
                })
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
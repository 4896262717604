var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "content screen-area", attrs: { id: "settings" } },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("h4", { staticClass: "card-title mb-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("settings.title")) +
                          " - " +
                          _vm._s(_vm.$t("settings.security.title"))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        attrs: { action: "", method: "post" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "mb-3" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("settings.security.oldPassword"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.oldPassword,
                                expression: "oldPassword"
                              }
                            ],
                            staticClass: "form-control medium",
                            attrs: {
                              type: "password",
                              id: "fieldOldPassword",
                              name: "oldPassword"
                            },
                            domProps: { value: _vm.oldPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.oldPassword = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("settings.security.newPassword"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newPassword,
                                expression: "newPassword"
                              }
                            ],
                            staticClass: "form-control medium",
                            attrs: {
                              type: "password",
                              id: "fieldNewPassword",
                              name: "newPassword"
                            },
                            domProps: { value: _vm.newPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.newPassword = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("settings.security.confirmNewPassword")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmPassword,
                                expression: "confirmPassword"
                              }
                            ],
                            staticClass: "form-control medium",
                            attrs: {
                              type: "password",
                              id: "fieldConfirmPassword",
                              name: "confirmPassword"
                            },
                            domProps: { value: _vm.confirmPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.confirmPassword = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("BaseButton", {
                          staticClass: "btn btn-primary btn-md btn-block",
                          staticStyle: {
                            float: "left",
                            color: "green",
                            "border-color": "green",
                            "margin-left": "0px"
                          },
                          attrs: {
                            buttonLabel: _vm.$t("settings.security.update"),
                            buttonType: "submit",
                            onClick: _vm.updatePassword
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
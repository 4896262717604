<template>
    <div class='container'>
        <object v-bind:data="appURL" width="100%" height="100%" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;left:0px;right:0px;bottom:0px">
            <embed v-bind:src="appURL" width="100%" height="100%">{{$t('datasources.embed_error')}}
        </object>

    </div>
</template>

<script>

export default {
    props: {
        path: String
    },
    data() {
        return {
            appURL: ''
        }
    },
    methods: {},
    created() {
        this.appURL = `${this.path}`;
    }
}
</script>

<style>

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("center", [
    _c("div", { staticClass: "o-page c-login-page" }, [
      _c("div", [
        _c("div", [
          _c("h2", { staticClass: "title text-danger" }, [
            _vm._v(_vm._s(_vm.$t("notFoundOrganization.title")))
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "title" }, [
            _vm._v(
              _vm._s(_vm.$t("notFoundOrganization.organization")) +
                " \n                  "
            ),
            _c("span", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.getSubdomain()))
            ]),
            _vm._v(
              "\n                   " +
                _vm._s(_vm.$t("notFoundOrganization.message_text"))
            ),
            _c("br"),
            _vm._v(
              "\n                   " +
                _vm._s(_vm.$t("notFoundOrganization.message_text_extended"))
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "a",
            { attrs: { href: "mailto:support@lichen.com", target: "_blank" } },
            [
              _c("button", { staticClass: "c-login-page-submit uppercase" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("notFoundOrganization.button_label")) +
                    " \n              "
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "c-login-page__footer__text" }, [
          _vm._v(_vm._s(_vm.$t("general.copyright")))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
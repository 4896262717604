/* global atob, btoa, fetch */

const parseDomain = require('parse-domain')

// localStorage
const USER_INFO = 'user_info'
const LAST_PATH = 'last_path'
const ORGANIZATION_PUBLIC_ID = 'organization_public_id'
// Cookies
const LICHEN_COOKIE = 'lichen'

const getCookie = (key) => {
  // const cookies = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')

  // if (cookies) {
  //   const cookie = cookies.pop()

  //   if (cookie) {
  //     try {
  //       const decoded = atob(cookie)
  //       return JSON.parse(decoded)
  //     } catch (err) {
  //       console.error(err)
  //     }
  //   }
  // }

  // return {}
  console.log('@@@@ Hardcoded Cookie')
  console.log('@@@@ Subdomain: ' + window.location.host)
  return JSON.parse('{"organization":{"publicId":"1b487440-d397-4a23-8998-8224134b2221","name":"DrumWave","subdomain":"drumwave","loginLogoUrl":"https://resources.lichen.com/images/drumwave/login_logo.png","isConsumer":false,"totalUsers":41},"token":"4rCktqod7ygD4EA73xUlWIRZ1BUDii3zkXQvrOtKOVbwBvgWjuy_juQasTcSDiZF"}')
}

const _inOneYearFromNow = () => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + 1)
  return date.toUTCString()
}

const setCookie = (key, content) => {
  const domainParsed = parseDomain(window.location.hostname)
  const base64dCookie = btoa(JSON.stringify(content))

  document.cookie = `${key}=${base64dCookie};domain=${domainParsed.domain}.${domainParsed.tld};Path=/;Expires=${_inOneYearFromNow()};`
}

const getUserInfo = () => {
  // return JSON.parse(window.localStorage.getItem(USER_INFO))
  console.log('@@@@ Hardcoded User Info')
  return JSON.parse('{"publicId":"951496fa-4d08-4a79-838c-e6bea8622910","email":"victor+simulator@drumwave.com","userName":"simulator","name":"simulator","photoUrl":null,"profile":{},"organization":{"subdomain":"drumwave","trialEndsAt":null,"taxId":null,"phoneNumber":null,"streetAddress":null},"dimScore":0,"profileCompleteness":0,"createAllowed":["projects","data-apps","data-sources"],"sectionAllowed":["users","projects","data-apps"],"actionAllowed":[]}')
}

const uploadToS3 = (url, content, contentType) => {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': contentType
    },
    body: content
  })
}

module.exports = {
  methods: {
    setAuthZToken: (token) => {
      const lichenCookie = getCookie(LICHEN_COOKIE)
      lichenCookie.token = token
      setCookie(LICHEN_COOKIE, lichenCookie)
    },
    getAuthZToken: () => {
      return getCookie(LICHEN_COOKIE).token
    },
    removeAuthZToken: () => {
      const lichenCookie = getCookie(LICHEN_COOKIE)
      delete lichenCookie.token
      setCookie(LICHEN_COOKIE, lichenCookie)
    },

    setOrganization: (organization) => {
      const lichenCookie = getCookie(LICHEN_COOKIE)
      lichenCookie.organization = organization
      setCookie(LICHEN_COOKIE, lichenCookie)
    },
    getOrganization: () => {
      const lichenCookie = getCookie(LICHEN_COOKIE)

      if (!lichenCookie) {
        return null
      }

      return lichenCookie.organization
    },

    getErrorMessage: (error) => {
      let message = 'Something went wrong'
      if (error.length > 1) {
        const messages = error[1].errors
        message = `${messages.join(';\n')}.`
      }
      return message
    },

    // These methods takes into account the organization selected by the user
    // if the user is part of multiple organizations
    setOrganizationPublicId: (organizationPublicId) => {
      window.localStorage.setItem(ORGANIZATION_PUBLIC_ID, organizationPublicId)
    },
    getOrganizationPublicId: () => {
      const userInfo = getUserInfo()

      if (userInfo) {
        const hasAccessToOrganizations = userInfo.sectionAllowed.includes('organizations')

        if (hasAccessToOrganizations) {
          const selectedOrganizationPubliId = window.localStorage.getItem(ORGANIZATION_PUBLIC_ID)

          if (selectedOrganizationPubliId) {
            return selectedOrganizationPubliId
          }
        }
      }

      const loggedOrganizationPublicId = getCookie(LICHEN_COOKIE).organization.publicId
      return loggedOrganizationPublicId
    },

    uploadToS3,

    setUserInfo: (userInfo) => {
      window.localStorage.setItem(USER_INFO, JSON.stringify(userInfo))
    },
    removeUserInfo: () => {
      window.localStorage.removeItem(USER_INFO)
    },

    getUserPublicId: () => {
      return getUserInfo().publicId
    },

    // Returns the user's real name (i.e. "John Doe")
    getUserName: () => {
      return getUserInfo().name
    },

    getUserEmail: () => {
      return getUserInfo().email
    },
    getUserProfilePhoto: () => {
      return getUserInfo().photoUrl || 'https://resources.lichen.com/public/shared_images/default_user_profile.png'
    },

    // Returns the user's screen name (stored in DB as user_ame) (i.e. "john_doe" )
    // To-Do: This is somewhat confusing and it's set like this for historical reasons
    // We may want to change it
    getUserScreenName: () => {
      return getUserInfo().userName
    },

    // TODO improve this
    isSectionAllowed: (resource) => {
      const userInfo = getUserInfo()
      if (!userInfo) {
        return false
      }

      const result = userInfo.sectionAllowed.includes(resource)

      if (resource === 'landscapes' || resource === 'views') {
        return result
      }

      return result && getUserInfo().createAllowed.includes(resource)
    },

    // TODO improve this
    isCreateAllowed: (resource) => {
      return getUserInfo().createAllowed.includes(resource)
    },

    isActionAllowed: (action) => {
      return getUserInfo().actionAllowed.includes(action)
    },

    abbreviateNumber: (value) => {
      let newValue = value

      if (value >= 1000) {
        const suffixes = ['', 'K', 'M', 'B', 'T']
        const suffixNum = Math.floor(('' + value).length / 3)
        let shortValue = ''

        for (let precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision))
          const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
          if (dotLessShortValue.length <= 2) {
            break
          }
        }
        if (shortValue % 1 !== 0) {
          shortValue = shortValue.toFixed(1)
        }
        newValue = shortValue + suffixes[suffixNum]
      }

      return newValue
    },

    getSubdomain: () => {
      return getUserInfo().organization.subdomain
    },
    setLastURL: (path) => {
      window.localStorage.setItem(LAST_PATH, path)
    },
    getLastURL: () => {
      return window.localStorage.getItem(LAST_PATH)
    },
    removeLastURL: () => {
      window.localStorage.removeItem(LAST_PATH)
    }
  }
}

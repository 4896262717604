var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { attrs: { id: "qrcode" } }),
    _vm._v(" "),
    _c("div", { attrs: { id: "dimContainer" } }, [
      _c(
        "div",
        [
          _vm._v("\n      " + _vm._s(_vm.$t("datasets.score")) + "\n\n      "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "span",
                        _vm._g({}, on),
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: {
                                opacity: "unset",
                                transition: "none"
                              },
                              attrs: {
                                small: "",
                                "data-toggle": "tooltip",
                                "data-placement": "top"
                              }
                            },
                            [_vm._v("info")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("div", {
                staticStyle: { padding: "15px" },
                domProps: { innerHTML: _vm._s(_vm.dimscoreInfoText) }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.dimScoreIsTreated
        ? _c("div", { staticClass: "dimScoreValue" }, [
            _vm._v("\n      " + _vm._s(_vm.score) + "\n    ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.dimScoreIsTreated
      ? _c("div", { attrs: { id: "dimPartsContainer" } }, [
          _c("div", [
            _c("div", { staticClass: "dimPartValue" }, [
              _vm._v(_vm._s(_vm.data_score))
            ]),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("datasets.data_score")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "dimPartValue" }, [
              _vm._v(_vm._s(_vm.info_score))
            ]),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("datasets.info_score")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "dimPartValue" }, [
              _vm._v(_vm._s(_vm.meaning_score))
            ]),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("datasets.meaning_score")) + "\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "piiContainer" } }, [
      _c("div", { staticStyle: { color: "#999" } }, [
        _vm._v(_vm._s(_vm.$t("datasets.pii")) + ":")
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { color: "#000" } }, [
        _vm._v(_vm._s(_vm.strPII))
      ])
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "certificateNumber" } }, [
      _c("span", { staticStyle: { color: "#999" } }, [
        _vm._v(_vm._s(_vm.$t("datasets.certificate_num")) + ":")
      ]),
      _vm._v(" "),
      _c("span", { staticStyle: { color: "#000" } }, [
        _vm._v(_vm._s(_vm.publicId))
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "watermark" } }, [
      _c("img", {
        staticStyle: { height: "100%" },
        attrs: { src: require("../assets/imgs/dw_logo_gradient.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
              <template slot="header">
                <div class="page-title">{{$t('projects.title')}}</div>
                <p class="card-category">{{$t('projects.subTitle')}}</p>
              </template>
              <template slot="raw-content">
                <div class="card-body">
                  <div class="container-search-bar-and-create-button searchContainer">
                    <form @submit.prevent="searchInputValue" style="padding: 15px">
                      <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onClick="searchInputValue" :onKeyUp="searchInputValue"></BaseInput>
                    </form>
                  </div>
                  <div class="displaySwitchContainer" style="clear: both;">
                    <div id="projectsFolderDisplay">
                      <FolderDisplay v-for="(item, index) of folderBreadcrumb" :folderId="index" :acessedFolder="item" :key="index" :onClick="filterFolder"></FolderDisplay>
                    </div>
                    <div class="switch-button">
                      <toggle-button :value="true" :color="{checked: 'lightgray', unchecked: 'lightgray'}" :labels="{checked: $t('projects.selector.option1'), unchecked: $t('projects.selector.option2')}" :width=80 @change="alternateFolderTree"/>
                    </div>
                  </div>
                </div>
                <div>
                  <BaseButton v-for="(item, index) of this.currentFolder" :buttonLabel="item.path" :value="item.path" :key="index" addonLeftIcon="fa fa-folder-o" :onClick="getInFolder"></BaseButton>
                  <DataTable class="table-hover table-striped" :headers="table1.columns" :items="table1.data" :namesToLinks="true" page="projects" :linkBuilder="linktoStudio" linkTarget="_blank"></DataTable>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>


<script>
  const config = require('config');

  import Vue from 'vue'
  import DataTable from 'src/components/DataTable.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import FolderDisplay from 'src/components/FolderDisplay.vue'
  import ProjectService from 'src/services/project'
  import ToggleButton from 'vue-js-toggle-button'
  import Swal from 'sweetalert2'
  Vue.use(ToggleButton)

  export default {
    components: {
      Card,
      BaseInput,
      BaseButton,
      FolderDisplay,
      DataTable
    },
    data () {
      return {
        table1: {
          columns: [
            { text: this.$t('projects.table.name'), align: 'left', value: 'name'},
            { text: this.$t('projects.table.creator'), value: 'user'},
            { text: this.$t('projects.table.createdAt'), value: 'created_at'},
            { text: this.$t('projects.table.updatedAt'), value: 'updated_at'}
          ],
          data: []
        },
        projectsData: {},
        folderBreadcrumb: ['Root'],
        currentFolder: []
      }
    },
    methods: {
      alternateFolderTree(target) {
        let groupBy = target.value === true? 'name': 'user';
        this.loadProjects(groupBy);
        this.folderBreadcrumb = ['Root']
      },
      createPathField(data) {
        for (const folder of data.folders) {
          folder.path = data.path ? `${data.path}/${folder.name}` : folder.name
          this.createPathField(folder)
        }
      },
      loadProjects(groupBy) {
        return ProjectService.listProjects(groupBy)
          .then( (result) => {
            this.projectsData = result;
            this.table1.data = result.projects;
            this.currentFolder = result.folders;
            this.projectsData.path = ''
            this.createPathField(this.projectsData)
            this.updateCurrentFolder();
          })
          .catch(err => {
            console.error(err)
            Swal.fire({
              title: 'Error!',
              text: 'Projects not found',
              type: 'error',
              confirmButtonText: 'Ok'
            })
          })
      },
      async searchInputValue(e) {
        this.filterBySearchInputValue(e)
      },
      filterBySearchInputValue(e) {
        const query = this.$refs.searchInput.getValue().toUpperCase();

        if (!query) {
          this.table1.data = this.projectsData.projects
          this.currentFolder = this.projectsData.folders
          return this.getInFolder(e);
        }

        const projects = []
        const folders = []

        const recursiveSearch = context => {
          for (const project of context.projects) {
            if (project.name.split('/').pop().toUpperCase().includes(query) || project.user.toUpperCase().includes(query)) {
              projects.push(project)
            }
          }

          for (const folder of context.folders) {
            if (folder.name.toUpperCase().includes(query)) {
              folders.push(folder)
            }
            recursiveSearch(folder)
          }
        }

        recursiveSearch(this.projectsData)
        this.table1.data = projects
        this.currentFolder = folders
      },
      getInFolder(e) {
        this.folderBreadcrumb = (e.target.value != "" || e.target.value === undefined) ? ['Root', ... e.target.value.split('/')] : ['Root'];
        this.updateCurrentFolder();
      },
      filterFolder(e) {
        this.folderBreadcrumb.splice((Number(e.target.id) + 1), Number.MAX_VALUE);
        this.updateCurrentFolder();
      },
      updateCurrentFolder() {
        this.currentFolder = this.projectsData.folders.filter(x => x.path != "");
        this.table1.data = this.projectsData.projects;

        this.populateTableAndFoldersDisplay();
        this.sortContentByNameProperty(this.currentFolder);
        this.sortContentByNameProperty(this.table1.data);
        this.allFoldersAndProjectsVisible();
      },
      async populateTableAndFoldersDisplay() {
        await this.folderBreadcrumb.map( context => {
          if(context !== 'Root') {
            let i = this.currentFolder.findIndex( obj => { return obj.name === context});
            this.table1.data = this.currentFolder[i].projects;
            this.currentFolder = this.currentFolder[i].folders;
          }
        });
      },
      sortContentByNameProperty(content) {
        content = content.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
        });
      },
      allFoldersAndProjectsVisible() {
        let folders = [...document.getElementsByClassName("baseButton")];
        folders.map( context => {
          context.classList.remove("d-none");
        });

        this.table1.data.map( (line, i) => {
          Vue.set(this.table1.data[i], 'visible', true);
        });
      },
      onSelectedOrganizationChanged: function(e){
        this.loadProjects('name');
      },
      linktoStudio (header, propsItem) {
        if (header != 'name') {
          return;
        }

        return `https://studio.${config.apiUrl.split('.')[1]}.com/#/${propsItem['user']}/${propsItem['name']}`;
      },
    },
    mounted () {
      this.loadProjects('name');
    },
    created(){
      var scoper = this;
      window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
    },
    beforeDestroy(){
      window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
    }
  }
</script>


<style scoped>
  .d-none {
    display: none;
  }

  .w-90 {
    width: 90%;
  }

  .switch-button {
    width: fit-content;
  }

  button > * {
    pointer-events: none;
  }

  #projectsFolderDisplay {
    display: flex;
    justify-content: flex-start;
    width: 90%;
  }

  .displaySwitchContainer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .searchContainer{
    float: right;
    margin-top: -60px;
    max-width: 300px;
    margin-bottom: -10px;
  }

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content", attrs: { id: "userCreate" } }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.$t("userCreate.createTitle")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "raw-content" },
                    [
                      _c("CreateEditUser", {
                        ref: "createEditUser",
                        attrs: {
                          onClickQuit: _vm.backToUsersList,
                          onFormValidated: _vm.onFormValidated
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
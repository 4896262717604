var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "relative", width: "min-content" },
      on: { mouseleave: _vm.hideOptionsPanel, mousewheel: _vm.hideOptionsPanel }
    },
    [
      _c("div", { staticClass: "sdasContainer" }, [
        _vm.item.type == "dataset"
          ? _c(
              "span",
              _vm._l(_vm.standardDataApps, function(sda, index) {
                return _c(
                  "div",
                  {
                    staticClass: "iconContainer iconContainerRound",
                    on: {
                      mouseenter: function($event) {
                        return _vm.displayOptionsPanel($event, sda)
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: sda.urlView,
                          target: "_blank",
                          rel: "noopener"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "dataAppIcon",
                          attrs: { src: sda.icon, title: sda.description }
                        })
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.type == "view"
          ? _c("div", { staticClass: "iconContainer iconContainerRound" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.base_view_url + _vm.item.publicId,
                    target: "_blank",
                    rel: "noopener"
                  }
                },
                [
                  _c("img", {
                    staticClass: "dataAppIcon",
                    attrs: {
                      src: require("../assets/imgs/dataApps/launch.png"),
                      title: this.$t("miscellania.data_apps.launch_dataproduct")
                    }
                  })
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.showOptions
        ? _c("div", { ref: "tooltip", staticClass: "arrow_box" }, [
            _c("div", {
              staticClass: "tooltipThumbContainer",
              style: {
                "background-image": "url(" + _vm.overedSda.thumbnail + ")"
              }
            }),
            _vm._v(" "),
            _c("div", { staticStyle: { padding: "10px" } }, [
              _c("div", { staticClass: "tooltipTitle" }, [
                _vm._v(_vm._s(_vm.overedSda.name))
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n        " + _vm._s(_vm.overedSda.description) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tooltipButtons" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.overedSda.urlStudio,
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: require("../assets/imgs/edit_icon.png") }
                    })
                  ]
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.items
        ? _c("div", { staticClass: "loadingMessage" }, [
            _vm._v(_vm._s(_vm.$t("general.loading")))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.items
        ? _c("v-data-table", {
            staticClass: "walletTable",
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              pagination: _vm.pagination,
              "rows-per-page-items": _vm.rowsPerPageItems,
              "select-all": "",
              "item-key": "name"
            },
            on: {
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "headers",
                  fn: function(props) {
                    return [
                      _c(
                        "tr",
                        _vm._l(props.headers, function(header) {
                          return _c(
                            "th",
                            {
                              class: [
                                "column",
                                _vm.pagination.descending ? "desc" : "asc",
                                header.value === _vm.pagination.sortBy
                                  ? "active"
                                  : "",
                                header.sortable === false ? "" : "sortable"
                              ],
                              on: {
                                click: function($event) {
                                  if (header.sortable !== false) {
                                    _vm.changeSort(header.value)
                                  }
                                }
                              }
                            },
                            [
                              header.sortable !== false
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("arrow_upward")
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n\n\n          "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "span",
                                              _vm._g({}, on),
                                              [
                                                header.tooltip
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          opacity: "unset",
                                                          transition: "none"
                                                        },
                                                        attrs: {
                                                          small: "",
                                                          "data-toggle":
                                                            "tooltip",
                                                          "data-placement":
                                                            "top"
                                                        }
                                                      },
                                                      [_vm._v("info")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("div", {
                                    staticStyle: { padding: "15px" },
                                    domProps: {
                                      innerHTML: _vm._s(header.tooltipText)
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  }
                },
                {
                  key: "items",
                  fn: function(props) {
                    return [
                      _c(
                        "tr",
                        [
                          _vm._l(_vm.headers, function(header) {
                            return _c(
                              "td",
                              { key: props.item.publicId + header.value },
                              [
                                header.value == "dataActions"
                                  ? _c(
                                      "div",
                                      {
                                        style:
                                          "padding-left: " +
                                          (header.sortable === false ? 0 : 20) +
                                          "px;"
                                      },
                                      [
                                        _c(
                                          "span",
                                          [
                                            _c("DataActions", {
                                              attrs: {
                                                item: props.item,
                                                transferCallback:
                                                  _vm.transferCallback,
                                                deleteItemCallback:
                                                  _vm.deleteCallback,
                                                embedCallback:
                                                  _vm.embedCallback,
                                                dataProductCallback:
                                                  _vm.dataProductCallback,
                                                postCallback: _vm.postCallback,
                                                unpostCallback:
                                                  _vm.unpostCallback,
                                                borrowCallback:
                                                  _vm.borrowCallback,
                                                unborrowCallback:
                                                  _vm.unborrowCallback,
                                                dataProductStatusChangedCallback:
                                                  _vm.dataProductStatusChangedCallback,
                                                buyDataProductCallback:
                                                  _vm.buyDataProductCallback,
                                                canTransfer: _vm.canTransfer(
                                                  props.item
                                                ),
                                                isCurrentUser:
                                                  props.item.isCurrentUser,
                                                page: _vm.page
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : props.item[header.value] != null
                                  ? _c(
                                      "div",
                                      {
                                        style:
                                          "padding-left: " +
                                          (header.sortable === false ? 0 : 20) +
                                          "px;"
                                      },
                                      [
                                        _vm.linkBuilder &&
                                        _vm.linkBuilder(
                                          header.value,
                                          props.item
                                        )
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.linkBuilder(
                                                    header.value,
                                                    props.item
                                                  ),
                                                  target: _vm.linkTarget
                                                }
                                              },
                                              [
                                                _vm.checkData(
                                                  props.item[header.value]
                                                ) === true
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.convertData(
                                                            props.item[
                                                              header.value
                                                            ]
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : header.value == "dataApps"
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _c("DataApps", {
                                                          attrs: {
                                                            item: props.item
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(
                                                            props.item[
                                                              header.value
                                                            ]
                                                          ) +
                                                          "\n              "
                                                      )
                                                    ])
                                              ]
                                            )
                                          : _c("span", [
                                              _vm.checkData(
                                                props.item[header.value]
                                              ) === true
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.convertData(
                                                          props.item[
                                                            header.value
                                                          ]
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : header.value ==
                                                  "dimScoreTotal"
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "dimColumnText"
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                props
                                                                                  .item[
                                                                                  header
                                                                                    .value
                                                                                ]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _c("b", [
                                                              _vm._v("D")
                                                            ]),
                                                            _vm._v(
                                                              ": " +
                                                                _vm._s(
                                                                  props.item.dimScore.D.total.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            "   \n                    "
                                                          ),
                                                          _c("span", [
                                                            _c("b", [
                                                              _vm._v("I")
                                                            ]),
                                                            _vm._v(
                                                              ": " +
                                                                _vm._s(
                                                                  props.item.dimScore.I.total.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            "  \n                    "
                                                          ),
                                                          _c("span", [
                                                            _c("b", [
                                                              _vm._v("M")
                                                            ]),
                                                            _vm._v(
                                                              ": " +
                                                                _vm._s(
                                                                  props.item.dimScore.M.total.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : header.value == "pii"
                                                ? _c("span", [
                                                    _c("img", {
                                                      staticClass: "piiIcon",
                                                      attrs: {
                                                        src: _vm.getPIIIcon(
                                                          props.item
                                                        )
                                                      }
                                                    })
                                                  ])
                                                : header.value == "dataApps"
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("DataApps", {
                                                        attrs: {
                                                          item: props.item
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : header.value == "wrangler"
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        bolded:
                                                          props.item
                                                            .isCurrentUser
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            props.item[
                                                              header.value
                                                            ]
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                : header.value == "size" ||
                                                  header.value ==
                                                    "numberOfCells" ||
                                                  header.value ==
                                                    "numberOfDatasets"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.abbreviateNumber(
                                                            props.item[
                                                              header.value
                                                            ]
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ])
                                                : _c(
                                                    "span",
                                                    {
                                                      style:
                                                        props.item["type"] ===
                                                          "datasource" &&
                                                        props.item["status"] ==
                                                          "error"
                                                          ? {
                                                              color: "red",
                                                              cursor: "pointer"
                                                            }
                                                          : { color: "black" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          props.item["type"] ===
                                                            "datasource" &&
                                                          props.item[
                                                            "status"
                                                          ] == "error"
                                                            ? _vm.errorMessage(
                                                                props.item
                                                              )
                                                            : ""
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            props.item[
                                                              header.value
                                                            ]
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                            ])
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "empty-field",
                                        style:
                                          "padding-left: " +
                                          (header.sortable === false ? 0 : 20) +
                                          "px;"
                                      },
                                      [_vm._v("\n            -\n          ")]
                                    )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.editDelete == true
                            ? _c(
                                "td",
                                { staticClass: "justify-center layout" },
                                [
                                  props.item.updateAllowed
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.editItem(props.item)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            edit\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  props.item.deleteAllowed
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteItem(props.item)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            delete\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  props.item.deletedAt != null
                                    ? _c("i", {
                                        staticClass:
                                          "fa fa-arrow-circle-up v-icon v-icon mr-2 theme--ligh v-icon--link",
                                        staticStyle: { "font-size": "16px" },
                                        attrs: { "aria-hidden": "true" },
                                        on: {
                                          click: function($event) {
                                            return _vm.reactiveItem(props.item)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              99263601
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Vuetify from 'vuetify'
import { i18n } from './i18n'
import 'vuetify/dist/vuetify.min.css'

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'

// router setup
import routes from './routes/routes'
import authz from './middlewares/authz'

// Sentry setup
import Log from '../src/services/log'
Log.setup(Vue)

// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)
Vue.use(Vuetify)

// configure router
const router = new VueRouter({
  routes,
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach(authz(router))

/* eslint-disable no-new */
window.mainApp = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n,
  data: () => {
    return {
    }
  },
  methods: {
  }
})

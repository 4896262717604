<template>
  <v-app>
    <div class="content" id="dataapp">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <card
              class="strpied-tabled-with-hover grid-container"
              header-classes="col-6 pull-left"
              body-classes="table-full-width table-responsive "
              style="margin: 0px"
            >
              <template slot="header">
                <div class="page-title">{{ $t("dataApps.title") }}</div>

                <div class="pull-left" style="padding: 0px">
                  <h4 class="boldText" style="margin: 0px">{{ name }}</h4>
                </div>
                <div class="col-4 pull-left" style="padding-top: 5px">
                  <p class="card-category">by {{ organization }}</p>
                </div>
              </template>

              <div class="col-6 pull-left containerRight">
                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("dataApps.user") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ user }}</b>
                  </div>
                  <div class="col-3 infoCol3" style="padding: 0px">
                    {{ $t("dataApps.updated_date") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ createdAt }}</b>
                  </div>
                </div>
                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("dataApps.project") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ project }}</b>
                  </div>
                  <div class="col-3 infoCol3" style="padding: 0px">
                    {{ $t("dataApps.project_updated_at") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ projectCreatedAt }}</b>
                  </div>
                </div>
                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("dataApps.project_origin") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ projectUser }}</b>
                  </div>
                  <div class="col-3 infoCol3" style="padding: 0px">
                    {{ $t("dataApps.project_organization") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ projectOrganization }}</b>
                  </div>
                </div>

                <div class="row info-row ds-info-text">
                  <div class="col-2 infoCol2" style="padding: 0px">
                    {{ $t("dataApps.number_of_datasets") }}:
                  </div>
                  <div class="col-3 infoCol3 pull-left" style="padding: 0px">
                    <b>{{ numberOfDatasets }}</b>
                  </div>
                </div>
              </div>
            </card>

            <DataAssetDIMContainer
              v-if="datasetsLoaded"
              :dimScore="dataAssetDimScore"
              :strPII="strPII"
              :publicId="dataAssetPublicId"
              :dataAssetType="dataAssetType"
            ></DataAssetDIMContainer>

            <DataAssetTransactions
              v-if="datasetsLoaded"
              :dataAssetPublicId="dataAssetPublicId"
              :dataAssetName="name"
            ></DataAssetTransactions>

            <card
              class="strpied-tabled-with-hover"
              body-classes="table-full-width table-responsive"
            >
              <div class="row panelRow">
                <div class="col-10 panelTitle">
                  {{ $t("dataApps.datasets") }}
                </div>
              </div>
              <div v-if="!datasetsLoaded" class="loadingMessage">
                {{ $t("general.loading") }}
              </div>
              <v-data-table
                v-if="datasetsLoaded"
                :headers="datasetsColumns"
                :items="datasets"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPageItems"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td>{{ props.item.name }}</td>

                    <td>{{ props.item.userName }}</td>

                    <td>{{ props.item.organizationName }}</td>

                    <td>
                      {{
                        $t("dataApps.data_score") +
                        ": " +
                        props.item.data_score +
                        "\n" +
                        $t("dataApps.info_score") +
                        ": " +
                        props.item.info_score +
                        "\n" +
                        $t("dataApps.meaning_score") +
                        ": " +
                        props.item.meaning_score +
                        "\n" +
                        $t("dataApps.score") +
                        ": " +
                        props.item.score
                      }}
                    </td>

                    <td>{{ props.item.version }}</td>
                  </tr>
                </template>
              </v-data-table>
            </card>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import DataTable from "src/components/DataTable.vue";
import Swal from "sweetalert2";
import CertificatesService from "src/services/certificates";
import TransactionsService from "src/services/transactions";
import Utils from "src/mixins/utils";
import OrganizationLibraryService from "src/services/organizationLibrary";
import DataAssetTransactions from "src/components/DataAssetTransactions.vue";
import DataAssetDIMContainer from "src/components/DataAssetDIMContainer.vue";

export default {
  mixins: [Utils],
  components: {
    Card,
    BaseInput,
    BaseButton,
    DataTable,
    DataAssetTransactions,
    DataAssetDIMContainer,
  },
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      dataAssetPublicId: "",
      dataAssetType: "dataapp",
      datasetsColumns: [
        { text: this.$t("dataApps.datasetsTable.name"), value: "name" },
        { text: this.$t("dataApps.datasetsTable.userName"), value: "origin" },
        {
          text: this.$t("dataApps.datasetsTable.organization"),
          value: "organization",
        },
        { text: this.$t("dataApps.datasetsTable.dimScore"), value: "dimScore" },
        { text: this.$t("dataApps.datasetsTable.version"), value: "version" },
      ],
      rowsPerPageItems: [10, 50, 100],
      pagination: {
        sortBy: "name",
        rowsPerPage: 100,
      },
      name: "",
      organization: "",
      user: "",
      createdAt: "",
      diffDays: "",
      project: "",
      projectCreatedAt: "",
      projectUser: "",
      projectOrganization: "",
      numberOfDatasets: "-",

      dataAssetDimScore: {
        score: 0,
        data_score: 0,
        meaning_score: 0,
        info_score: 0,
      },
      strPII: "",

      datasets: [],
      datasetsLoaded: false,
    };
  },
  methods: {
    async loadDataappInfo(id) {
      try {
        const c = await CertificatesService.getCertificate(id);

        this.dataAssetPublicId = c.publicId;

        this.project = c.projectName;
        this.projectCreatedAt = this.formatDate(c.projectUpdatedAt);
        this.projectUser = c.projectUserName;
        this.projectOrganization = c.projectOrganizationName;
        this.numberOfDatasets = c.datasets.length;

        this.name = c.name;

        this.diffDays = this.diffDate(c.createdAt);
        this.createdAt = this.formatDate(c.createdAt);

        this.organization = c.projectOrganizationName;
        this.user = c.userName;

        if (this.numberOfDatasets > 0) {
          this.datasets = c.datasets.map((d) => {
            d.data_score =
              d.dimScore.D.total == null ? 0 : d.dimScore.D.total.toFixed(2);
            d.info_score =
              d.dimScore.I.total == null ? 0 : d.dimScore.I.total.toFixed(2);
            d.meaning_score =
              d.dimScore.M.total == null ? 0 : d.dimScore.M.total.toFixed(2);
            d.score =
              d.dimScore.total == null ? 0 : d.dimScore.total.toFixed(2);

            if (c.fields !== null) {
              const arrPII = [];
              d.fields.map((x) => arrPII.push(x.includesPii));

              d.strPII = "No";

              if (arrPII.includes("true")) {
                d.strPII = "Yes";
              } else if (arrPII.includes("unknown") || arrPII.includes(null)) {
                d.strPII = "Unknown";
              }
            }

            return d;
          });

          const datasetsArrPII = [];
          this.datasets.map((d) => datasetsArrPII.push(d.strPII));

          if (datasetsArrPII.includes("Yes")) {
            this.strPII = "Yes";
          } else if (datasetsArrPII.includes("Unknown") || datasetsArrPII.includes(null)) {
            this.strPII = "Unknown";
          } else {
            this.strPII = "No";
          }

          this.dataAssetDimScore = this.datasets.reduce((acc, cur) => {
            acc.data_score +=
              parseFloat(cur.data_score) / this.numberOfDatasets;
            acc.info_score +=
              parseFloat(cur.info_score) / this.numberOfDatasets;
            acc.meaning_score +=
              parseFloat(cur.meaning_score) / this.numberOfDatasets;
            acc.score += parseFloat(cur.score) / this.numberOfDatasets;

            return acc;
          }, this.dataAssetDimScore);
        }

        this.datasetsLoaded = true;
      } catch (err) {
        console.error(err)
        Swal.fire({
          title: "Error!",
          text:
            "It was not possible to load the Certificate information. Try again later",
          type: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    formatDate(date_str) {
      return date_str ? date_str.replace("T", " ").split(".")[0] : date_str;
    },
    diffDate(date_str) {
      if (date_str) {
        const update_date = new Date(date_str.replace("T", " ").split(".")[0]);
        const date = new Date();
        const diffTime = Math.abs(date - update_date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      }
    },
    getWindowWidth(event) {
      const GET = document.querySelectorAll.bind(document);
      let windowWidth = document.documentElement.clientWidth;
      if (windowWidth <= 1400) {
        let containers = GET(".containerRight");
        Array.from(containers).map((x) => {
          if (x.classList.contains("col-6")) {
            x.classList.remove("col-6");
            x.classList.add("col-8");
          } else if (x.classList.contains("col-8")) {
            x.classList.remove("col-8");
            x.classList.add("col-6");
          }
        });
        let labels = GET(".infoCol2");
        Array.from(labels).map((x) => {
          if (x.classList.contains("col-2")) {
            x.classList.remove("col-2");
            x.classList.add("col");
          } else if (x.classList.contains("col-2")) {
            x.classList.remove("col");
            x.classList.add("col-2");
          }
        });
        let data = GET(".infoCol3");
        Array.from(data).map((x) => {
          if (x.classList.contains("col-3")) {
            x.classList.remove("col3");
            x.classList.add("col");
          } else if (x.classList.contains("col-3")) {
            x.classList.remove("col");
            x.classList.add("col3");
          }
        });
      }
    },
  },
  async mounted() {
    await this.loadDataappInfo(this.$route.params.publicId);
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>  
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "baseButton",
      attrs: { id: _vm.buttonId },
      on: { click: _vm.onClick }
    },
    [
      _c("i", { class: _vm.addonLeftIcon }),
      _vm._v("\n  " + _vm._s(_vm.buttonLabel) + "\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
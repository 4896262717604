var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _vm.multiple
        ? _c("v-select", {
            attrs: {
              items: _vm.data,
              attach: "",
              chips: "",
              "deletable-chips": "",
              label: _vm.label,
              color: "black",
              id: _vm.inputID,
              name: _vm.name,
              multiple: ""
            }
          })
        : _c("v-select", {
            attrs: {
              items: _vm.data,
              attach: "",
              chips: "",
              "deletable-chips": "",
              label: _vm.label,
              id: _vm.inputID,
              name: _vm.name,
              color: "black"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("center", [
    _c("div", { staticClass: "o-page c-login-page" }, [
      _c("div", { staticClass: "c-login-page__content" }, [
        _c("h1", { staticClass: "c-login-page-title" }, [
          _vm._v("Terms Of Use")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-login-page-form" }, [
          _c("textarea", { attrs: { readonly: "" } }, [
            _vm._v("            " + _vm._s(_vm.$t("termsOfUse")) + "\n        ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-login-page__footer__text" }, [
          _vm._v(_vm._s(_vm.$t("general.copyright")))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const api = require('./api-caller')

module.exports = {
  retrieveView: (viewName) => {
    const endpoint = `/wallet/data-apps/${viewName}`
    return api(endpoint, 'GET')
  },
  getAccountViews: () => {
    const endpoint = '/wallet/data-apps'
    return api(endpoint, 'GET')
  },
  deleteView: (publicId) => {
    const endpoint = `/data-apps/${publicId}`
    return api(endpoint, 'DELETE')
  },
  checkIfCanBeDeleted: (publicId) => {
    const endpoint = `/data-apps/${publicId}/canBeDeleted`
    return api(endpoint, 'GET')
  },
  checkIfCanBePublished: (publicId) => {
    const endpoint = `/is-publishable/data-apps/${publicId}`
    return api(endpoint, 'GET')
  }

}

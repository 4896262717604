const api = require('./api-caller')
const { getOrganizationPublicId } = require('../mixins/utils').methods

const baseEndpoint = '/organizations'

module.exports = {
  listDataWallets: () => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/data-wallets`
    return api(endpoint, 'GET')
  },
  listDataWalletsInMarketplace: () => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/data-wallets/getAll/published`
    return api(endpoint, 'GET')
  },
  getDataWalletByPublicId: (id) => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/data-wallets/${id}`
    return api(endpoint, 'GET')
  },
  deleteDataWallet: (id) => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/data-wallets/${id}`
    return api(endpoint, 'DELETE')
  },
  createDataWallet: (dataWallet) => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/data-wallets/`
    return api(endpoint, 'POST', dataWallet)
  },
  updateDataWallet: (id, dataWallet) => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/data-wallets/${id}`
    return api(endpoint, 'PUT', dataWallet)
  }
}

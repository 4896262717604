var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content", attrs: { id: "exchange" } }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("exchange.title")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-category" }, [
                      _vm._v(_vm._s(_vm.$t("exchange.subTitle")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "container-search-bar-and-create-button searchContainer"
                    },
                    [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.searchInputValue($event)
                            }
                          }
                        },
                        [
                          _c("BaseInput", {
                            ref: "searchInput",
                            attrs: {
                              addonRightIcon: "fa fa-search",
                              placeholder: "Search",
                              onKeyUp: _vm.searchInputValue,
                              onClick: _vm.searchInputValue
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { clear: "both" } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "20px"
                      }
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { "slider-color": "#EC008C" },
                          model: {
                            value: _vm.selectedTab,
                            callback: function($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab"
                          }
                        },
                        [
                          _c("v-tab", [
                            _c("div", { staticClass: "tabName" }, [
                              _vm._v("Datasets")
                            ]),
                            _vm._v(" "),
                            _vm.datasets_tableDataProvider
                              ? _c("div", { staticClass: "tabCounter" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datasets_tableDataProvider.length
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("v-tab", [
                            _c("div", { staticClass: "tabName" }, [
                              _vm._v("Data Apps")
                            ]),
                            _vm._v(" "),
                            _vm.views_tableDataProvider
                              ? _c("div", { staticClass: "tabCounter" }, [
                                  _vm._v(
                                    _vm._s(_vm.views_tableDataProvider.length)
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.selectedTab == 0
                    ? _c(
                        "div",
                        [
                          _vm.searchWord &&
                          !_vm.datasets_tableDataProvider.length
                            ? _c("div", { staticClass: "loadingMessage" }, [
                                _vm._v(_vm._s(_vm.$t("general.no_results")))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.searchWord ||
                          (_vm.searchWord &&
                            _vm.datasets_tableDataProvider.length)
                            ? _c("DataTable", {
                                attrs: {
                                  headers: _vm.columns_in_datasets,
                                  items: _vm.datasets_tableDataProvider,
                                  page: "exchange",
                                  linkBuilder: _vm.linktoDetails,
                                  deleteCallback: _vm.deleteProduct,
                                  buyDataProductCallback: _vm.buyDataProduct,
                                  linkTarget: "_self",
                                  defaultSortBy: "updatedAt",
                                  sortDesc: true
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedTab == 1
                    ? _c(
                        "div",
                        [
                          _vm.searchWord && !_vm.views_tableDataProvider.length
                            ? _c("div", { staticClass: "loadingMessage" }, [
                                _vm._v(_vm._s(_vm.$t("general.no_results")))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.searchWord ||
                          (_vm.searchWord && _vm.views_tableDataProvider.length)
                            ? _c("DataTable", {
                                attrs: {
                                  headers: _vm.columns_in_views,
                                  items: _vm.views_tableDataProvider,
                                  page: "exchange",
                                  linkBuilder: _vm.linktoDetails,
                                  deleteCallback: _vm.deleteProduct,
                                  linkTarget: "_self",
                                  defaultSortBy: "updatedAt",
                                  sortDesc: true
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
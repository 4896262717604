var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen-area invisible", attrs: { id: "reactivateModal" } },
    [
      _c(
        "div",
        { staticClass: "modal-container" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.$t("modal.reactivate.question")) }
          }),
          _vm._v(" "),
          _c("BaseButton", {
            staticStyle: { color: "red" },
            attrs: {
              buttonLabel: _vm.$t("modal.reactivate.confirm"),
              onClick: _vm.onClickReactivate
            }
          }),
          _vm._v(" "),
          _c("BaseButton", {
            attrs: {
              buttonLabel: _vm.$t("modal.reactivate.cancel"),
              onClick: _vm.onClickQuit
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    style="position: relative; width: min-content"
    @mouseleave="hideOptionsPanel"
    @mousewheel="hideOptionsPanel"
  >
    <div style="width: max-content">
      <div
        v-if="page == 'dwallet' && !item.borrowedByMe"
        class="iconContainer iconAction"
      >
        <i
          class="fa fa-times"
          style="cursor: pointer"
          title="Remove"
          @click="deleteRow(item)"
        ></i>
      </div>

      <!-- Transfers. Applies to all cases -->
      <div
        v-if="page == 'dwallet' && canTransfer"
        class="iconContainer iconAction"
      >
        <i
          class="fa fa-share-alt"
          style="cursor: pointer"
          :title="this.$t('miscellania.data_apps.transfer')"
          @click="openShareDialog(item)"
        ></i>
      </div>

      <div
        v-if="item.type == 'dataproduct' && page == 'dwallet' && isCurrentUser"
        class="iconContainer iconAction"
      >
        <img
          class="dataAppIcon"
          style="cursor: pointer"
          src="../assets/imgs/dataApps/embed.png"
          title="Embed code"
          @click="openEmbedDialog(item)"
        />
      </div>

      <div
        v-if="page == 'dwallet' && !item.post && canTransfer"
        class="iconContainer iconAction"
      >
        <img
          src="../assets/imgs/library_post.png"
          title="Post to Organization Library"
          class="libraryIcon"
          @click="postRow(item)"
        />
      </div>
      <div v-if="item.post && canTransfer" class="iconContainer iconAction">
        <img
          src="../assets/imgs/library_unpost.png"
          title="Remove from Organization Library"
          class="libraryIcon"
          @click="unpostRow(item)"
        />
      </div>

      <div v-if="page == 'dwallet' && canTransfer" class="iconContainer iconAction">
        <i class="fa fa-usd" style="cursor: pointer;" title="Create Data Product" @click="openDataProductDialog(item)"></i>
      </div>

      <div v-if="item.type === 'datasource' && item.status == 'error'" class="iconContainer col-2">
        <img class="dataAppIcon" style="cursor: pointer;" src="../assets/imgs/edit_icon.png" title="Edit Data Source" @click="callEditPage(item)" />
      </div>

      <div
        v-if="item.type === 'datasource' && item.status !== 'error'"
        class="iconContainer col-2"
      ></div>

      <!-- Borrowing / unborrowing  -->
      <div
        v-if="
          page == 'orgLibrary' &&
          !item.isCurrentUser &&
          !item.borrowedByMe &&
          !isItInMydWallet(item)
        "
        class="iconContainer iconAction"
      >
        <img
          src="../assets/imgs/library_borrow.png"
          title="Borrow"
          class="libraryIcon"
          @click="borrowItem(item)"
        />
      </div>

      <div
        v-if="!item.isCurrentUser && item.borrowedByMe"
        class="iconContainer iconAction"
      >
        <img
          src="../assets/imgs/library_unborrow.png"
          title="Return to library"
          class="libraryIcon"
          @click="unborrowCallback(item)"
        />
      </div>

      <!-- Data Products / Exchange -->
      <div v-if="page == 'auditaccount' " class="iconContainer iconAction">
        <select v-model="item.status" name="status" id="statusAction" @change="dataProductStatusChangedCallback(item)" :class="changeClass(item)">
          <option value="pending" :style="item.status != 'pending' ? 'display:none' : ''" :v-show="item.status == 'pending'">PENDING</option>
          <option value="approved">{{ item.status == 'approved' ? 'APPROVED' : 'APPROVE'}}</option>
          <option value="rejected">{{ item.status == 'rejected' ? 'REJECTED' : 'REJECT'}}</option>
        </select>
      </div>

      <div v-if="page == 'auditaccount' && item.type != 'purchase' "  class="iconContainer iconAction">
        <i class="fa fa-cog" aria-hidden="true" style="cursor: pointer;" title="Edit Data Product" @click="openDataProductDialog(item)"></i>
      </div>

      <div v-if="page == 'auditaccount' && item.status != 'pending' && item.type != 'purchase' " class="iconContainer iconAction">
        <i class="fa fa-times" style="cursor: pointer;" title="Remove" @click="deleteRow(item)"></i>
      </div>

      <div v-if="page == 'exchange' && !isItFromMyOrg(item.organization.publicId) ">
        <i class="fa fa-shopping-cart" aria-hidden="true" title="Buy" style="cursor: pointer;" @click="buyDataProductCallback(item)" ></i>
      </div>

      <div v-if="page == 'exchange' && isSectionAllowed('dataProductsManagement') && isItFromMyOrg(item.organization.publicId)" class="iconContainer iconAction">
        <i class="fa fa-times" style="cursor: pointer;" title="Remove" @click="deleteRow(item)"></i>
      </div>

    </div>
  </div>
</template>

<script>
import Utils from "../mixins/utils";
import ViewService from "src/services/view";
import Swal from "sweetalert2";

export default {
  props: {
    item: {
      type: Object,
    },
    transferCallback: Function,
    deleteItemCallback: Function,
    embedCallback: Function,
    postCallback: Function,
    unpostCallback: Function,
    borrowCallback: Function,
    unborrowCallback: Function,
    dataProductCallback: Function,
    dataProductStatusChangedCallback: Function,
    buyDataProductCallback: Function,
    canTransfer: Boolean,
    isCurrentUser: Boolean,
    page: String,
  },
  mixins: [Utils],
  data() {
    return {
      base_view_url: "/#/views/",
      showOptions: false,
      overedSda: null,
    };
  },
  methods: {
    async openShareDialog(item) {
      if (item.type == "dataproduct") {
        Swal.fire({
          onOpen: () => {
            Swal.showLoading();
          },
        });

        const canBePublished = await ViewService.checkIfCanBePublished(
          item.publicId
        );

        Swal.close();

        if (!canBePublished.throughShare) {
          Swal.fire({
            html: this.$t("dwallet.cantBeShared"),
            type: "info",
            confirmButtonText: "Ok",
          });

          return;
        }
      }
      this.transferCallback(item);
    },
    async deleteRow(item) {
      await this.deleteItemCallback(item);
    },
    async postRow(item) {
      await this.postCallback(item);
    },
    changeClass(item) {
      switch(item.status) {
        case 'pending':
          return 'pending-status'
        case 'approved':
          return 'approved-status'
        case 'rejected':
          return 'rejected-status'
      }
    },
    isItFromMyOrg(organizationId) {
      return organizationId == this.getOrganizationPublicId();
    },
    async unpostRow(item) {
      await this.unpostCallback(item);
    },
    async borrowItem(item) {
      await this.borrowCallback(item);
    },
    openEmbedDialog(item) {
      this.embedCallback(item);
    },
    openDataProductDialog(item) {
      this.dataProductCallback(item);
    },
    hideOptionsPanel() {
      this.showOptions = false;
    },
    callEditPage(item) {
      this.$router.push({ path: `datasources/${item.publicId}` });
    },
    isItInMydWallet(item) {
      if(item.transferredTo) {
        const userPublicId = this.getUserPublicId();
        return item.transferredTo.reduce((boolDefault, row) => row.publicId == userPublicId || boolDefault, false);
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.dataAppIcon{
  margin: 0px 2px;
  width: 20px;
  height: 20px;
}

.shareBtn{
  cursor: pointer;
  float: right;
  padding: 5px 0px;
  opacity: 0.3;
}
.shareBtn:hover{
  opacity: 1;
}

.disabledDataApp{
  opacity: 0.2;
}

.iconContainer{
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 4px;
}

.iconContainerRound{
  margin-right: -1px;
}

.iconAction{
  padding: 3px;
}

.arrow_box {
  background: #ffffff;
  border: 1px solid #949494;
  border-radius: 5px;
  padding: 0px;
  position: fixed;
  z-index: 1000;
  width: 250px;
  box-shadow: 2px 4px 8px -4px #888;
}
.arrow_box:after, .arrow_box:before {
  top: 100%;
  right: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 5px;
  margin-left: -5px;
}
.arrow_box:before {
  border-color: rgba(148, 148, 148, 0);
  border-top-color: #949494;
  border-width: 6px;
  margin-left: -6px;
}

.tooltipTitle{
  font-size: 16px;;
  font-weight: bold;
  margin-bottom: 2px;

}
.tooltipThumbContainer{
  width: 100%;
  height: 170px;
  overflow: hidden;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  border-radius: 4px;
}

.tooltipButtons{
  text-align: right;
}
.tooltipButtons img{
  width: 16px;
  height: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.tooltipButtons img:hover{
  opacity: 1;
}

.libraryIcon{
  height: 20px;
  cursor: pointer;
}

.pending-status {
  background-color: #d2a31f;
  color: white;
}

.approved-status {
  background-color: green;
  color: white;
}

.rejected-status {
  background-color: #e65959;
  color: white;
}

</style>

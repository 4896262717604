var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content screen-area",
      attrs: { id: "createEditOrganization" }
    },
    [
      _c("div", { staticClass: "container-fluid " }, [
        _c("div", { staticClass: "row" }, [
          _c("input", {
            ref: "input",
            attrs: { type: "file", name: "image", accept: "image/*" },
            on: { change: _vm.setImage }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("h4", {
                    staticClass: "mb-3",
                    attrs: { id: "createEditOrganizationTitle" }
                  }),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: {
                        id: "createEditForm",
                        action: "",
                        method: "post"
                      },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "fieldName" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("organizationCreate.form.name"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.nameValue,
                              expression: "nameValue"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "fieldName",
                            name: "name",
                            required: ""
                          },
                          domProps: { value: _vm.nameValue },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.nameValue = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "fieldSubdomain" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("organizationCreate.form.subdomain")
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.subdomainValue,
                              expression: "subdomainValue"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            readonly: _vm.isEdit ? true : false,
                            type: "text",
                            id: "fieldSubdomain",
                            name: "subdomain",
                            required: ""
                          },
                          domProps: { value: _vm.subdomainValue },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.subdomainValue = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.comingFromMyOrgPage
                        ? _c(
                            "div",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "input-label",
                                  attrs: { for: "fieldEmailDomains" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("organizationCreate.form.domain")
                                    )
                                  )
                                ]
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("v-combobox", {
                                attrs: {
                                  label: "drumwave.com",
                                  clearable: "",
                                  solo: "",
                                  multiple: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                selected: data.selected,
                                                close: ""
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.removeDomain(
                                                    data.item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(_vm._s(data.item))
                                              ])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1600517945
                                ),
                                model: {
                                  value: _vm.domains,
                                  callback: function($$v) {
                                    _vm.domains = $$v
                                  },
                                  expression: "domains"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isActionAllowed("licensesManagement")
                        ? _c(
                            "div",
                            {
                              staticClass: "mb-3",
                              staticStyle: { "max-width": "200px" }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "input-label",
                                  attrs: { for: "fieldTrialEndsAt" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "organizationCreate.form.trialEndsAt"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-switch", {
                                attrs: { label: "Trial never ends" },
                                model: {
                                  value: _vm.trialNeverEnds,
                                  callback: function($$v) {
                                    _vm.trialNeverEnds = $$v
                                  },
                                  expression: "trialNeverEnds"
                                }
                              }),
                              _vm._v(" "),
                              !_vm.trialNeverEnds
                                ? _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "290px"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        "prepend-icon": "event",
                                                        readonly: "",
                                                        id: "fieldTrialEndsAt",
                                                        name: "trialEndsAt"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.trialEndsAtValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.trialEndsAtValue = $$v
                                                        },
                                                        expression:
                                                          "trialEndsAtValue"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1212440981
                                      ),
                                      model: {
                                        value: _vm.trialEndsAtMenu,
                                        callback: function($$v) {
                                          _vm.trialEndsAtMenu = $$v
                                        },
                                        expression: "trialEndsAtMenu"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-date-picker", {
                                        on: {
                                          input: function($event) {
                                            _vm.trialEndsAtMenu = false
                                          }
                                        },
                                        model: {
                                          value: _vm.trialEndsAtValue,
                                          callback: function($$v) {
                                            _vm.trialEndsAtValue = $$v
                                          },
                                          expression: "trialEndsAtValue"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isActionAllowed("licensesManagement") &&
                      !_vm.comingFromMyOrgPage
                        ? _c(
                            "div",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "input-label",
                                  attrs: { for: "fieldLicenses" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("organizationCreate.form.licenses")
                                    )
                                  )
                                ]
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _vm._l(_vm.parsedLicenses, function(item) {
                                return _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.total,
                                        expression: "item.total"
                                      }
                                    ],
                                    staticClass:
                                      "form-control micro licenseNumberInput",
                                    attrs: {
                                      min: "0",
                                      max: "999",
                                      type: "number",
                                      required: ""
                                    },
                                    domProps: { value: item.total },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          "total",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(item["license-type"].name) +
                                      " accounts\n                    "
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "fieldLogo" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("organizationCreate.form.logoLogin")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3" }, [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.imgSrc && !_vm.imgChosen,
                                  expression: "imgSrc && !imgChosen"
                                }
                              ],
                              staticStyle: { width: "50%" },
                              attrs: { src: _vm.imgSrc }
                            })
                          ]),
                          _vm._v(" "),
                          _c("vue-cropper", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imgSrc && _vm.imgChosen,
                                expression: "imgSrc && imgChosen"
                              }
                            ],
                            ref: "cropper",
                            attrs: {
                              src: _vm.imgSrc,
                              alt: "Source Image",
                              cropend: _vm.cropImage,
                              cropmove: _vm.cropMoveEvent,
                              width: "100%"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.imgChosen,
                                  expression: "imgChosen"
                                }
                              ],
                              staticClass: "subNote logoSize",
                              class: { logoSizeOk: _vm.sizeOk }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("organizationCreate.form.cropSize") +
                                    ": " +
                                    _vm.cropSize
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("BaseButton", {
                                staticClass: "btn btn-primary btn-lg btn-block",
                                attrs: {
                                  buttonLabel: _vm.$t(
                                    "basebutton.choose_image"
                                  ),
                                  onClick: _vm.showFileChooser
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "subNote",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("organizationCreate.form.logoSize")
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "mb-4" }),
                      _vm._v(" "),
                      _c("BaseButton", {
                        staticClass: "btn btn-primary btn-lg btn-block",
                        staticStyle: {
                          float: "right",
                          color: "green",
                          "border-color": "green"
                        },
                        attrs: {
                          buttonLabel: _vm.$t("basebutton.confirm"),
                          onClick: _vm.validateForm,
                          buttonType: "submit"
                        }
                      }),
                      _vm._v(" "),
                      _c("BaseButton", {
                        staticClass: "btn btn-primary btn-lg btn-block",
                        staticStyle: { float: "right" },
                        attrs: {
                          buttonLabel: _vm.$t("basebutton.cancel"),
                          onClick: _vm.onClickQuit
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("div", { staticClass: "content", attrs: { id: "organizations" } }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("div", { staticClass: "page-title" }, [
                        _vm._v(_vm._s(_vm.$t("organizations.title")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-category" }, [
                        _vm._v(_vm._s(_vm.$t("organizations.subTitle")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "container-search-bar-and-create-button searchContainer"
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.searchInputValue($event)
                              }
                            }
                          },
                          [
                            _c("BaseInput", {
                              ref: "searchInput",
                              attrs: {
                                addonRightIcon: "fa fa-search",
                                placeholder: "Search",
                                onKeyUp: _vm.searchInputValue,
                                onClick: _vm.searchInputValue
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isCreateAllowed("organizations")
                      ? _c("BaseButton", {
                          staticStyle: {
                            padding: "12px 10px",
                            margin: "20px 0px",
                            float: "right"
                          },
                          attrs: {
                            buttonLabel: "+ " + _vm.$t("organizations.create"),
                            onClick: _vm.createOrganization
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticStyle: { clear: "both" } }),
                    _vm._v(" "),
                    _vm.searchWord && !_vm.searchResults.length
                      ? _c("div", { staticClass: "loadingMessage" }, [
                          _vm._v(_vm._s(_vm.$t("general.no_results")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.data
                      ? _c("div", { staticClass: "loadingMessage" }, [
                          _vm._v(_vm._s(_vm.$t("general.loading")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data &&
                    (!_vm.searchWord ||
                      (_vm.searchWord && _vm.searchResults.length))
                      ? _c("data-table", {
                          attrs: {
                            items: _vm.data,
                            headers: _vm.columns,
                            editDelete: true,
                            page: "organizations",
                            editCallback: _vm.editCallback
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("DelModal", {
        attrs: {
          onClickQuit: _vm.quitDelete,
          onClickDelete: _vm.confirmDelete,
          title: _vm.$t("organizations.delete")
        }
      }),
      _vm._v(" "),
      _c("CreateEditOrganization", {
        ref: "createEditOrganization",
        attrs: {
          onClickQuit: _vm.quitCreateEdit,
          parentData: _vm.myForm,
          onFormValidated: _vm.onFormValidated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
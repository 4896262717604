const api = require('./api-caller')

module.exports = {
  listDatasetFields: (id) => {
    const endpoint = `/wallet/datasets/${id}/fields`
    return api(endpoint, 'GET')
  },
  getDatasetByPublicId: (id) => {
    const endpoint = `/wallet/datasets/${id}`
    return api(endpoint, 'GET')
  },
  getDatasets: () => {
    const endpoint = '/wallet/datasets'
    return api(endpoint, 'GET')
  },
  updateRightHolder: (id, json) => {
    const endpoint = `/datasets/${id}`
    return api(endpoint, 'PATCH', json)
  },
  updateFields: (id, fields) => {
    const endpoint = `/datasets/${id}/fields`
    return api(endpoint, 'PATCH', fields)
  }
}

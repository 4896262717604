var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar navbar-expand-lg topbar" }, [
    !_vm.isSectionAllowed("organizations")
      ? _c("div", { staticClass: "logo" }, [
          _c("div", { staticClass: "logo-img" }, [
            _c("img", { attrs: { src: _vm.getOrganization().loginLogoUrl } })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid" }, [
      _vm.isSectionAllowed("organizations") &&
      _vm.organizations &&
      _vm.organizations.length > 1
        ? _c("div", { staticClass: "filterContainer" }, [
            _c(
              "div",
              { staticClass: "organizationSelectorContainer filterElement" },
              [
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary dropdown-toggle",
                      attrs: {
                        type: "button",
                        id: "dropdownMenuButton",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      }
                    },
                    [
                      _c("a", { attrs: { href: "#/dwallet" } }, [
                        _c("div", { staticClass: "logo-img" }, [
                          !_vm.selectedOrganizationLogo
                            ? _c("span", { staticClass: "label" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.selectedOrganizationName) +
                                    "\n                "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedOrganizationLogo
                            ? _c("img", {
                                attrs: { src: _vm.selectedOrganizationLogo }
                              })
                            : _vm._e()
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      attrs: { "aria-labelledby": "dropdownMenuButton" }
                    },
                    _vm._l(_vm.organizations, function(item, index) {
                      return _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.changeSelectedOrganization(
                                $event,
                                index
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "navbar-toggler navbar-toggler-right",
          class: { toggled: _vm.$sidebar.showSidebar },
          staticStyle: { display: "none" },
          attrs: {
            type: "button",
            "aria-controls": "navigation-index",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation"
          },
          on: { click: _vm.toggleSidebar }
        },
        [
          _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-toggler-bar burger-lines" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-toggler-bar burger-lines" })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "collapse navbar-collapse justify-content-end" },
        [
          _c("ul", { staticClass: "navbar-nav ml-auto" }, [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "/home" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("hamburgerMenu.home")) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle",
                  class: { "router-link-active": _vm.subIsActive("personal") },
                  attrs: {
                    id: "navbarDropdown",
                    href: "#",
                    role: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [_vm._v("\n            dWallet\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "navbarDropdown" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { to: "/dwallet" }
                    },
                    [_vm._v(_vm._s(_vm.$t("hamburgerMenu.dWallet")))]
                  ),
                  _vm._v(" "),
                  _vm.isSectionAllowed("projects")
                    ? _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item nav-link",
                          attrs: { to: "/projects" }
                        },
                        [_vm._v(_vm._s(_vm.$t("hamburgerMenu.projects")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle",
                  class: { "router-link-active": _vm.subIsActive("apps") },
                  attrs: {
                    id: "navbarDropdown",
                    href: "#",
                    role: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [_vm._v("\n            Apps\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "navbarDropdown" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { to: "/auditmap" }
                    },
                    [_vm._v(_vm._s(_vm.$t("hamburgerMenu.auditMap")))]
                  ),
                  _vm._v(" "),
                  _vm.isCreateAllowed("projects")
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item nav-link",
                          attrs: {
                            href: _vm.studioUrl,
                            target: "_blank",
                            rel: "noopener"
                          }
                        },
                        [_vm._v("Studio")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _vm.isSectionAllowed("datasets")
                ? _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle",
                      class: {
                        "router-link-active": _vm.subIsActive("organization")
                      },
                      attrs: {
                        id: "navbarDropdown",
                        href: "#",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      }
                    },
                    [_vm._v("\n            Organization\n          ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "navbarDropdown" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { to: "/auditdata" }
                    },
                    [_vm._v(_vm._s(_vm.$t("hamburgerMenu.auditCatalog")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { to: "/auditusers" }
                    },
                    [_vm._v(_vm._s(_vm.$t("hamburgerMenu.auditWallets")))]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  { staticClass: "nav-link", attrs: { to: "/exchange" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("hamburgerMenu.exchange")) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle",
                  attrs: {
                    id: "navbarDropdown",
                    href: "#",
                    role: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [_vm._v("\n            Admin\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "navbarDropdown" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { to: "/settings/organization" }
                    },
                    [_vm._v(_vm._s(_vm.$t("hamburgerMenu.myOrganization")))]
                  ),
                  _vm._v(" "),
                  _vm.isSectionAllowed("users")
                    ? _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item nav-link",
                          attrs: { to: "/users" }
                        },
                        [_vm._v(_vm._s(_vm.$t("hamburgerMenu.users")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSectionAllowed("organizations")
                    ? _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item nav-link",
                          attrs: { to: "/organizations" }
                        },
                        [_vm._v(_vm._s(_vm.$t("hamburgerMenu.organizations")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link dropdown-toggle dropdown-nocaret",
                  staticStyle: { padding: "0px 0px 0px 10px !important" },
                  attrs: {
                    id: "navbarDropdown",
                    href: "#",
                    role: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [
                  _c("img", {
                    staticClass: "userAvatar",
                    attrs: { src: _vm.getUserProfilePhoto() }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu userDropDown",
                  attrs: { "aria-labelledby": "navbarDropdown" }
                },
                [
                  _c("div", { staticClass: "dropdown-item" }, [
                    _c(
                      "div",
                      { staticStyle: { "font-size": "1.2em", color: "#fff" } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.getUserName()) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "font-size": "0.9em", opacity: "0.8" } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.getUserEmail()) +
                            "\n              "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { to: "/settings/profile" }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.profile.title")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { to: "/settings/security" }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.security.title")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item nav-link",
                      attrs: { href: "#" },
                      on: { click: _vm.logout }
                    },
                    [_vm._v(_vm._s(_vm.$t("signOut")))]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
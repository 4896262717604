<template>
    <v-app>
        <div id="createEditUser" class="content screen-area">
            <div class="container-fluid ">
                    <div class="row">
                        <div class="col-12">
                            <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                            <h4 id="createEditUserTitle" class="mb-3"></h4>
                            <form v-on:submit.prevent id="createEditForm" action="" method="post">
                                <div class="mb-3">
                                    <label class="control-label" for="fieldName">{{$t('userCreate.form.name')}}</label>
                                    <input v-model="nameValue" type="text" id="fieldName" name="name" class="form-control medium" required />
                                    <div class="invalid-feedback">
                                        {{$t('userCreate.validName')}}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="control-label" for="fieldUsername">{{$t('userCreate.form.userName')}}</label>
                                    <input :readonly="isEdit ? true : false" v-model="userNameValue" type="text" id="fieldUserName" name="userName" class="form-control medium" required />
                                </div>
                                <div class="mb-3">
                                    <label class="control-label" for="fieldEmail">{{$t('userCreate.form.email')}}</label>
                                    <input v-model="emailValue" type="email" id="fieldEmail" name="email" class="form-control medium" required />
                                </div>

                                <div class="mb-3">
                                    <label class="control-label" for="fieldRoles">{{$t('userCreate.form.role')}}</label><br/>
                                    <select v-if="roles.length" v-model="rolePublicId" name="roles" id="fieldRoles" class="form-control small">
                                        <option v-for="policy in roles" :value="policy.publicId">{{ policy.name }}</option>
                                    </select>
                                    <div v-if="!roles.length" class="noLicensesWarning">{{$t('userCreate.noLicenses')}}</div>
                                    <div v-if="rolesDict && rolePublicId" class="seatsWarning">(Only {{rolesDict[rolePublicId].available}} seats left for this type!)</div>
                                </div>

                                <div class="mb-3">
                                    <label class="control-label" for="fieldLocale">{{$t('userCreate.form.locale')}}</label>
                                    <select v-model="localeValue" name="locale" id="fieldLocale" class="form-control small">
                                        <option value="pt-br">Brazilian Portuguese</option>
                                        <option value="en">English</option>
                                    </select>
                                </div>
                                <hr class="mb-4">
                                <BaseButton style="float: right; color: green; border-color: green" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.confirm')" :onClick="validateForm" buttonType="submit"></BaseButton>
                                <BaseButton style="float: right" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.cancel')" :onClick="onClickQuit"></BaseButton>
                            </form>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>


<script>
import BaseButton from './BaseButton.vue'
import Utils from "src/mixins/utils"
import OrgService from 'src/services/organization'

export default {
    mixins: [Utils],
    name: 'CreateEditUser',
    components: {
        'BaseButton': BaseButton,
    },
    data() {
        let roles = [];

        return {
            nameValue: '',
            userNameValue: '',
            emailValue: '',
            rolePublicId: '',
            localeValue: '',
            publicId: '',
            isEdit: false,
            rolesDict: {},
            roles: []
        }
    },
    mounted () {
      this.getRoles();
    },
    methods: {
        async getRoles() {
            let scoper = this;
            await OrgService.listRoles()
                .then(result => {
                    scoper.roles = result;
                    scoper.rolesDict = {};
                    scoper.roles.forEach( function(item) {
                        scoper.rolesDict[item.publicId] = item;
                    });
                })
                .catch(err => {
                    console.error("API ERROR", err);
                })
        },
        resetData() {
            Object.assign(this.$data, this.$options.data());
            this.getRoles();
        },
        resetForm: function() {
            [...document.getElementsByClassName("form-control")].map(input => {
                input.classList.remove("invalidField");
            });
        },
        validateForm: function() {
            if(this.nameValue == '' || this.userNameValue == '' || this.emailValue == '' || this.rolePublicId == '') return;

            let formNode = document.getElementById('createEditForm')
            let formJSON = {}
            for (let i = 0; i < formNode.length; i++) {
                formJSON[formNode[i].name] = formNode[i].value
            }

            formJSON.password = '_' + Math.random().toString(36).substr(2, 9) // random string
            formJSON.organizationId = this.getOrganizationPublicId();
            formJSON.rolePublicId = this.rolePublicId;

            this.onFormValidated(formJSON, this.isEdit, this.publicId);
            return formJSON;
        },
        editItem(item) {
            this.isEdit = true;
            this.nameValue = item.name;
            this.userNameValue = item.userName;
            this.emailValue = item.email;
            this.rolePublicId = item.role ? item.role.publicId : "";    // To-do: change for rolePublicId when the API returns it
            this.localeValue = item.locale;
            this.publicId = item.publicId;
        }
    },
    props: {
        onClickQuit: {
            type: Function,
            required: true
        },
        onFormValidated: {
            type: Function,
            required: true
        }
    }
}
</script>


<style scoped lang="scss">
.modal-container {
    display: block;
    padding-left: 10px
}
.invisible {
    display: none;
}
#fieldRoles{
    display: inline-block;
}
.seatsWarning{
    color: #f60;
    margin-left: 10px;
    display: inline-block;
}
.noLicensesWarning{
    color: #ff3b1d;
    font-size: 0.9em;
    font-style: italic;
}
#createEditUser{
    padding: 0px 30px;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700", padding: "30px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _c("div", { staticClass: "col-12 typeIcon" }, [
              _c("img", {
                staticStyle: { "max-height": "70px" },
                attrs: { src: "src/assets/imgs/dialog_icon_money.png", alt: "" }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 text",
                staticStyle: { "margin-top": "20px" }
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            )
          ]),
          _vm._v(" "),
          _c(
            "v-card-title",
            { staticClass: "infoText", attrs: { primary: "", title: "" } },
            [
              _c("div", { staticClass: "col-12 text" }, [
                _c("p", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.information) + "\n        "
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-card-title",
            { staticClass: "slotForm", attrs: { "primary-title": "" } },
            [_c("div", [_vm._t("form")], 2)]
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "actions-buttons" },
            [
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeCallBack } },
                [_vm._v(_vm._s(_vm.closeLabel))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.confirmCallBack } },
                [_vm._v(_vm._s(_vm.confirmLabel))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
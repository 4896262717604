var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "side-bar",
        { staticStyle: { display: "none" } },
        [
          _c("mobile-menu", { attrs: { slot: "content" }, slot: "content" }),
          _vm._v(" "),
          _c("sidebar-link", { attrs: { to: "/home" } }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.home")))])
          ]),
          _vm._v(" "),
          _c("sidebar-link", { attrs: { to: "/dwallet" } }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.dWallet")))])
          ]),
          _vm._v(" "),
          _vm.isSectionAllowed("projects")
            ? _c("sidebar-link", { attrs: { to: "/projects" } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.projects")))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("sidebar-link", { attrs: { to: "/organization/library" } }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.orgLibrary")))])
          ]),
          _vm._v(" "),
          _c("sidebar-link", { attrs: { to: "/exchange" } }, [
            _c("p", [_vm._v("Exchange")])
          ]),
          _vm._v(" "),
          _vm.isSectionAllowed("datasets")
            ? _c("sidebar-link", { attrs: { to: "/auditdata" } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.auditCatalog")))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isSectionAllowed("datasets")
            ? _c("sidebar-link", { attrs: { to: "/auditmap" } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.auditMap")))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isSectionAllowed("datasets")
            ? _c("sidebar-link", { attrs: { to: "/auditusers" } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.auditWallets")))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isSectionAllowed("users")
            ? _c("sidebar-link", { attrs: { to: "/users" } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.users")))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isSectionAllowed("organizations")
            ? _c("sidebar-link", { attrs: { to: "/organizations" } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("hamburgerMenu.organizations")))])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _vm._v(" "),
          _c("dashboard-content", { on: { click: _vm.toggleSidebar } }),
          _vm._v(" "),
          _c("content-footer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>

            <div class="dimContainer">
              <div class="userName">
                {{getUserName()}}
              </div>
              <div class="dimLabel">
                DIM Score
              </div>
              <div class="dimValue">
                {{accountDIM}}
              </div>
            </div>

            <hr class="gradientCenter"/>

            <div class="analyticsContainer">
              <StatsPad
                ref="pad_datasources"
                :title="$t('home.cards.datasources')"
              />
              <StatsPad
                ref="pad_datasets"
                :title="$t('home.cards.datasets')"
              />
              <StatsPad
                v-if="isSectionAllowed('views')"
                ref="pad_views"
                :title="$t('home.cards.views')"
              />
              <StatsPad
                v-if="isSectionAllowed('projects')"
                ref="pad_projects"
                :title="$t('home.cards.projects')"
              />
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>


<script>
import AnalyticsService from 'src/services/analytics';
import StatsPad from 'src/components/StatsPad.vue';
import Utils from 'src/mixins/utils';
import BaseButton from 'src/components/BaseButton.vue';
import DatasetService from 'src/services/dataset';
import DataSourceService from 'src/services/datasource';
import ProjectsService from 'src/services/project';
import ViewService from 'src/services/view';

export default {
  mixins: [Utils],
  components: {
    StatsPad,
    BaseButton
  },
  data() {
    return {
      accountDIM: 0,
      datasourcesCount: "...",
      datasetsCount: "...",
      viewsCount: "...",
      projectsCount: "...",
      selectedTimeRange: 5,
      timeRanges: [
        { label: "Past 7 days", days: 7 },
        { label: "Past 14 days", days: 14 },
        { label: "Past month", days: 30 },
        { label: "Past 3 months", days: 91 },
        { label: "Past 6 months", days: 182 },
        { label: "Past year", days: 365 }
      ]
    };
  },
  methods: {
    async loadDatasets() {
      try {
        let datasets = await DatasetService.getDatasets();
        let accountDIM = 0;
        this.datasetsCount = datasets.length;
        datasets.forEach(element => {
          if (element.dimScore && element.dimScore.total) {
            accountDIM += element.dimScore.total;
          }
        });
        this.accountDIM = accountDIM.toFixed(2);

      } catch (err) {
        console.error('ERROR Loading datasets', err)
      }
    },

    async loadDatasources() {
      try {
        let datasources = await DataSourceService.listDatasources();
        this.datasourcesCount = datasources.length
      } catch(err) {
        console.error('ERROR Loading datasources', err)
      }
    },

    async loadViews(){
      try {
        let accountViews = await ViewService.getAccountViews();
        this.viewsCount = accountViews.length;
      } catch(err) {
        console.error('ERROR Loading datasources', err)
      }
    },

    async loadProjects(){
      try {
        let accountProjects = await ProjectsService.getCurrentUserProjects();
        this.projectsCount = accountProjects.length;
      } catch(err) {
        console.error('ERROR Loading projects', err)
      }
    },


    updatePads() {
      if (this.$refs.pad_views) {
        this.$refs.pad_views.updateCount(this.viewsCount);
      }

      if (this.$refs.pad_datasources) {
        this.$refs.pad_datasources.updateCount(this.datasourcesCount);
      }

      if (this.$refs.pad_datasets) {
        this.$refs.pad_datasets.updateCount(this.datasetsCount);
      }

      if (this.$refs.pad_projects) {
        this.$refs.pad_projects.updateCount(this.projectsCount);
      }
    },
    onSelectedOrganizationChanged(e) {
      this.updatePads();
    }
  },
  created() {
    window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  },
  beforeDestroy() {
    window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  },
  async mounted() {
    await this.loadDatasets();
    await this.loadViews();
    await this.loadDatasources();
    await this.loadProjects();
    this.updatePads();
  }
};
</script>


<style>

.dimContainer{
  position: relative;
  text-align: center;
}

.userName{
  display: none;
  font-size: 35px;
  margin-bottom: -10px;
  font-size: 35px;
  margin-bottom: 20px;
  line-height: 35px;
}

.dimLabel{
  color: #8C8C8C;
  font-size: 16px;
}

.dimValue{
    color: #EC008C;
    font-size: 68px;
    font-weight: 100;
    margin-top: -21px;
    margin-left: -5px;
}



@media (max-width: 769px){
  .userName{
    display: block;
  }

}

@media (max-width: 425px){

  .analyticsContainer{
    margin-top: 50px;
  }

  .dimLabel{
    font-size: 26px;
    font-weight: 300;;

  }
  .dimValue{
      font-size: 98px;
      margin-top: -21px;
      margin-left: -5px;
  }
}

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("card", [
                _c("div", { staticClass: "dimContainer" }, [
                  _c("div", { staticClass: "userName" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.getUserName()) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dimLabel" }, [
                    _vm._v("\n              DIM Score\n            ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dimValue" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.accountDIM) +
                        "\n            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("hr", { staticClass: "gradientCenter" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "analyticsContainer" },
                  [
                    _c("StatsPad", {
                      ref: "pad_datasources",
                      attrs: { title: _vm.$t("home.cards.datasources") }
                    }),
                    _vm._v(" "),
                    _c("StatsPad", {
                      ref: "pad_datasets",
                      attrs: { title: _vm.$t("home.cards.datasets") }
                    }),
                    _vm._v(" "),
                    _vm.isSectionAllowed("views")
                      ? _c("StatsPad", {
                          ref: "pad_views",
                          attrs: { title: _vm.$t("home.cards.views") }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSectionAllowed("projects")
                      ? _c("StatsPad", {
                          ref: "pad_projects",
                          attrs: { title: _vm.$t("home.cards.projects") }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
    <div class="content" id="datasourcesCreate">
      <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                  <template slot="header">
                      <div class="page-title">{{ this.isUpload ? $t('dsCreateEdit.uploadTitle') : this.isAPI ? $t('dsCreateEdit.apiTitle') : $t('dsCreateEdit.connectDbTitle') }}</div>
                  </template>
                  <template slot="raw-content">
                      <CreateEditModal ref="createEditModal" :onClickQuit="quitCreateEdit" :onFormValidated="onFormValidated" :isEdit="false"></CreateEditModal>
                  </template>
                </card>
            </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
  import Vue from 'vue'
  import Card from 'src/components/Cards/Card.vue'
  import DatasourceService from 'src/services/datasource'
  import CreateEditModal from 'src/components/CreateEditDatasources.vue'
  import Swal from 'sweetalert2'


  export default {
    components: {
      CreateEditModal,
      Card
    },
    data() {
      return {
        isUpload: this.$route.params.mode == 'upload',
        isAPI: this.$route.params.mode == 'api'
      };
    },
    methods: {
      async onFormValidated(formData, publicId = null) {
        try {
          Swal.fire({
            title: this.$t( this.isUpload ? 'dsCreateEdit.uploading' : 'dsCreateEdit.connecting' ),
            onOpen: () => {
              Swal.showLoading();
            }
          });
          if (['csv', 'excel'].includes(formData.type)) {
            let params = {
              name: formData.name,
              fileName: formData.file ? formData.file.name : '',
              content: formData.file? formData.file.content : '',
              url: formData.url
            }
            params.fileType = formData.type == 'csv' ? 'text/csv' : 'application/vnd.ms-excel';
            await DatasourceService.createDatasource(formData, params);

          } else {
            await DatasourceService.createDatasource(formData);
          }
          this.quitCreateEdit();
          Swal.fire({
            title: this.$t( this.isUpload ? 'dsCreateEdit.upload_success' : this.isAPI ? 'dsCreateEdit.connect_success_api' : 'dsCreateEdit.connect_success_db' ),
            type: 'success',
            showConfirmButton: false,
            timer: 1000,
            onBeforeOpen: () => {
              Swal.hideLoading();
            }
          });
        } catch (err) {
          let message = 'It was not possible to create the data source right now. Please try again later';

          if (err && Array.isArray(err) && err.length > 1) {
            message = err[1].errors[0];
          }

          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: message,
            type: 'error',
            confirmButtonText: 'Ok',
            onBeforeOpen: () => Swal.hideLoading()
          });
        }
      },
      quitCreateEdit() {
        this.$router.push({name: 'dWallet'})
      },
    }
  }
</script>
<style>
</style>

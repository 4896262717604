<template>
  <v-app>
       <div class="content" id="organizationCreate">
           <div class="container-fluid">
               <div class="row">
                   <div class="col-12">
                       <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                           <template slot="raw-content">
                              <CreateEditOrganization ref="createEditOrganization" :onClickQuit="backToOrganizations" :onFormValidated="onFormValidated" :comingFromMyOrgPage="true" ></CreateEditOrganization>
                           </template>
                       </card>
                   </div>
               </div>
           </div>
       </div>
    </v-app>
</template>

<script>
import Vue from 'vue'
import Card from 'src/components/Cards/Card.vue'
import CreateEditOrganization from 'src/components/CreateEditOrganization.vue'
import OrgService from 'src/services/organization'
import SignedService from 'src/services/signed'
import Utils from "src/mixins/utils"
import Swal from 'sweetalert2'
export default {
    mixins: [Utils],
    components: {
        Card,
        CreateEditOrganization
    },
    methods: {
      backToOrganizations() {
          this.$router.push({name: 'dWallet'});
      },
      onFormValidated: async function( formData, edit=true, publicId=null ) {
        try {
          Swal.fire({
            title: 'Submitting',
            onOpen: () => {
              Swal.showLoading();
            }
          });

          if( formData.imgChosen ){
            const uploaded = await this.uploadImage(formData);
            formData.loginLogoUrl = formData.homeLogoUrl = uploaded;
          }
          delete formData.cropImg;
          delete formData.imgChosen;
          await OrgService.editOrganization(publicId, formData);
          Swal.fire({
              title: 'Organization Updated!',
              type: 'success',
              showConfirmButton: false,
              timer: 1000,
              onBeforeOpen: () => {
                Swal.hideLoading();
              }
          });
          location.reload();
        } catch (e) {
          let error = e[1].errors[0];
          console.error(e)
          Swal.fire({
              title: 'Error!',
              text: error,
              type: 'error',
              confirmButtonText: 'Ok',
              onBeforeOpen: () => {
                  Swal.hideLoading();
              }
          });
        }
      },
      async uploadImage( formData ){
          try{
              let params = {
                  name: formData.subdomain,
                  fileName: 'login_logo.png',
                  content: formData.cropImg,
                  fileType: 'image/png',
                  url: '',
                  orgImage: true
              }

              params.url = await SignedService.upload(params);

              let blobData = this.dataURItoBlob(params.content);
              let uploaded = await this.uploadToS3(params.url, blobData, params.fileType);
              let fileUrl = uploaded.url.split("?")[0];
              return fileUrl;
          }catch(err){
              return false;
          }
      },
      dataURItoBlob(dataURI) {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
          for(var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i));
          }
          return new Blob([new Uint8Array(array)], {type: 'image/png'});
      }
    },
    mounted() {
      const org = {
        publicId: this.getOrganizationPublicId()
      };
      this.$refs.createEditOrganization.editItem(org)
      document.getElementById("createEditOrganizationTitle").innerHTML = this.$i18n.t('organizationCreate.editTitle')
    }
}
</script>

<style scoped>
</style>

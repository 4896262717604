var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("center", { staticStyle: { "padding-top": "80px" } }, [
    _c("div", { staticClass: "topBar" }, [
      _c("img", {
        staticStyle: { height: "40px" },
        attrs: { src: _vm.organizationLogo }
      })
    ]),
    _vm._v(" "),
    _vm.submitSuccess == false
      ? _c("div", [
          _c("div", { staticClass: "o-page c-login-page" }, [
            _c("div", { staticClass: "c-login-page__content" }, [
              _c("div"),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticStyle: {
                    "max-width": "320px",
                    "margin-bottom": "20px"
                  },
                  attrs: { src: require("../assets/imgs/form-header.png") }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "logo_container" }, [
                _c("img", { attrs: { src: _vm.organizationLogo } })
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "c-login-page-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      this.isFirstLogin
                        ? _vm.$t("authN.newPassword.firstTimeTitle")
                        : _vm.$t("authN.newPassword.title")
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("small", { staticStyle: { "font-size": "14px" } }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("authN.email")) + ":")]),
                _vm._v(" " + _vm._s(this.email) + "\n        ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "login_form",
                  on: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _vm.log != ""
                    ? _c("div", { staticClass: "error" }, [
                        _c(
                          "span",
                          { staticStyle: { "white-space": "pre-line" } },
                          [_vm._v(_vm._s(_vm.log))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "o-form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "password",
                        type: "password",
                        placeholder: _vm.$t("authN.password"),
                        value: "",
                        required: "",
                        autofocus: ""
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "o-form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.passwordConfirm,
                          expression: "passwordConfirm"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "password",
                        type: "password",
                        placeholder: _vm.$t("authN.passwordConfirm"),
                        value: "",
                        required: ""
                      },
                      domProps: { value: _vm.passwordConfirm },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.passwordConfirm = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.isFirstLogin == true
                    ? _c("div", { staticClass: "o-form-group terms-of-use" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.terms,
                              expression: "terms"
                            }
                          ],
                          staticClass: "c-login-page__checkbox",
                          attrs: {
                            name: "accepted_terms",
                            type: "checkbox",
                            value: "on",
                            required: ""
                          },
                          domProps: {
                            checked: Array.isArray(_vm.terms)
                              ? _vm._i(_vm.terms, "on") > -1
                              : _vm.terms
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.terms,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "on",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.terms = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.terms = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.terms = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("authN.newPassword.termsOfUse1")) +
                            "\n            "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            staticStyle: {
                              color: "rgb(153, 153, 153)",
                              "text-decoration": "underline",
                              display: "inline-block",
                              "padding-left": "2px"
                            },
                            attrs: {
                              id: "terms",
                              href: "/#/terms-of-use",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("authN.newPassword.termsOfUse2"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "o-form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "baseButton",
                        on: { click: _vm.submitForm }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("authN.button.confirm")) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("br")
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "c-login-page__footer__text" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.copyright")) +
                    "\n        "
                )
              ])
            ])
          ])
        ])
      : _c("div", [
          _c("div", { staticClass: "o-page c-login-page" }, [
            _c("div", { staticClass: "c-login-page__content" }, [
              _c("div", [
                _c("img", {
                  staticStyle: {
                    "max-width": "320px",
                    "margin-bottom": "20px"
                  },
                  attrs: { src: require("../assets/imgs/form-header.png") }
                })
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "c-login-page-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("authN.newPassword.success.title")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("authN.newPassword.success.p1")))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("authN.newPassword.success.p2")))]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "c-login-page__footer__text" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.copyright")) +
                    "\n        "
                )
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
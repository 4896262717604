<template>
  <v-app>
  <div class="content" id="users">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('users.title')}}</div>
              <p class="card-category">{{$t('dwallet.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput ref="searchInput" addonRightIcon="fa fa-search" placeholder="Search" :onClick="searchInputValue" :onKeyUp="searchInputValue"></BaseInput>
                <div class="fieldGroup" style="margin-top: 10px; text-align: right; color: #666;">
                  <div style="display: inline-block;">{{$t('users.show')}}: </div>
                  <div style="display: inline-block;">
                    <select @change="onFilterChanged" name="filter" id="fieldFilterUsers" class="form-control small" v-model="filter">
                      <option name="activeOption" value="active">{{$t('users.active')}}</option>
                      <option name="inactiveOption" value="inactive">{{$t('users.inactive')}}</option>
                    </select>
                    <BaseButton v-if="isCreateAllowed('users')" :buttonLabel="'+ '+$t('users.create')" style="padding: 12px 10px; margin: 10px 0px; float: right;" :onClick="createUser"></BaseButton>
                  </div>
                </div>
              </form>
            </div>
            <div style="clear: both;"/>
            <div v-if="searchWord && !searchResults.length" class="loadingMessage">{{$t('general.no_results')}}</div>
            <div v-if="!table || !table.data" class="loadingMessage">{{$t('general.loading')}}</div>
            <data-table v-if="table.data && (!searchWord || (searchWord && searchResults.length))" :items="table.data" :headers="table.columns" :editDelete="true" page="user" :editCallback="editCallback">
            </data-table>
          </card>
        </div>
      </div>
    </div>
  </div>
  <DelModal :onClickQuit="quitDelete" :onClickDelete="confirmDelete" :title="$t('users.delete')"></DelModal>
  <ReactivateModal :onClickQuit="quitReactivate" :onClickReactivate="confirmReactivate" :title="$t('users.reactive')"></ReactivateModal>
  <CreateEditUser ref="createEditUser" :onClickQuit="quitCreateEdit" :onFormValidated="onFormValidated"></CreateEditUser>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import DataTable from 'src/components/DataTable.vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseCheckbox from 'src/components/Inputs/BaseCheckbox.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import DelModal from 'src/components/DelModal.vue'
  import ReactivateModal from 'src/components/ReactivateModal.vue'
  import UserService from 'src/services/user'
  import CreateEditUser from 'src/components/CreateEditUser.vue'
  import Swal from 'sweetalert2'
  import Utils from 'src/mixins/utils'

  export default {
    mixins: [Utils],
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable,
      DelModal,
      ReactivateModal,
      CreateEditUser
    },
    data() {
      let columns = [
            { text: this.$t('users.table.name'), value: 'name', align: 'left' },
            { text: this.$t('users.table.email'), value: 'email' },
            { text: this.$t('users.table.createdAt'), value: 'createdAt' },
            { text: this.$t('users.table.lastActive'), value: 'lastActivity' },
            { text: this.$t('users.table.role'), value: 'roleName' }
          ];

      return {
        table: {
          columns: columns,
          data: null
        },
        users: {},
        subTitle: '',
        searchWord: '',
        searchResults: [],
        filter: 'active'
      }
    },
    methods: {
      searchInputValue(e) {
        let results = [];
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.users.map((row, index) => {
          let matchName = row.name === undefined ? false : row.name.toUpperCase().includes( this.searchWord );
          let matchEmail = row.email === undefined ?  false : row.email.toUpperCase().includes( this.searchWord );
          if(matchName || matchEmail){
            results.push(this.users[index]);
          }
        });
        this.searchResults = results;
        this.table.data = this.applyFilter(this.searchResults);
      },
      createUser(){
        this.$router.push({name: 'UserCreate'});
      },
      async loadUsers() {
          try {
              document.getElementById('createEditUser').classList.add("invisible");
              let result = await UserService.listUsers(true);
              this.users = result;
              this.table.data = this.applyFilter(this.users);
              this.table.data.map((row, index) => {
                  this.table.data[index].roleName = row.role ? row.role.name : null;
              });
          } catch (error) {
            console.error(error)
              Swal.fire({
                  title: 'Error!',
                  text: 'Users not found',
                  type: 'error',
                  confirmButtonText: 'Ok'
              });
          }
      },
      applyFilter(users) {
        if (this.filter != 'active') {
          return users.filter(x => {
             if (x.deletedAt != null) {
               delete x.deleteAllowed;
               return x;
             }
          });
        } else {
          return users.filter(x => x.deletedAt == null);
        }
      },
      quitDelete() {
        document.getElementById("deleteModal").classList.add("invisible");
      },
      quitReactivate() {
        document.getElementById("reactivateModal").classList.add("invisible");
      },
      async confirmReactivate() {
        try {
          Swal.fire({
            title: 'Reactivating',
            onOpen: () => Swal.showLoading()
          });
          let results = [];
          this.users.map(async (row, index) => {
            if(row.publicId != document.getElementById("elementToBeReactivated").dataset.publicId) {
              results.push(this.users[index]);
            }
            else {
              let res = await UserService.reactivateUser(row.publicId);
              this.quitDelete();
              Swal.fire({
                title: 'User Reactivated!',
                type: 'success',
                showConfirmButton: false,
                timer: 1000,
                onBeforeOpen: () => Swal.hideLoading()
              });
              await this.loadUsers();
            }
          });
          this.users = results;
          this.searchInputValue();
          document.getElementById("reactivateModal").classList.add("invisible");
        } catch (err) {
          const error = err[1].erros[0];
          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: error,
            type: 'error',
            confirmButtonText: 'Ok',
            onBeforeOpen: () => Swal.hideLoading()
          });
        }
      },
      async confirmDelete() {
        try {
          Swal.fire({
            title: 'Deleting',
            onOpen: () => Swal.showLoading()
          });
          let results = [];
          this.users.map(async (row, index) => {
            if(row.publicId != document.getElementById("elementToBeDeleted").dataset.publicId) {
              results.push(this.users[index]);
            }
            else {
              let res = await UserService.deleteUser(row.publicId);
              this.quitDelete();
              Swal.fire({
                title: 'User Deleted!',
                type: 'success',
                showConfirmButton: false,
                timer: 1000,
                onBeforeOpen: () => Swal.hideLoading()
              });
              await this.loadUsers();
            }
          });
          this.users = results;
          this.searchInputValue();
          document.getElementById("deleteModal").classList.add("invisible");
        } catch (err) {
          let error = err[1].erros[0];
          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: error,
            type: 'error',
            confirmButtonText: 'Ok',
            onBeforeOpen: () => Swal.hideLoading()
          });
        }
      },
      quitCreateEdit() {
        this.$refs.createEditUser.resetForm();
        document.getElementById("createEditUser").classList.add("invisible");
        document.getElementById("createEditForm").reset();
        document.getElementById('users').style.display = "block";
        this.$refs.createEditUser.resetData()
      },
      onFormValidated: async function(formData, edit = true, publicId = null){
        try {
          Swal.fire({
            title: 'Submitting',
            onOpen: () => Swal.showLoading()
          });
          let user = await UserService.editUser(publicId, formData);
          this.quitCreateEdit();
          Swal.fire({
              title: 'User Updated!',
              type: 'success',
              showConfirmButton: false,
              timer: 1000,
              onBeforeOpen: () => Swal.hideLoading()
          });
          await this.loadUsers();
        } catch (err) {
          let error = err[1].errors[0];
          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: error,
            type: 'error',
            confirmButtonText: 'Ok',
            onBeforeOpen: () => Swal.hideLoading()
          });
        }
      },

      editCallback(item) {
        this.$refs.createEditUser.editItem(item)
        document.getElementById("createEditUserTitle").innerHTML = this.$t('userCreate.editTitle')
        document.getElementById('createEditUser').classList.remove("invisible");
        document.getElementById('users').style.display = "none";
      },
      onSelectedOrganizationChanged: function(e) {
        this.loadUsers();
      },
      onFilterChanged: function() {
        this.searchInputValue()
      }
    },
    async mounted () {
      await this.loadUsers();
    },
    created(){
      var scoper = this;
      window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
    },
    beforeDestroy(){
      window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
    }
  }

</script>

<style>
.searchContainer{
    float: right;
    margin-top: -50px;
    max-width: 300px;
    margin-bottom: 10px;
  }
</style>

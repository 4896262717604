const api = require('./api-caller')

module.exports = {
  getOrganizationLibraryDatasets: (body) => {
    const endpoint = '/library/datasets'
    return api(endpoint, 'GET', body)
  },
  getOrganizationLibraryViews: (body) => {
    const endpoint = '/library/data-apps'
    return api(endpoint, 'GET', body)
  },
  postItem: (body) => {
    const endpoint = '/posts'
    return api(endpoint, 'PUT', body)
  },
  unpostItem: (postPublicId) => {
    const endpoint = `/posts/${postPublicId}`
    return api(endpoint, 'DELETE')
  },
  borrowPost: (postPublicId) => {
    const endpoint = `/posts/${postPublicId}/borrow`
    return api(endpoint, 'PUT')
  },
  unborrowPost: (postPublicId) => {
    const endpoint = `/posts/${postPublicId}/borrow`
    return api(endpoint, 'DELETE')
  }
}

<template>
  <v-app>
  <div class="content" id="exchange">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('exchange.title')}}</div>
              <p class="card-category">{{$t('exchange.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
              </form>
            </div>
            <div style="clear: both;"/>
            <div style="margin-top: 20px; margin-bottom: 20px;">
              <v-tabs v-model="selectedTab" slider-color="#EC008C">
                <v-tab>
                  <div class="tabName">Datasets</div>
                  <div v-if="datasets_tableDataProvider"  class="tabCounter">{{datasets_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab>
                  <div class="tabName">Data Apps</div>
                  <div  v-if="views_tableDataProvider" class="tabCounter">{{views_tableDataProvider.length}}</div>
                </v-tab>
              </v-tabs>
            </div>

            <div v-if="selectedTab==0">
              <div v-if="searchWord && !datasets_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && datasets_tableDataProvider.length)"
                :headers="columns_in_datasets"
                :items="datasets_tableDataProvider"
                page="exchange"
                :linkBuilder="linktoDetails"
                :deleteCallback="deleteProduct"
                :buyDataProductCallback="buyDataProduct"
                linkTarget="_self"
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>

            <div v-if="selectedTab==1">
              <div v-if="searchWord && !views_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && views_tableDataProvider.length)"
                :headers="columns_in_views"
                :items="views_tableDataProvider"
                page="exchange"
                :linkBuilder="linktoDetails"
                :deleteCallback="deleteProduct"
                linkTarget="_self"
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import DatasetService from 'src/services/dataset'
  import DataSourceService from 'src/services/datasource'
  import ExchangeService from 'src/services/exchange'
  import DataProductService from 'src/services/dataproduct'
  import ContractService from 'src/services/contract'
  import DataTable from 'src/components/DataTable.vue'
  import Swal from 'sweetalert2'
  import Utils from 'src/mixins/utils'
  import WalletUtils from 'src/mixins/walletUtils'

  export default {
    mixins: [Utils, WalletUtils],
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable
    },
    data () {
      return {
        columns_in_datasets: [
          { text: this.$t('exchange.table.dimScore'), value: 'dimScoreTotal', sortable: true, tooltip: true, tooltipText: this.$t('datasets.dimscore_info') },
          { text: this.$t('exchange.table.name'), align: 'left', value: 'name'},
          { text: this.$t('dwallet.table.origin'), value: 'orgName' },
          { text: this.$t('dwallet.table.price'), value: 'price'},
          { text: 'Date Added', value: 'updatedAt' },
          { text: this.$t('dwallet.table.size'), value: 'cells' },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        columns_in_views: [
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.origin'), value: 'orgName' },
          { text: this.$t('dwallet.table.price'), value: 'price'},
          { text: 'Date Added', value: 'updatedAt' },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        selectedTab: 0,
        datasets: [],
        views: [],
        datasources: [],
        datasets_tableDataProvider: null,
        views_tableDataProvider: null,
        datasources_tableDataProvider: null,
        dataLoaded: false,
        editDelete: true,
        searchWord: ''
      }
    },
    methods: {
      async searchInputValue(e) {
        if( !this.dataLoaded ) {
          await this.loadDataProducts();
        }
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.datasets_tableDataProvider = this.datasets.filter(row => row.name.toUpperCase().includes(this.searchWord));
        this.views_tableDataProvider = this.views.filter(row => row.name.toUpperCase().includes(this.searchWord));
        this.datasources_tableDataProvider = this.datasources.filter(row => row.name.toUpperCase().includes(this.searchWord));
      },

      async loadDataProducts() {
        this.products = await ExchangeService.getDataProducts();
        this.products = this.products.map(x => {
          x.type = 'dataproduct';
          x.orgName = x.organization.name;
          x.price = x.status == 'pending' ? '-' : this.$t('general.free');
          x.tag = x.tags.join(', ');
          x.dataApps = [];
          if (x.dataApp == null) {
            x.type = 'dataproduct';
            x.dimScore = x.dataset?.dimScore;
            x.dimScoreTotal = x.dimScore?.total !== null ? x.dimScore?.total?.toFixed(2) : null;
            x.standardDataApps = x.dataset?.standardDataApps;
            x.cells = (x.dataset?.numberOfRows != null) ? (x.dataset?.numberOfRows * x.dataset?.numberOfFields) : '-';
            delete x.dataset?.standardDataApps;
          }
          return x;
        });

        this.datasets = this.products.filter(x => x.dataApp == null);
        this.views = this.products.filter(x => x.dataset == null);
        this.datasets_tableDataProvider = this.datasets;
        this.views_tableDataProvider = this.views;
      },

      async deleteProduct(item) {
        try {
          const result = await Swal.fire({
              title: this.$t('dataProduct.warning'),
              html: this.$t('dataProduct.delete.msg', [item.name]),
              type: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('unborrowFromLibrary.confirm.confirmButton'),
              cancelButtonText: this.$t('unborrowFromLibrary.confirm.cancelButton')
          });
          if (result.value) {
            Swal.fire({
              title: this.$t('dataProduct.delete.title'),
              onOpen: () => {
                Swal.showLoading();
              }
            });

            await DataProductService.removeDataProduct(item.publicId);

            await this.loadDataProducts();

            Swal.fire({
              title: this.$t('dataProduct.success'),
              html: this.$t('dataProduct.delete.success_msg', [item.name]),
              type: 'success',
              showConfirmButton: true,
              onBeforeOpen: () => {
                Swal.hideLoading();
              }
            });
          }
        } catch (err) {
          Swal.fire({
            title: this.$t('dataProduct.error'),
            text: `<pre>${this.getErrorMessage(err)}</pre>`,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async buyDataProduct(item) {
        try {
          const result  = await Swal.fire({
              title: this.$t('dataProduct.warning'),
              html: this.$t('dataProduct.buy.msg', [item.name]),
              type: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('unborrowFromLibrary.confirm.confirmButton'),
              cancelButtonText: this.$t('unborrowFromLibrary.confirm.cancelButton')
          });
          if (result.value) {
            const contract = await DataProductService.buyDataProduct(item.publicId);
            if (contract.status == 'pending') {
              Swal.fire({
                title: this.$t('dataProduct.almost'),
                html: this.$t('dataProduct.buy.pending', [item.name]),
                type: 'success',
                showConfirmButton: true,
                onBeforeOpen: () => {
                  Swal.hideLoading();
                }
            });
            } else {
              const confirmed = await Swal.fire({
                title: this.$t('dataProduct.success'),
                html: this.$t('dataProduct.buy.success', [item.name]),
                type: 'success',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('dataProduct.buy.go_wallet'),
                cancelButtonText: this.$t('dataProduct.buy.back_exchange'),
                onBeforeOpen: () => {
                  Swal.hideLoading();
                }
              });
              if (confirmed.value) {
                this.$router.push({name: 'dWallet'});
              }
            }
          }
        } catch (err) {
          Swal.fire({
            title: this.$t('dataProduct.error'),
            html: `<pre>${this.getErrorMessage(err)}</pre>`,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      quitDelete() {
        document.getElementById("deleteModal").classList.add("invisible");
      },

      onSelectedOrganizationChanged: function(e){
        this.loadAllTables();
      }
    },
    async mounted () {
      await this.loadDataProducts();
    }
  }
</script>


<style>
  .container-search-bar-and-create-button{
    margin: 0px;
  }

  .searchContainer{
    float: right;
    margin-top: -50px;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .v-tabs__div{
    min-width: 160px;
  }
</style>

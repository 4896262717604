const api = require('./api-caller')
const SignedService = require('./signed')
const { getOrganizationPublicId, uploadToS3 } = require('../mixins/utils').methods

const baseEndpoint = `/data-sources`

export default {
  listDatasources: unsuccessful => {
    let endpoint = '/wallet/data-sources'

    if (unsuccessful) {
      endpoint += '?status=success&statusOperator=!='
    }

    return api(endpoint, 'GET')
  },
  listDatasets: id => {
    const endpoint = `/wallet/data-sources/${id}/datasets`
    return api(endpoint, 'GET')
  },
  getDatasourceByPublicId: publicId => {
    const endpoint = `/wallet/data-sources/${publicId}`
    return api(endpoint, 'GET')
  },
  deleteDatasource: publicId => {
    const endpoint = `${baseEndpoint}/${publicId}`
    return api(endpoint, 'DELETE', { organizationPublicId: getOrganizationPublicId() })
  },
  createDatasource: async (formData, params = null) => {
    if (formData.file) delete formData.file.content

    if (params) {
      params.url = await SignedService.upload(params)
      await uploadToS3(params.url, params.content, params.fileType)
    }

    return api(baseEndpoint, 'POST', Object.assign({}, { organizationPublicId: getOrganizationPublicId() }, formData))
  },
  updateDatasource: async (publicId, formData, params = null) => {
    const endpoint = `${baseEndpoint}/${publicId}`

    if (formData.file) delete formData.file.content

    const result = await api(endpoint, 'PUT', Object.assign({}, { organizationPublicId: getOrganizationPublicId() }, formData))

    if (!params) {
      return result
    }

    params.url = await SignedService.upload(params)

    return uploadToS3(params.url, params.content, params.fileType)
  },
  checkIfCanBeDeleted: async (publicId, isCsv = undefined) => {
    let endpoint = `${baseEndpoint}/${publicId}/canBeDeleted`
    if (isCsv) {
      endpoint += `&csv=${isCsv}`
    }
    return api(endpoint, 'GET')
  }
}

import { render, staticRenderFns } from "./PasswordRecovery.vue?vue&type=template&id=652377f4&scoped=true&"
import script from "./PasswordRecovery.vue?vue&type=script&lang=js&"
export * from "./PasswordRecovery.vue?vue&type=script&lang=js&"
import style0 from "./PasswordRecovery.vue?vue&type=style&index=0&id=652377f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652377f4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/__w/ddv-home/ddv-home/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('652377f4')) {
      api.createRecord('652377f4', component.options)
    } else {
      api.reload('652377f4', component.options)
    }
    module.hot.accept("./PasswordRecovery.vue?vue&type=template&id=652377f4&scoped=true&", function () {
      api.rerender('652377f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PasswordRecovery.vue"
export default component.exports
<template>
  <div class="wrapper">
    <side-bar style="display: none;">
      <mobile-menu slot="content"></mobile-menu>

      <sidebar-link to="/home">
        <p>{{$t('hamburgerMenu.home')}}</p>
      </sidebar-link>

      <sidebar-link to="/dwallet">
        <p>{{$t('hamburgerMenu.dWallet')}}</p>
      </sidebar-link>

      <sidebar-link to="/projects" v-if="isSectionAllowed('projects')">
        <p>{{$t('hamburgerMenu.projects')}}</p>
      </sidebar-link>

      <sidebar-link to="/organization/library">
        <p>{{$t('hamburgerMenu.orgLibrary')}}</p>
      </sidebar-link>

      <sidebar-link to="/exchange">
        <p>Exchange</p>
      </sidebar-link>
      
      <sidebar-link to="/auditdata" v-if="isSectionAllowed('datasets')">
        <p>{{$t('hamburgerMenu.auditCatalog')}}</p>
      </sidebar-link>

      <sidebar-link to="/auditmap" v-if="isSectionAllowed('datasets')">
        <p>{{$t('hamburgerMenu.auditMap')}}</p>
      </sidebar-link>

      <sidebar-link to="/auditusers" v-if="isSectionAllowed('datasets')">
        <p>{{$t('hamburgerMenu.auditWallets')}}</p>
      </sidebar-link>

      <sidebar-link to="/users" v-if="isSectionAllowed('users')">
        <p>{{$t('hamburgerMenu.users')}}</p>
      </sidebar-link>

      <sidebar-link to="/organizations" v-if="isSectionAllowed('organizations')">
        <p>{{$t('hamburgerMenu.organizations')}}</p>
      </sidebar-link>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>


<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu.vue";
import Utils from "src/mixins/utils";

export default {
  mixins: [Utils],
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
    return {};
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>


<style scoped>
  .disabled{
    pointer-events: none;
  }

  .indented{
    margin-left: 15px !important;
  }
</style>

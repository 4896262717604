var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "card",
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("overview.title")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "filtersContainer" }, [
                      _c(
                        "div",
                        { staticClass: "timeSelectorContainer filterElement" },
                        [
                          _c("div", { staticClass: "dropdown" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary dropdown-toggle",
                                attrs: {
                                  type: "button",
                                  id: "dropdownMenuButton",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.timeRanges[_vm.selectedTimeRange].label
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-menu",
                                attrs: {
                                  "aria-labelledby": "dropdownMenuButton"
                                }
                              },
                              _vm._l(_vm.timeRanges, function(item, index) {
                                return _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeTimeRange(index)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              }),
                              0
                            )
                          ])
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "analyticsContainer" },
                    [
                      _vm.isSectionAllowed("datasets")
                        ? _c("AnalyticsPad", {
                            ref: "pad_datasets",
                            attrs: {
                              title: _vm.$t("home.cards.datasets"),
                              apiMethod: "listDatasets"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSectionAllowed("views")
                        ? _c("AnalyticsPad", {
                            ref: "pad_views",
                            attrs: {
                              title: _vm.$t("home.cards.views"),
                              apiMethod: "listViews"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSectionAllowed("projects")
                        ? _c("AnalyticsPad", {
                            ref: "pad_projects",
                            attrs: {
                              title: _vm.$t("home.cards.projects"),
                              apiMethod: "listProjects"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSectionAllowed("users")
                        ? _c("AnalyticsPad", {
                            ref: "pad_users",
                            attrs: {
                              title: _vm.$t("home.cards.users"),
                              apiMethod: "listUsers"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSectionAllowed("organizations")
                        ? _c("AnalyticsPad", {
                            ref: "pad_organizations",
                            attrs: {
                              title: _vm.$t("home.cards.organizations"),
                              apiMethod: "listOrganizations"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
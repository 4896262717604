<template>
    <div id="createEditModal" class="screen-area ">
        <div class="modal-container ">
            <div class="col-md-12 order-md-1">
                <h4 id="createEditModalTitle" class="mb-3"></h4>
                <form v-on:submit.prevent id="createEditForm" action="" method="post">

                    <!--  ------------ UPLOAD MODE ------------------------- -->

                    <div v-if="mode=='upload'">
                        <!-- --- FILE UPLOAD DROP AREA --- -->
                        <div class="mb-3 dropbox" :style="{ opacity: fileUrl=='' ? 1: 0.3}">
                            <input type="file" id="fieldFile" name="file" ref="file" accept=".csv, .xls, .xlsx" @change="fileChanged" />
                            <p v-if="this.fileName == '' " class="control-label" for="fieldFile">{{$t('dsCreateEdit.form.csv.title')}} <br/> {{$t('dsCreateEdit.form.csv.subTitle')}}</p>
                            <p v-if="this.fileName != '' " class="control-label" for="fieldFile">{{ this.fileName }}</p>
                        </div>
                        <BaseButton v-if="fileName" style="float: right; color: #999; border-color: #999; padding-top: 7px; padding-bottom: 7px; margin-top: -10px;" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.clear')" :onClick="resetUpload" buttonType="submit"></BaseButton>

                        <!-- --- UPLOAD URL ---  -->
                        <div class="mb-3">
                            <label class="control-label" for="fileUrl">{{$t('dsCreateEdit.form.fileUrl')}}</label>
                            <input v-model="fileUrl" type="text" id="fileUrl" name="name" class="form-control medium" :placeholder="$t('dsCreateEdit.form.fileUrl_placeholder')" @keyup="fileUrlChanged" :style="{ 'border-color': fileUrl!=='' ? '#999': '#E3E3E3'}"/>
                        </div>


                        <span v-if="type === 'csv' && type !== ''">
                            <div class="mb-3">
                                <label class="control-label" for="fieldSeparator">{{$t('dsCreateEdit.form.separator')}}</label>
                                <select v-model="separator" name="rowNames" id="fieldSeparator" class="form-control small" @change="updateExampleCsvTemplate">
                                    <option value=",">{{$t('dsCreateEdit.form.delimiters.comma')}}</option>
                                    <option value=";">{{$t('dsCreateEdit.form.delimiters.semicolon')}}</option>
                                    <option value="	">{{$t('dsCreateEdit.form.delimiters.tabular')}}</option>
                                    <option value="|">{{$t('dsCreateEdit.form.delimiters.pipe')}}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldHeaders">{{$t('dsCreateEdit.form.headers.title')}}</label>
                                <select v-model="rowNames" name="rowNames" id="fieldHeaders" class="form-control small" @change="updateExampleCsvTemplate">
                                    <option value="auto">{{$t('dsCreateEdit.form.headers.auto')}}</option>
                                    <option value="yes">{{$t('dsCreateEdit.form.headers.yes')}}</option>
                                    <option value="no">{{$t('dsCreateEdit.form.headers.no')}}</option>
                                </select>
                            </div>
                            <div class="mb-3" v-if="!isEdit">
                                <label class="control-label" for="fieldFile">{{$t('dsCreateEdit.form.example_csv')}}</label>
                                <div class="csv_example" v-html="exampleCsv"></div>
                            </div>
                        </span>



                        <span v-if="['excel'].includes(type) && type !== ''">
                            <div class="mb-3" v-if="!isEdit">
                                <label class="control-label" for="fileUrl">{{$t('dsCreateEdit.form.fileUrl')}}</label>
                                <input v-model="fileUrl" type="text" id="fileUrl" name="name" class="form-control medium" :placeholder="$t('dsCreateEdit.form.fileUrl_placeholder')" @keydown="fileUrlChanged"/>
                            </div>
                        </span>
                    </div>

                    <!--  ------------ DATABASE MODE ------------------------- -->

                    <div v-if="mode=='database'">
                        <h4>{{$t('dsCreateEdit.form.tech_info')}}</h4>
                        <div class="mb-3">
                            <label class="control-label" for="fieldType">{{$t('dsCreateEdit.form.type')}}</label>

                            <select v-model="type" name="type" id="fieldType" class="form-control small">
                                <option name="" value="">{{$t('datasources.choose_ds_type')}}</option>
                                <option name="" value="redshift">AWS RedShift</option>
                                <option name="" value="mariadb">MariaDB</option>
                                <option name="" value="mysql">MySQL</option>
                                <option name="" value="oracle">Oracle</option>
                                <option name="" value="postgres">PostgreSQL</option>
                                <option name="" value="sqlserver">SQL Server</option>
                                <option name="" value="db2">IBM DB2</option>
                                <option name="" value="saphana">SAP HANA</option>
                                <option name="" value="bigquery">Google BigQuery</option>
                            </select>
                        </div>
                        <span v-if="!['', 'csv', 'excel', 'bigquery'].includes(type)">
                            <div class="mb-3">
                                <label class="control-label" for="fieldHost">{{$t('dsCreateEdit.form.host')}}</label>
                                <input v-model="host" type="text" id="fieldHost" name="host" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldCloduProvider">{{$t('dsCreateEdit.form.cloudProvider')}}</label>
                                <select v-model="cloudProvider" name="cloduProvider" id="cloduProvider" class="form-control small">
                                    <option v-for="cloud in $t('dsCreateEdit.form.cloudProvider_options')" :value="cloud.value" :selected="cloud.value == cloudProvider">
                                        {{cloud.text}}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="contryServer">{{$t('dsCreateEdit.form.country')}}</label>
                                <v-autocomplete
                                    :items="countriesOption"
                                    class="form-control small"
                                    solo
                                    v-model="country"
                                    label="Choose Country"
                                >
                                </v-autocomplete>
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldPort">{{$t('dsCreateEdit.form.port')}}</label>
                                <input v-model="port" type="number" id="fieldPort" name="port" class="form-control micro" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldUser">{{$t('dsCreateEdit.form.user')}}</label>
                                <input v-model="user" type="text" id="fieldUser" name="user" class="form-control small" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldPassword">{{$t('dsCreateEdit.form.password')}}</label>
                                <input v-model="password" type="password" id="fieldPassword" name="password" class="form-control small" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldDatabase">{{$t( type== 'oracle' ? 'dsCreateEdit.form.serviceName' : 'dsCreateEdit.form.database')}}</label>
                                <input v-model="database" type="text" id="fieldDatabase" name="database" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldSchema">{{$t('dsCreateEdit.form.schema')}}</label>
                                <input v-model="schema" type="text" id="fieldSchema" name="schema" class="form-control medium" />
                            </div>
                        </span>
                        <span v-if="['bigquery'].includes(type)">
                            <div class="mb-3">
                                <label class="control-label" for="fieldProjectId">{{$t('dsCreateEdit.form.projectId')}}</label>
                                <input v-model="projectId" type="text" id="fieldProjectId" name="projectId" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldEmail">{{$t('dsCreateEdit.form.email')}}</label>
                                <input v-model="email" type="text" id="fieldEmail" name="email" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldPrivateKey">{{$t('dsCreateEdit.form.privateKey')}}</label>
                                <textarea @paste="parseEscapeNewline" v-model="privateKey" type="text" id="fieldPrivateKey" name="privateKey" class="form-control medium" style="min-height: 200px; min-width: 700px;"></textarea>
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldBigQueryProject">{{$t('dsCreateEdit.form.bigQueryProject')}}</label>
                                <input v-model="bigQueryProject" type="text" id="fieldBigQueryProject" name="bigQueryProject" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldBigQueryDataset">{{$t('dsCreateEdit.form.bigQueryDataset')}}</label>
                                <input v-model="bigQueryDataset" type="text" id="fieldBigQueryDataset" name="bigQueryDataset" class="form-control medium" />
                            </div>
                        </span>
                    </div>


                    <!--  ------------ API MODE ------------------------- -->

                    <div v-if="mode=='api'">
                        <h4>{{$t('dsCreateEdit.form.tech_info')}}</h4>
                        <div class="mb-3">
                            <label class="control-label" for="fieldType">{{$t('dsAPI.plataform')}}</label>

                            <select v-model="type" name="type" id="fieldType" class="form-control small">
                                <option name="" value="">{{$t('dsAPI.choose_plataform')}}</option>
                                <option name="" value="shopify">Shopify</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label class="control-label" for="fieldHost">{{$t('dsCreateEdit.form.host')}}</label>
                            <input v-model="host" type="text" id="fieldHost" name="host" class="form-control medium" />
                        </div>

                        <div class="mb-3">
                            <label class="control-label" for="fieldUser">{{$t('dsAPI.apiKey')}}</label>
                            <input v-model="user" type="text" id="fieldUser" name="user" class="form-control small" />
                        </div>

                        <div class="mb-3">
                            <label class="control-label" for="fieldPassword">{{$t('dsCreateEdit.form.password')}}</label>
                            <input v-model="password" type="password" id="fieldPassword" name="password" class="form-control small" />
                        </div>
                    </div>


                    <!--  ------------ COMMON INFO (Available for both modes) ------------------------- -->

                    <h4>{{$t('dsCreateEdit.form.general_info')}}</h4>
                    <div class="mb-3">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.form.name')}}</label>
                        <input :readonly="isEdit ? true : false" v-model="nameValue" type="text" id="fieldName" name="name" class="form-control medium" />
                    </div>
                    <div class="mb-3">
                        <label class="control-label" for="fieldDescription">{{$t('dsCreateEdit.form.description')}}</label>
                        <textarea v-model="description" type="text" id="fieldDescription" name="description" class="form-control medium" style="min-height: 100px;"></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.form.provenance')}}</label>
                        <input :readonly="isEdit ? true : false" v-model="provenance" type="text" id="fieldName" name="name" class="form-control medium" :placeholder="$t('dsCreateEdit.form.provenance_placeholder')"/>
                    </div>
                    <div class="mb-3">
                        <label class="control-label">{{$t('dsCreateEdit.form.rightHolder')}}</label>
                        <select :disabled="isEdit ? true : false" v-model="rightHolder" name="rightHolder" id="fieldrightHolder" class="form-control small">
                            <option v-for="holder in rightsHolderOptions" :value="holder.value">
                                {{holder.text}}
                            </option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label class="control-label" for="fieldDomain">{{$t('dsCreateEdit.form.domain')}}</label>
                        <input v-model="domain" type="text" id="fieldDomain" name="domain" class="form-control medium" />
                    </div>

                    <div v-if=" rightHolder == 'other_company' " class="mb-3">
                        <label class="control-label" style="display: block">{{$t('dsCreateEdit.form.cost')}}</label>
                        <select :disabled="isEdit ? true : false" v-model="currency" name="currency" id="fieldCurrency" class="form-control small" style="display: inline; width: 150px !important;">
                            <option v-for="currency in $t('dsCreateEdit.form.currencyOptions')" :value="currency.value" >
                                {{ currency.text }}
                            </option>
                        </select>

                        <input v-model="cost" type="text" id="fieldCost" name='cost' class="form-control medium" style="display: inline; width: 347px !important;" :placeholder="$t('dsCreateEdit.form.const_placeholder')" @keyup="fieldCostChanged" />
                    </div>

                    <br/><h4>{{$t('dsCreateEdit.form.pii_info')}}</h4>
                    <div class="mb-3">
                        <label class="control-label">{{$t('dsCreateEdit.form.includesPII')}}</label>
                        <select :disabled="isEdit ? true : false" v-model="includesPII" name="includesPII" id="fieldincludesPII" class="form-control small">
                            <option v-for="option in $t('dsCreateEdit.form.includesPIIOptions')" :value="option.value">
                                {{option.text}}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3" v-if="includesPII=='true'">
                        <label class="control-label">{{$t('dsCreateEdit.form.piiDataFormat')}}</label>
                        <select :disabled="isEdit ? true : false" v-model="piiDataFormat" name="piiDataFormat" id="fieldpiiDataFormat" class="form-control small">
                            <option v-for="format in $t('dsCreateEdit.form.piiDataFormatOptions')" :value="format.value">
                                {{format.text}}
                            </option>
                        </select>
                    </div>

                    <hr class="mb-4">
                    <BaseButton style="float: right; color: green; border-color: green" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t( mode == 'upload' ? 'dsCreateEdit.upload' : 'dsCreateEdit.connect' )" :onClick="validateForm" buttonType="submit"></BaseButton>
                    <BaseButton style="float: right" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.cancel')" :onClick="onClickQuit"></BaseButton>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import BaseButton from './BaseButton.vue'
import { pathToFileURL } from 'url';
import { countries } from '../services/countries';
import Swal from 'sweetalert2'
import Utils from "src/mixins/utils";

export default {
    name: 'CreateEditModal',
    mixins: [Utils],
    components: {
        'BaseButton': BaseButton,
    },
    data () {
        var rightsHolderOptions = this.$t("dsCreateEdit.form.rightsHolderOptions");
        for (let i = 0; i < rightsHolderOptions.length; i++) {
            const element = rightsHolderOptions[i];
            if (element.value == 'own_company') {
                element.text = this.getOrganization().name;
            }
        }

        return {
            mode: this.$route.params.mode,
            nameValue: '',
            description: '',
            provenance: this.$route.params.mode === 'api' ? 'API' : '',
            includesPII: '',
            piiDataFormat: '',
            connection_type: '',
            rightHolder: '',
            currency: '',
            cost: '',
            type: '',
            createdAt: '',
            updatedAt: '',
            // DB only values
            host: '',
            cloudProvider: '',
            country: '',
            port: null,
            dbPorts: {
                redshift: 5439,
                mariadb: 3306,
                mysql: 3306,
                oracle: 1521,
                postgres: 5432,
                sqlserver: 1433,
                db2: 50000,
                saphana: 30015
            },
            projectId: '',
            email: '',
            privateKey: '',
            bigQueryProject: '',
            bigQueryDataset: '',
            user: '',
            password: '',
            database: '',
            schema: 'public',
            domain: '',

            // CSV only values
            fileUrl: '',
            file: '',
            separator: ',',
            rowNames: 'auto',
            fileName: '',
            content: '',

            // Parsed
            rightsHolderOptions: rightsHolderOptions,

            exampleCsvTemplateHeader: 'Super Name,Real Name,Publisher,Creators,Strength',
            exampleCsvTemplateBody: 'Superman,Clark Kent,DC Comics,Action Comics,100<br> Batman,Bruce Wayne,DC Comics,Action Comics,18<br> Spiderman,Peter Parker,Marvel,Stan Lee,55',
            exampleCsv: '',
            countriesOption: countries,
            serverOptions: ['AWS', 'Azure', 'Google Cloud', 'Oracle Cloud', 'IBM Cloud', 'Alibaba Cloud', 'Huawei Cloud', 'DigitalOcean', 'On Premise', 'Private Data Center']
        }
    },
    methods: {
        parseEscapeNewline(evt){
          let paste = (event.clipboardData || window.clipboardData).getData('text');
          let parsed = paste.replace(/\\n/g, '\n'); // if the pasted content has \n's it needs to be considered newline without escape char
          evt.preventDefault();
          this.privateKey = parsed;
        },

        updateExampleCsvTemplate(){
            if( this.rowNames == 'no' ){
                this.exampleCsv = '';
            }else{
                this.exampleCsv = this.exampleCsvTemplateHeader + '<br/>';
            }
            this.exampleCsv += this.exampleCsvTemplateBody;
            this.exampleCsv = this.exampleCsv.replace(/,/g, this.separator );
        },
        resetData() {
            Object.assign(this.$data, this.$options.data());
        },
        resetForm: function() {
            [...document.getElementsByClassName("form-control")].map(input => {
                input.classList.remove("invalidField");
            });
        },
        resetUpload(){
            this.fileName = '';
            this.content = '';
            this.nameValue = '';
            this.fileUrl = '';
        },
        getFileName: function(filePath){
            let filePath_parts = filePath.split('.');
            if( filePath_parts.length > 1 )
                filePath_parts.pop()
            filePath_parts = filePath_parts.join('.').split('/')
            let fileName = filePath_parts[ filePath_parts.length-1 ];
            return fileName;
        },
        getFileExtension: function(filePath) {
            let parts = filePath.split('.');
            let extension = parts.pop().toLowerCase();

            parts = extension.split('?');
            // if ? was not found, splits return the whole string in an array
            extension = parts[0];

            return extension;
        },
        fileUrlChanged: function (event) {
            $("#fieldFile").val('');
            this.fileName = '';
            this.content = '';
            const type = $("#fileUrl").val().split(/(xlsx|xls|csv)$/g)[1];
            this.nameValue = this.isEdit ? this.nameValue : this.getFileName( $("#fileUrl").val() );

            if(['xls', 'xlsx'].includes(type)) {
                this.type = 'excel';
            } else if(['csv'].includes(type)) {
                this.type = 'csv';
            }else{
                this.type = '';
            }
        },
        fileChanged: function (event) {
            this.fileUrl = '';
            var vueCtrl = this;
            let file = event.target.files[0];
            vueCtrl._data.fileName = file.name;
            const type = this.getFileExtension( file.name );
            this.nameValue = this.isEdit ? this.nameValue : this.getFileName( file.name );
            var reader = new FileReader();
            if(['xls', 'xlsx'].includes(type)) {
                this.type = 'excel';
                reader.onload = function(fileReadeEvent) {
                    const data = fileReadeEvent.target.result;
                    let content = Buffer.from(data, 'binary');
                    vueCtrl._data.content = content
                }
                reader.onerror = e => console.error("File could not be read! " + event.target.error.code);
                reader.readAsBinaryString(file);
            } else if(['csv'].includes(type)) {
                this.type = 'csv';
                reader.onload = function(fileReadeEvent) {
                    let content = fileReadeEvent.target.result;
                    vueCtrl._data.content = content;
                }
                reader.onerror = e => console.error("File could not be read! " + event.target.error.code);
                reader.readAsText(file);
            }else{
                Swal.fire({
                    title: 'Error!',
                    text: this.$t('datasources_erros.unsupported_file'),
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
                $("#fieldFile").val('');
                this.fileName = '';
                this.content = '';
            }
        },
        fieldCostChanged: function(e) {
            let value = e.target.value;
            if (value.length > 15) {
              value = value.substring(0, 16);
            } else if (value.length == 0) {
              return;
            }
            value = value.replace(/\D/g, '');
            const n = Number(value)/100;
            this.cost = n.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },
        validateForm: function() {
            let errorMessage;

            if ( this.nameValue == '' ) {
                errorMessage = this.$t('datasources_erros.name')
            } else if (this.provenance == '') {
                errorMessage = this.$t('datasources_erros.provenance')
            } else if ( this.rightHolder == '' ) {
                errorMessage = this.$t('datasources_erros.rightHolder')
            } else if ( this.includesPII == '' ) {
                errorMessage = this.$t('datasources_erros.includesPII')
            } else if ( this.includesPII == 'true' && this.piiDataFormat == '' ) {
                errorMessage = this.$t('datasources_erros.piiDataFormat')
            } else if ( this.fileUrl && this.type == '' ) {
                errorMessage = this.$t('datasources_erros.unsupported_file');
            } else if (this.type == '') {
                errorMessage = this.$t('datasources_erros.type')
            } else if ( this.type == "csv" || this.type == "excel" ) {
                if ( this.fileName == '' && this.fileUrl == '' )
                    errorMessage = this.$t('datasources_erros.upload_file')
                else if ( this.fileName == '' && this.fileUrl != '' ){
                    // If URL is provided, confirm it has a valir URL format
                    var isValidUrl = this.fileUrl.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                    if( !isValidUrl )
                        errorMessage = this.$t('datasources_erros.invalid_url')
                }
            } else if (!['csv', 'excel', 'bigquery'].includes(this.type)) {
                if (this.host == '') {
                    errorMessage = this.$t('datasources_erros.host')
                } else if (this.cloudProvider == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.cloud_provider')
                } else if  (this.country == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.country')
                } else if (this.port == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.port')
                } else if (this.user == '') {
                    errorMessage = this.$t('datasources_erros.user')
                } else if (this.database == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.database')
                } else if (this.schema == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.schema')
                }
            } else if (this.type == 'bigquery') {
                if (this.projectId == '') {
                    errorMessage = this.$t('datasources_erros.projectId')
                } else if (this.email == '') {
                    errorMessage = this.$t('datasources_erros.email')
                } else if  (this.privateKey == '') {
                    errorMessage = this.$t('datasources_erros.privateKey')
                } else if  (this.bigQueryProject == '') {
                    errorMessage = this.$t('datasources_erros.bigQueryProject')
                } else if  (this.bigQueryDataset == '') {
                    errorMessage = this.$t('datasources_erros.bigQueryDataset')
                }
            }

            if (this.rightHolder == 'other_company') {
                if (this.currency == '') {
                    errorMessage = this.$t('datasources_erros.currency')
                } else if (this.cost == '') {
                    errorMessage = this.$t('datasources_erros.cost')
                }
            }

            if( errorMessage){
                Swal.fire({
                    title: 'Error!',
                    text: errorMessage,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    //onBeforeOpen: () => Swal.hideLoading()
                });
                return;
            }

            if (this.rightHolder != 'other_company') {
                this.currency = '';
                this.cost = '';
            }

            let formJSON = {
                "name": this.nameValue,
                "description": this.description,
                "provenance": this.provenance,
                "includesPII": this.includesPII,
                "piiDataFormat": this.piiDataFormat,
                "connection_type": this.connection_type,
                "rightHolder": this.rightHolder,
                "domain": this.domain,
                "currency": this.currency,
                "cost": this.cost ? Number(this.cost.replace(/[.,]/g, '')).toString() : '',
                "type": this.type,
            };

            switch (this.mode) {
                case 'database':
                    if (this.type == 'bigquery') {
                      formJSON.config = {
                          "credentials": {
                              "type": this.type,
                              "projectId": this.projectId,
                              "project": this.bigQueryProject,
                              "dataset": this.bigQueryDataset,
                              "credentials": {
                                "client_email": this.email,
                                "private_key": this.privateKey,
                              }
                          }
                      }
                    } else {
                      formJSON.config = {
                          "credentials": {
                              "type": this.type,
                              "host": this.host,
                              "port": this.port,
                              "user": this.user,
                              "password": this.password,
                              "database": this.database,
                              "schema": this.schema
                          },
                          "hosting": {
                              "provider": this.cloudProvider,
                              "country": this.country
                          }
                      };
                    }
                    break;

                case 'api':
                    formJSON.config = {
                        "credentials": {
                            "type": this.type,
                            "host": this.host,
                            "apiKey": this.user,
                            "password": this.password
                        },
                    };
                    break;

                default:
                    formJSON.config = {
                        "parsing": {
                            "headers": this.rowNames,
                            "delimiter": this.separator
                        }
                    };
                    formJSON.file = {
                        "name": this.fileName,
                        "content": this.content
                    };
                    formJSON.fileName = this.fileName;
                    formJSON.url = this.fileUrl;
                    break;
            }

            this.onFormValidated(formJSON, this.publicId);
            return formJSON;
        },

        editItem (item) {
            this.nameValue = item.name;
            this.description = item.description;
            this.provenance = item.provenance;
            this.type = item.type;
            this.createdAt = item.createdAt.split("T")[0];
            this.updatedAt = item.updatedAt.split("T")[0];
            this.domain = item.domain;

            if (this.type != 'csv') {
                this.mode = item.type == 'shopify' ? 'api' : 'database';
                this.host = item.config.credentials.host;

                if (item.config.credentials.port) {
                    this.port = item.config.credentials.port;
                    this.user = item.config.credentials.user;
                    this.password = item.config.credentials.password;
                    this.database = item.config.credentials.database;
                    this.schema = item.config.credentials.schema;
                    this.cloudProvider = item.config.hosting.provider;
                    this.country = item.config.hosting.country;
                } else {
                    this.user = item.config.credentials.apiKey;
                    this.password = item.config.credentials.password;
                }
            } else {
                this.mode = 'upload'
                this.separator = item.config.parsing.delimiter;
                this.rowNames = item.config.parsing.headers;

                if (item.config.external) {
                    this.fileUrl =  item.config.external.url;
                } else {
                    this.file = `${item.config.aws.s3.Bucket}/${item.config.aws.s3.Key}`;
                    this.fileName = item.config.aws.s3.Key.split('/')[1];
                }
            }

            this.rightHolder = item.userExtras.rightHolder;
            this.includesPII = item.userExtras.includesPII;
            this.piiDataFormat = item.userExtras.piiDataFormat;
        }
    },
    watch: {
        type: function(val) {
            this.port = this.dbPorts[val];
        }
    },
    props: {
        isEdit: {
            type: Boolean,
            required: true
        },
        onClickQuit: {
            type: Function,
            required: true
        },
        onFormValidated: {
            type: Function,
            required: true
        }
    },
    mounted() {
        this.updateExampleCsvTemplate();
    }
}
</script>


<style scoped lang="scss">
.modal-container {
    display: block;
    padding: 10px
}
.invisible {
    display: none;
}

.dropbox {
    outline: 2px dashed grey;
    /* the dash box */
    outline-offset: -10px;
    background: #d4dff7;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
    width: 100%;
}

.dropbox:hover {
    background: #e6edfb;
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

select{
    -webkit-appearance: menulist-button !important;
}

.csv_example {
    background: #f4f4f4;
    border: solid 1px #bbd;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 1.0em;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 15px;
    display: block;
    word-wrap: break-word;
}
</style>

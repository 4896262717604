import AuditData from 'src/pages/ddv-home/AuditData.vue'
import ClientApp from 'src/pages/ClientApp.vue'
import AuditMap from 'src/pages/AuditMap.vue'
import AuditUsers from 'src/pages/ddv-home/AuditUsers.vue'
import CheckingAccount from 'src/pages/ddv-home/CheckingAccount.vue'
// @ts-nocheck
import DashboardLayout from '../layout/DashboardLayout.vue'
import DataAppDIMReport from 'src/pages/ddv-home/DataAppDIMReport.vue'
import Dataexplorer from 'src/pages/ddv-home/DataExplorer.vue'
import DatasetEdit from 'src/pages/ddv-home/DatasetEdit.vue'
import DatasetView from 'src/pages/DatasetView.vue'
import DatasourceCreate from 'src/pages/DatasourceCreate.vue'
import DatasourceEdit from 'src/pages/DatasourceEdit.vue'
import DatasourceView from 'src/pages/DatasourceView.vue'
import DatawalletCreate from 'src/pages/DataWalletCreate.vue'
import Datawallets from 'src/pages/ddv-home/Datawallets.vue'
import Embed from 'src/pages/Embed.vue'
import Home from 'src/pages/ddv-home/Home.vue'
import Landscapes from 'src/pages/ddv-home/Landscapes.vue'
import Login from 'src/pages/Login.vue'
import Marketplace from 'src/pages/ddv-home/Marketplace.vue'
import MyOrganization from 'src/pages/ddv-home/MyOrganization.vue'
import NotFound from '../pages/NotFoundPage.vue'
import NotFoundOrganization from '../pages/NotFoundOrganization.vue'
import OrganizationCreate from 'src/pages/OrganizationCreate.vue'
import OrganizationLibrary from 'src/pages/ddv-home/OrganizationLibrary.vue'
import Organizations from 'src/pages/ddv-home/Organizations.vue'
import Overview from 'src/pages/ddv-home/Overview.vue'
import PasswordRecovery from 'src/pages/PasswordRecovery.vue'
import PasswordSetup from 'src/pages/PasswordSetup.vue'
import Projects from 'src/pages/ddv-home/Projects.vue'
import SavingsAccount from 'src/pages/ddv-home/SavingsAccount.vue'
import Security from 'src/pages/ddv-home/Security.vue'
import Settings from 'src/pages/ddv-home/Settings.vue'
import SettingsLayout from '../layout/SettingsLayout.vue'
import TermsOfUse from 'src/pages/TermsOfUse.vue'
import UserCreate from 'src/pages/UserCreate.vue'
import Users from 'src/pages/ddv-home/Users.vue'
import View from 'src/pages/View.vue'

// import DatawalletEdit from 'src/pages/DataWalletEdit.vue'

function findBootstrapEnvironment () {
  const envs = ['xs', 'sm', 'md', 'lg', 'xl']

  const el = document.createElement('div')
  document.body.appendChild(el)

  let curEnv = envs.shift()

  for (const env of envs.reverse()) {
    el.classList.add(`d-${env}-none`)

    if (window.getComputedStyle(el).display === 'none') {
      curEnv = env
      break
    }
  }

  document.body.removeChild(el)
  return curEnv
}

export default [
  { path: '/embed/:embedCode', component: Embed },
  { path: '/login', component: Login },
  { path: '/terms-of-use', component: TermsOfUse },
  { path: '/new-password/:recoverId', component: PasswordSetup },
  { path: '/password-recovery', component: PasswordRecovery },
  {
    path: '/',
    component: DashboardLayout,
    redirect: () => {
      if (findBootstrapEnvironment() !== 'xl') {
        return '/home'
      }

      return '/dwallet'
    },
    children: [
      {
        path: 'landscapes',
        name: 'Landscapes',
        component: Landscapes
      },
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'users',
        name: 'Users',
        component: Users
      },
      {
        path: 'users/create',
        name: 'UserCreate',
        component: UserCreate
      },
      {
        path: 'organizations',
        name: 'Organizations',
        component: Organizations
      },
      {
        path: 'organizations/create',
        name: 'OrganizationCreate',
        component: OrganizationCreate
      },
      {
        path: 'projects',
        name: 'Projects',
        component: Projects
      },
      {
        path: 'dwallet',
        name: 'dWallet',
        component: SavingsAccount
      },
      {
        path: 'auditdata',
        name: 'AuditData',
        component: AuditData
      },
      {
        path: 'auditusers',
        name: 'AuditUsers',
        component: AuditUsers
      },
      {
        path: 'exchange',
        name: 'Exchange',
        component: CheckingAccount
      },
      {
        path: 'datasources/:publicId',
        name: 'DatasourceEdit',
        component: DatasourceEdit
      },
      {
        path: '/datasources/create/:mode',
        name: 'DatasourceCreate',
        component: DatasourceCreate
      },
      {
        path: '/dataexplorer',
        name: 'Dataexplorer',
        component: Dataexplorer
      },
      {
        path: 'datasets/:publicId',
        name: 'DatasetEdit',
        component: DatasetEdit
      },
      {
        path: '/dataexplorer/wallets',
        name: 'Datawallets',
        component: Datawallets
      },
      {
        path: '/dataexplorer/wallets/create',
        name: 'DatawalletCreate',
        component: DatawalletCreate
      },
      {
        path: '/organization/library',
        name: 'OrganizationLibrary',
        component: OrganizationLibrary
      },
      {
        path: '/dataexplorer/marketplace',
        name: 'Marketplace',
        component: Marketplace
      },
      {
        path: 'dataapps/:publicId',
        name: 'DataAppDIMReport',
        component: DataAppDIMReport
      }
    ]
  },
  {
    path: '/settings',
    component: SettingsLayout,
    redirect: () => {
      return '/settings/profile'
    },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: Settings
      },
      {
        path: 'security',
        name: 'Security',
        component: Security
      },
      {
        path: 'organization',
        name: 'MyOrganization',
        component: MyOrganization
      }
    ]
  },
  // TODO is this being used?
  { path: '/view', component: View },
  { path: '/datasource/:projectName/:datasourceName', component: DatasourceView },
  { path: '/datasets/:datasetPublicId/:projectName', component: DatasetView },
  { path: '/auditmap', component: AuditMap, props: { path: 'JavierC/audit/mapInterface#@13' } },
  { path: '/auditcatalog', component: AuditMap, props: { path: 'JavierC/audit/dbDiagram#@0' } },
  { path: '/auditreport', component: AuditMap, props: { path: 'JavierC/audit/auditReport#@0' } },
  { path: '/auditscore', component: AuditMap, props: { path: 'JavierC/audit/cubeScore#@0' } },
  { path: '/:landscapeName/:viewName', component: View },
  { path: '/wkc', component: ClientApp, props: { path: 'https://signup.lichenstg.com/' } },
  { path: '/404', name: 'NotFound', component: NotFoundOrganization },
  { path: '*', component: NotFound }
]

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("projects.title")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-category" }, [
                      _vm._v(_vm._s(_vm.$t("projects.subTitle")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "raw-content" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "container-search-bar-and-create-button searchContainer"
                        },
                        [
                          _c(
                            "form",
                            {
                              staticStyle: { padding: "15px" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.searchInputValue($event)
                                }
                              }
                            },
                            [
                              _c("BaseInput", {
                                ref: "searchInput",
                                attrs: {
                                  addonRightIcon: "fa fa-search",
                                  placeholder: "Search",
                                  onClick: _vm.searchInputValue,
                                  onKeyUp: _vm.searchInputValue
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "displaySwitchContainer",
                          staticStyle: { clear: "both" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "projectsFolderDisplay" } },
                            _vm._l(_vm.folderBreadcrumb, function(item, index) {
                              return _c("FolderDisplay", {
                                key: index,
                                attrs: {
                                  folderId: index,
                                  acessedFolder: item,
                                  onClick: _vm.filterFolder
                                }
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "switch-button" },
                            [
                              _c("toggle-button", {
                                attrs: {
                                  value: true,
                                  color: {
                                    checked: "lightgray",
                                    unchecked: "lightgray"
                                  },
                                  labels: {
                                    checked: _vm.$t(
                                      "projects.selector.option1"
                                    ),
                                    unchecked: _vm.$t(
                                      "projects.selector.option2"
                                    )
                                  },
                                  width: 80
                                },
                                on: { change: _vm.alternateFolderTree }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _vm._l(this.currentFolder, function(item, index) {
                          return _c("BaseButton", {
                            key: index,
                            attrs: {
                              buttonLabel: item.path,
                              value: item.path,
                              addonLeftIcon: "fa fa-folder-o",
                              onClick: _vm.getInFolder
                            }
                          })
                        }),
                        _vm._v(" "),
                        _c("DataTable", {
                          staticClass: "table-hover table-striped",
                          attrs: {
                            headers: _vm.table1.columns,
                            items: _vm.table1.data,
                            namesToLinks: true,
                            page: "projects",
                            linkBuilder: _vm.linktoStudio,
                            linkTarget: "_blank"
                          }
                        })
                      ],
                      2
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <card
      class="strpied-tabled-with-hover grid-container"
      header-classes="col-6 pull-left"
      body-classes="table-full-width table-responsive "
      style="margin: 0px"
    >
      <div class="col-6 pull-left containerRight">
        <div class="row info-row ds-info-text">
          <div class="col-2 infoCol2" style="padding: 0px">
            {{ $t("transactions.is_public") }}:
          </div>
          <div class="col-3 infoCol3 pull-left" style="padding: 0px">
            <b>{{
              dataAssetIsPublic == true
                ? $t("transactions.yes")
                : $t("transactions.no")
            }}</b>
          </div>
          <div
            v-if="dataAssetIsPublic"
            class="col-3 infoCol3"
            style="padding: 0px"
          >
            {{ $t("transactions.posted_to_library_at") }}:
          </div>
          <div
            v-if="dataAssetIsPublic"
            class="col-3 infoCol3 pull-left"
            style="padding: 0px"
          >
            <b>{{ postedAt }}</b>
          </div>
        </div>

        <div class="row info-row ds-info-text">
          <div class="col-2 infoCol2" style="padding: 0px">
            {{ $t("transactions.is_in_your_wallet") }}:
          </div>
          <div class="col-3 infoCol3 pull-left" style="padding: 0px">
            <b>{{
              dataAssetIsInYourWallet == true
                ? $t("transactions.yes")
                : $t("transactions.no")
            }}</b>
          </div>
          <div
            v-if="youHaveBorrowIt || youHaveReceivedATransfer"
            class="col-3 infoCol3"
            style="padding: 0px"
          >
            {{ $t("transactions.type_of_licensing") }}:
          </div>
          <div
            v-if="youHaveBorrowIt"
            class="col-3 infoCol3 pull-left"
            style="padding: 0px"
          >
            <b>{{ $t("transactions.you_borrowed_it") }}</b>
          </div>
          <div
            v-if="youHaveReceivedATransfer"
            class="col-3 infoCol3 pull-left"
            style="padding: 0px"
          >
            <b>{{ $t("transactions.you_received_it") }}</b>
          </div>
        </div>

        <div v-if="youHaveBorrowIt" class="row info-row ds-info-text">
          <div class="col-2 infoCol2" style="padding: 0px">
            {{ $t("transactions.borrowed_at") }}:
          </div>
          <div class="col-3 infoCol3 pull-left" style="padding: 0px">
            <b>{{ borrowedAt }}</b>
          </div>
        </div>

        <div v-if="youHaveReceivedATransfer" class="row info-row ds-info-text">
          <div class="col-2 infoCol2" style="padding: 0px">
            {{ $t("transactions.transfer_starting_at") }}:
          </div>
          <div class="col-3 infoCol3 pull-left" style="padding: 0px">
            <b>{{ yourTransfer.startAt }}</b>
          </div>
          <div class="col-3 infoCol3" style="padding: 0px">
            {{ $t("transactions.transfer_ending_at") }}:
          </div>
          <div class="col-3 infoCol3 pull-left" style="padding: 0px">
            <b>{{ yourTransfer.endAt }}</b>
          </div>
        </div>

        <div v-if="youHaveReceivedATransfer" class="row info-row ds-info-text">
          <div class="col-2 infoCol2" style="padding: 0px">
            {{ $t("transactions.intent") }}:
          </div>
          <div class="col-3 infoCol3 pull-left" style="padding: 0px">
            <b>{{ yourTransfer.intent }}</b>
          </div>
        </div>
      </div>

      <div class="col-6 pull-left containerRight">
        <div class="row info-row ds-info-text">
          <div class="col-5 infoCol5 pull-right" style="padding: 0px">
            <div
              class="col-5 pull-left"
              style="padding-right: 0px; text-align: right"
            >
              <BaseButton
                v-if="dataAssetIsPublic && loggedUserIsTheOwner"
                :onClick="unpostDataAsset"
                :buttonLabel="$t('transactions.remove_from_org_lib')"
                style="padding: 12px 30px; margin: 0px; float: right"
              ></BaseButton>
              <BaseButton
                v-else-if="!dataAssetIsPublic && loggedUserIsTheOwner"
                :onClick="postDataAsset"
                :buttonLabel="$t('transactions.post_to_org_lib')"
                style="padding: 12px 30px; margin: 0px; float: right"
              ></BaseButton>
              <BaseButton
                v-if="
                  !youHaveReceivedATransfer &&
                  youHaveBorrowIt &&
                  !loggedUserIsTheOwner &&
                  dataAssetIsPublic
                "
                :onClick="unborrowDataAsset"
                :buttonLabel="$t('transactions.return_to_lib')"
                style="padding: 12px 30px; margin: 0px; float: right"
              ></BaseButton>
              <BaseButton
                v-else-if="
                  !youHaveReceivedATransfer &&
                  !youHaveBorrowIt &&
                  !loggedUserIsTheOwner &&
                  dataAssetIsPublic
                "
                :onClick="borrowDataAsset"
                :buttonLabel="$t('transactions.borrow')"
                style="padding: 12px 30px; margin: 0px; float: right"
              ></BaseButton>
            </div>
          </div>
        </div>
      </div>
    </card>

    <card
      v-if="loggedUserIsTheOwner"
      class="strpied-tabled-with-hover"
      body-classes="table-full-width table-responsive"
    >
      <div v-if="hasBorrows" class="row panelRow">
        <div class="col-10 panelTitle">{{ $t("transactions.borrows") }}</div>
      </div>
      <div v-if="!transactionsLoaded" class="loadingMessage">
        {{ $t("general.loading") }}
      </div>
      <v-data-table
        v-if="transactionsLoaded && hasBorrows"
        :headers="columnsBorrows"
        :items="borrows"
        :pagination.sync="paginationBorrows"
        :rows-per-page-items="rowsPerPageItems"
      >
        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.user.email }}</td>

            <td>{{ props.item.user.name }}</td>

            <td>{{ props.item.updatedAt }}</td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="hasTransfers" class="row panelRow">
        <div class="col-10 panelTitle">{{ $t("transactions.transfers") }}</div>
      </div>
      <div v-if="!transactionsLoaded" class="loadingMessage">
        {{ $t("general.loading") }}
      </div>
      <v-data-table
        v-if="transactionsLoaded && hasTransfers"
        :headers="columnsTransfers"
        :items="transfers"
        :pagination.sync="paginationTransfers"
        :rows-per-page-items="rowsPerPageItems"
      >
        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.user.email }}</td>

            <td>{{ props.item.user.name }}</td>

            <td>{{ props.item.startAt }}</td>

            <td>{{ props.item.endAt }}</td>

            <td>{{ props.item.intent }}</td>
          </tr>
        </template>
      </v-data-table>
    </card>
  </div>
</template>

<script>
import Vue from "vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import DataTable from "src/components/DataTable.vue";
import Swal from "sweetalert2";
import TransactionsService from "src/services/transactions";
import Utils from "src/mixins/utils";
import OrganizationLibraryService from "src/services/organizationLibrary";

export default {
  mixins: [Utils],
  name: "data-asset-transactions",
  components: {
    Card,
    BaseInput,
    BaseButton,
    DataTable,
  },
  props: {
    dataAssetPublicId: String,
    dataAssetName: String,
  },
  data() {
    return {
      columnsBorrows: [
        { text: this.$t("transactions.tableBorrows.email"), value: "email" },
        {
          text: this.$t("transactions.tableBorrows.userName"),
          value: "name",
        },
        {
          text: this.$t("transactions.tableBorrows.borrowedAt"),
          value: "borrowedAt",
        },
      ],
      columnsTransfers: [
        { text: this.$t("transactions.tableTransfers.email"), value: "email" },
        {
          text: this.$t("transactions.tableTransfers.userName"),
          value: "name",
        },
        {
          text: this.$t("transactions.tableTransfers.startAt"),
          value: "startAt",
        },
        { text: this.$t("transactions.tableTransfers.endAt"), value: "endAt" },
        {
          text: this.$t("transactions.tableTransfers.intent"),
          value: "intent",
        },
      ],
      paginationBorrows: {
        sortBy: "borrowedAt",
        rowsPerPage: 10,
      },
      paginationTransfers: {
        sortBy: "startAt",
        rowsPerPage: 10,
      },
      rowsPerPageItems: [10, 50, 100],

      dataAssetIsPublic: false,
      dataAssetIsInYourWallet: false,
      postedAt: "",
      loggedUserIsTheOwner: false,
      post: {},
      youHaveBorrowIt: false,
      youHaveReceivedATransfer: false,
      yourBorrow: {},
      yourTransfer: {},
      borrowedAt: "",
      transactionsLoaded: false,
      hasBorrows: false,
      hasTransfers: false,
    };
  },
  methods: {
    async loadDataAssetTransactions(id) {
      this.transactionsLoaded = false;
      const t = await TransactionsService.getDataAssetTransactions(id);
      const loggedUserPublicId = this.getUserPublicId();

      this.dataAssetIsPublic = !!t.transactions.post;
      this.post = t.transactions.post;

      if (this.dataAssetIsPublic == true) {
        this.postedAt = this.formatDate(this.post.updatedAt);
      }

      this.ownerPublicId = t.ownerPublicId;
      this.dataAssetType = t.dataAssetType;
      this.borrows = t.transactions.borrows;
      this.transfers = t.transactions.transfers;
      this.hasBorrows = this.borrows.length > 0;
      this.hasTransfers = this.transfers.length > 0;
      this.transactionsLoaded = true;
      this.loggedUserIsTheOwner = this.ownerPublicId == loggedUserPublicId;

      if (!this.loggedUserIsTheOwner) {
        this.yourBorrow = this.borrows.find(
          (b) => b.user.publicId == loggedUserPublicId
        );

        this.youHaveBorrowIt = !!this.yourBorrow;

        this.borrowedAt = this.youHaveBorrowIt
          ? this.formatDate(this.yourBorrow.updatedAt)
          : "";

        if (!this.youHaveBorrowIt) {
          this.yourTransfer = this.transfers.find(
            (t) => t.user.publicId == loggedUserPublicId
          );

          this.youHaveReceivedATransfer = !!this.yourTransfer;
        }
      }

      this.dataAssetIsInYourWallet =
        this.youHaveBorrowIt ||
        this.loggedUserIsTheOwner ||
        this.youHaveReceivedATransfer;
    },
    async postDataAsset() {
      let result = await Swal.fire({
        title: this.$t("postToLibrary.confirm.title"),
        html: this.$t("postToLibrary.confirm.text"),
        type: "warning",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("postToLibrary.confirm.confirmButton"),
        cancelButtonText: this.$t("postToLibrary.confirm.cancelButton"),
      });
      if (result.value) {
        let postObject = {};

        switch (this.dataAssetType) {
          case "views":
            postObject.dataAppPublicId = this.dataAssetPublicId;
            break;
          case "datasets":
            postObject.datasetPublicId = this.dataAssetPublicId;
            break;
          default:
            postObject.datasetPublicId = this.dataAssetPublicId;
            break;
        }

        let result = await OrganizationLibraryService.postItem(postObject);
        await this.loadDataAssetTransactions(this.dataAssetPublicId);
        Swal.fire(
          "Success!",
          "Your asset is now available in the library!",
          "success"
        );
      }
    },
    async unpostDataAsset() {
      let result = await Swal.fire({
        title: this.$t("unpostFromLibrary.confirm.title"),
        html: this.$t("unpostFromLibrary.confirm.text"),
        type: "warning",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("unpostFromLibrary.confirm.confirmButton"),
        cancelButtonText: this.$t("unpostFromLibrary.confirm.cancelButton"),
      });
      if (result.value) {
        let postPublicId = this.post.publicId;
        let result = await OrganizationLibraryService.unpostItem(postPublicId);
        await this.loadDataAssetTransactions(this.dataAssetPublicId);
        Swal.fire(
          "Success!",
          "Your asset has been removed from the library!",
          "success"
        );
      }
    },
    async borrowDataAsset() {
      let postPublicId = this.post.publicId;
      let result = await OrganizationLibraryService.borrowPost(postPublicId);
      await this.loadDataAssetTransactions(this.dataAssetPublicId);
      Swal.fire(
        "Success!",
        "The asset has been added to your dWallet!",
        "success"
      );
    },
    async unborrowDataAsset() {
      let result = await Swal.fire({
        title: this.$t("unborrowFromLibrary.confirm.title"),
        html: this.$t("unborrowFromLibrary.confirm.text", [this.dataAssetName]),
        type: "warning",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("unborrowFromLibrary.confirm.confirmButton"),
        cancelButtonText: this.$t("unborrowFromLibrary.confirm.cancelButton"),
      });
      if (result.value) {
        let postPublicId = this.post.publicId;
        let result = await OrganizationLibraryService.unborrowPost(
          postPublicId
        );
        await this.loadDataAssetTransactions(this.dataAssetPublicId);

        Swal.fire(
          "Success!",
          "The asset has been removed from your dWallet!",
          "success"
        );
      }
    },
    formatDate(date_str) {
      return date_str ? date_str.replace("T", " ").split(".")[0] : date_str;
    },
    diffDate(date_str) {
      if (date_str) {
        const update_date = new Date(date_str.replace("T", " ").split(".")[0]);
        const date = new Date();
        const diffTime = Math.abs(date - update_date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      }
    },
  },
  async mounted() {
    await this.loadDataAssetTransactions(this.dataAssetPublicId);
  },
};
</script>


<style>
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: unset;
  margin-left: unset;
}
.info-row {
  padding: 2px 0px;
}
.panelRow {
  border-bottom: solid 1px #ccc;
  padding: 10px 0px 10px 0px;
  width: 100%;
}
</style>

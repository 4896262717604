var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "content screen-area", attrs: { id: "settings" } },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("input", {
              ref: "input",
              attrs: { type: "file", name: "image", accept: "image/*" },
              on: { change: _vm.setImage }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("h4", { staticClass: "card-title mb-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("settings.title")) +
                          " - " +
                          _vm._s(_vm.$t("settings.profile.title"))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        attrs: { action: "", method: "post" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-6 pull-right",
                            staticStyle: { height: "250px" }
                          },
                          [
                            _c("div", { staticClass: "mb-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-6 pull-left",
                                  staticStyle: { height: "250px" }
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("settings.profile.picture")
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mb-3" }, [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.imgSrc && !_vm.imgChosen,
                                          expression: "imgSrc && !imgChosen"
                                        }
                                      ],
                                      staticStyle: { "border-radius": "50%" },
                                      attrs: {
                                        src: _vm.imgSrc,
                                        width: "200",
                                        height: "200"
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("vue-cropper", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.imgSrc && _vm.imgChosen,
                                        expression: "imgSrc && imgChosen"
                                      }
                                    ],
                                    ref: "cropper",
                                    staticStyle: {
                                      width: "200px !important",
                                      height: "200px !important"
                                    },
                                    attrs: {
                                      src: _vm.imgSrc,
                                      alt: "Source Image",
                                      cropmove: _vm.cropMoveEvent,
                                      cropend: _vm.cropImage
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-6 pull-right",
                                  staticStyle: { height: "250px" }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.imgSrc && _vm.imgChosen,
                                          expression: "imgSrc && imgChosen"
                                        }
                                      ],
                                      staticClass: "control-label"
                                    },
                                    [_vm._v("Preview")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.imgSrc && _vm.imgChosen,
                                          expression: "imgSrc && imgChosen"
                                        }
                                      ],
                                      staticClass: "mb-3"
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { "border-radius": "50%" },
                                        attrs: {
                                          src: _vm.imgSrc,
                                          width: "200",
                                          height: "200"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.imgChosen,
                                      expression: "imgChosen"
                                    }
                                  ],
                                  staticClass: "subNote logoSize",
                                  class: { logoSizeOk: _vm.sizeOk }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "organizationCreate.form.cropSize"
                                      ) +
                                        ": " +
                                        _vm.cropSize
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("BaseButton", {
                                    staticClass:
                                      "btn btn-primary btn-lg btn-block pull-left",
                                    attrs: {
                                      buttonLabel: _vm.$t(
                                        "basebutton.choose_image"
                                      ),
                                      onClick: _vm.showFileChooser
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("BaseButton", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.imgSrc && _vm.imgChosen,
                                        expression: "imgSrc && imgChosen"
                                      }
                                    ],
                                    staticClass:
                                      "btn btn-primary btn-lg btn-block pull-right",
                                    attrs: {
                                      buttonLabel: _vm.$t(
                                        "settings.profile.cropImage"
                                      ),
                                      onClick: _vm.useCropImage
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-6 pull-left",
                            staticStyle: { height: "250px" }
                          },
                          [
                            _c("div", { staticClass: "mb-3" }, [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v(_vm._s(_vm.$t("settings.profile.name")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name"
                                  }
                                ],
                                staticClass: "form-control medium",
                                attrs: {
                                  type: "text",
                                  id: "fieldName",
                                  name: "name"
                                },
                                domProps: { value: _vm.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.name = $event.target.value
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.profile.notification")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("v-switch", {
                                  model: {
                                    value: _vm.emailNotification,
                                    callback: function($$v) {
                                      _vm.emailNotification = $$v
                                    },
                                    expression: "emailNotification"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mb-3" }, [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v(_vm._s(_vm.$t("settings.profile.role")))
                              ]),
                              _vm._v(" "),
                              _c("h5", [_vm._v(_vm._s(_vm.role))])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("BaseButton", {
                          staticClass: "btn btn-primary btn-md btn-block",
                          staticStyle: {
                            float: "left",
                            color: "green",
                            "border-color": "green",
                            "margin-left": "0px"
                          },
                          attrs: {
                            buttonLabel: _vm.$t("settings.profile.update"),
                            buttonType: "submit",
                            onClick: _vm.updateUser
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
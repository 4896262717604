var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content", attrs: { id: "createWallet" } }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("walletCreate.title")) + " ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row panelsContainer" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-5 col-sm-12 commonPanel DetailsPanel"
                      },
                      [
                        _c("div", { staticClass: "row panelRow" }, [
                          _c(
                            "div",
                            { staticClass: "col-m-10 col-sm-12 panelTitle" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("walletCreate.details.title"))
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "panelBody" }, [
                          _c("div", { staticClass: "container" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-6 col-md-4 labelRow" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("walletCreate.details.name")
                                        ) + ":"
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-8 panelInput" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.walletName,
                                        expression: "walletName"
                                      }
                                    ],
                                    staticClass: "form-control pull-right",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.walletName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.walletName = $event.target.value
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-6 col-md-4 labelRow" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "walletCreate.details.description"
                                          )
                                        ) + ":"
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-8  panelInput" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.walletDescription,
                                        expression: "walletDescription"
                                      }
                                    ],
                                    staticClass: "form-control pull-right",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.walletDescription },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.walletDescription =
                                          $event.target.value
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-6 col-md-4 labelRow" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("walletCreate.details.status")
                                        ) + ":"
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-12 col-md-8 panelInput" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.$t("walletCreate.status"),
                                      solo: ""
                                    },
                                    model: {
                                      value: _vm.statusSelected,
                                      callback: function($$v) {
                                        _vm.statusSelected = $$v
                                      },
                                      expression: "statusSelected"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-2" }),
                    _vm._v(" "),
                    false
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-md-5 col-sm-12 commonPanel pull-right"
                          },
                          [
                            _c("div", { staticClass: "row panelRow" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-10 col-sm-12 panelTitle"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("walletCreate.subscription.title")
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "panelBody" }, [
                              _c("div", { staticClass: "container" }, [
                                _c("div", { staticClass: "row panelRow" })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "panelFooter" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-md-9 col-sm-12 createNew"
                                  },
                                  [
                                    _vm._v(
                                      "+ " +
                                        _vm._s(_vm.$t("walletCreate.add_new"))
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row panelsContainer" }, [
                    _c("div", { staticClass: "col-md-12 commonPanel" }, [
                      _c("div", { staticClass: "row panelRow" }, [
                        _c("div", { staticClass: "col-10 panelTitle" }, [
                          _vm._v(_vm._s(_vm.$t("walletCreate.datasets.title")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2 panelCounter" }, [
                          _vm._v(_vm._s(_vm.totalDatasets))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "panelBody" },
                        [
                          _c("v-data-table", {
                            staticClass: "col-md-12 col-sm-12",
                            attrs: {
                              items: _vm.datasets,
                              "item-key": "publicId",
                              headers: _vm.$t("walletCreate.datasets.headers")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "items",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "td",
                                      [
                                        _c("v-checkbox", {
                                          staticStyle: { height: "22px" },
                                          model: {
                                            value: props.selected,
                                            callback: function($$v) {
                                              _vm.$set(props, "selected", $$v)
                                            },
                                            expression: "props.selected"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(props.item.name))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(props.item.type))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.source))
                                    ])
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "panelFooter" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("BaseButton", {
                              staticClass: "btn btn-primary btn-lg btn-block",
                              staticStyle: { float: "left" },
                              attrs: {
                                buttonLabel: _vm.$t("walletCreate.create"),
                                buttonType: "submit",
                                onClick: _vm.submit
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

module.exports = {
  methods: {
    linktoDetails (header, propsItem) {
      if (header !== 'name') {
        return
      }

      switch (propsItem.type) {
        case 'dataset':
          return '/#/datasets/' + propsItem.publicId
        case 'view':
          return '/#/dataapps/' + propsItem.publicId
        default:
      }
    },

    parseDatasources (datasources) {
      const currentUserPubId = this.getUserPublicId()
      datasources.forEach(element => {
        element.icon = 'table'
        element.isCurrentUser = element.user?.publicId === currentUserPubId
        element.wrangler = element.isCurrentUser ? this.$t('auditData.myself') : element.user?.name
        element.dataApps = []
      })

      this.datasources = datasources
    },
    parseDatasets (datasets) {
      const currentUserPubId = this.getUserPublicId()
      datasets.forEach(element => {
        element.type = 'dataset'
        element.icon = 'table'
        element.dimScoreTotal = element.dimScore ? element.dimScore.total !== null ? element.dimScore.total.toFixed(2) : null : null
        element.isCurrentUser = element.dataSource?.user?.publicId === currentUserPubId
        element.dataSourceName = element.dataSource?.name
        element.wrangler = element.isCurrentUser ? this.$t('auditData.myself') : element.dataSource?.user?.name

        if (element.transferredTo) {
          element.transferredTo = element.transferredTo.map(item => {
            return {
              publicId: item['user.publicId'],
              email: item['user.email'],
              startAt: item.startAt.split('T')[0],
              endAt: item.endAt.split('T')[0],
              intent: item.intent
            }
          })
        } else {
          element.transferredTo = []
        }
        element.dataApps = []
        element.cells = element.numberOfRows * element.numberOfFields
      })

      this.datasets = datasets
    },
    parseViews (views) {
      const currentUserPubId = this.getUserPublicId()
      views.forEach(element => {
        element.type = 'view'
        element.icon = 'eye'
        element.dimScoreTotal = element.dimScore ? element.dimScore.total !== null ? element.dimScore.total.toFixed(2) : null : null
        element.isCurrentUser = element.user?.publicId === currentUserPubId
        element.wrangler = element.isCurrentUser ? this.$t('auditData.myself') : element.user?.name

        if (element.transferredTo) {
          element.transferredTo = element.transferredTo.map(item => {
            return {
              publicId: item['user.publicId'],
              email: item['user.email'],
              startAt: item.startAt.split('T')[0],
              endAt: item.endAt.split('T')[0],
              intent: item.intent
            }
          })
        } else {
          element.transferredTo = []
        }
        element.dataApps = []
      })
      this.views = views
    },
    transferCallback (item) {
      item.transferredTo = Array.from(item.transferredTo)
      this.$refs.transferDialog.updateData(item)
    }
  },
  created () {
    window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged)
  },
  beforeDestroy () {
    window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged)
  }
}

<template>
    <v-app>
        <div class="content" id="datasourcesCreate">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                        <template slot="header">
                            <div class="page-title">{{$t('dsCreateEdit.editTitle')}}</div>
                        </template>
                        <template slot="raw-content">
                            <CreateEditModal ref="createEditModal" :onClickQuit="quitCreateEdit" :onFormValidated="onFormValidated" :isEdit="true"></CreateEditModal>
                        </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>

import Vue from 'vue'
import Card from 'src/components/Cards/Card.vue'
import CreateEditModal from 'src/components/CreateEditDatasources.vue'
import DatasourceService from 'src/services/datasource'
import DatasetService from 'src/services/dataset'
import Swal from 'sweetalert2'

export default {
    components: {
        CreateEditModal,
        Card
    },
    data () {
      return {
        dataSourceId: null
      }
    },
    methods: {
        async onFormValidated(formData) {
            try {
                Swal.fire({
                    title: 'Submitting',
                    onOpen: () => {
                        Swal.showLoading();
                    }
                });
                if (['csv', 'excel'].includes(formData.type) && formData.file && formData.file.content) {
                  let params = {
                    name: formData.name,
                    fileName: formData.file ? formData.file.name : '',
                    content: formData.file? formData.file.content : '',
                    url: formData.url
                  }
                  params.fileType = formData.type == 'csv' ? 'text/csv' : 'application/vnd.ms-excel';
                  await DatasourceService.updateDatasource(this.dataSourceId, formData, params);

                } else {
                  await DatasourceService.updateDatasource(this.dataSourceId, formData);
                }
                this.quitCreateEdit();
                Swal.fire({
                    title: 'Datasource Updated!',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
            } catch (err) {
                let error = err[1].errors[0];
                console.error(err)
                Swal.fire({
                    title: 'Error!',
                    text: error,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    onBeforeOpen: () => Swal.hideLoading()
                });
            }
        },
        quitCreateEdit() {
            this.$router.push({name: 'dWallet'})
        },
        async loadDatasource(id) {
            this.dataSourceId = id;

            try {
                let dataSource = await DatasourceService.getDatasourceByPublicId(id);
                this.$refs.createEditModal.editItem(dataSource);
            } catch (error) {
                throw error;
            }
        }
    },
    async mounted() {
        await this.loadDatasource(this.$route.params.publicId)
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "content", attrs: { id: "auditaccount" } },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "card",
                  {
                    staticClass: "strpied-tabled-with-hover",
                    attrs: {
                      "body-classes": "table-full-width table-responsive"
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("div", { staticClass: "page-title" }, [
                        _vm._v(_vm._s(_vm.$t("auditData.title")))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-category" }, [
                        _vm._v(_vm._s(_vm.$t("auditData.subTitle")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "container-search-bar-and-create-button searchContainer"
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.searchInputValue($event)
                              }
                            }
                          },
                          [
                            _c("BaseInput", {
                              ref: "searchInput",
                              attrs: {
                                addonRightIcon: "fa fa-search",
                                placeholder: "Search",
                                onKeyUp: _vm.searchInputValue,
                                onClick: _vm.searchInputValue
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("DataProductsSearch", {
                      attrs: {
                        onChange: _vm.searchInputValue,
                        advancedFilters: _vm.advancedFilters
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticStyle: { clear: "both" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "-40px" } },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: { "slider-color": "#EC008C" },
                            model: {
                              value: _vm.selectedTab,
                              callback: function($$v) {
                                _vm.selectedTab = $$v
                              },
                              expression: "selectedTab"
                            }
                          },
                          [
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Data Sources")
                              ]),
                              _vm._v(" "),
                              _vm.datasources_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.datasources_tableDataProvider.length
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Datasets")
                              ]),
                              _vm._v(" "),
                              _vm.datasets_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.datasets_tableDataProvider.length
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("v-tab", [
                              _c("div", { staticClass: "tabName" }, [
                                _vm._v("Data Apps")
                              ]),
                              _vm._v(" "),
                              _vm.views_tableDataProvider
                                ? _c("div", { staticClass: "tabCounter" }, [
                                    _vm._v(
                                      _vm._s(_vm.views_tableDataProvider.length)
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.isSectionAllowed("dataProductsManagement")
                              ? _c("v-tab", [
                                  _c("div", { staticClass: "tabName" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataProduct.published"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.submissions_tableDataProvider
                                    ? _c("div", { staticClass: "tabCounter" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.submissions_tableDataProvider
                                              .length
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isSectionAllowed("dataProductsManagement")
                              ? _c("v-tab", [
                                  _c("div", { staticClass: "tabName" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataProduct.purchased"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.purchases_tableDataProvider
                                    ? _c("div", { staticClass: "tabCounter" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.purchases_tableDataProvider
                                              .length
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedTab == 0
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.datasources_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(_vm._s(_vm.$t("general.no_results")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.datasources_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_datasources,
                                    items: _vm.datasources_tableDataProvider,
                                    page: "auditaccount",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    transferCallback: _vm.transferCallback,
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTab == 1
                      ? _c(
                          "div",
                          [
                            _vm.filtersApplied &&
                            !_vm.datasets_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(_vm._s(_vm.$t("general.no_results")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.filtersApplied ||
                            (_vm.filtersApplied &&
                              _vm.datasets_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_datasets,
                                    items: _vm.datasets_tableDataProvider,
                                    page: "auditaccount",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTab == 2
                      ? _c(
                          "div",
                          [
                            _vm.filtersApplied &&
                            !_vm.views_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(_vm._s(_vm.$t("general.no_results")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.filtersApplied ||
                            (_vm.filtersApplied &&
                              _vm.views_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_views,
                                    items: _vm.views_tableDataProvider,
                                    page: "auditaccount",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    defaultSortBy: "updatedAt",
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTab == 3
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.submissions_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(_vm._s(_vm.$t("general.no_results")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.submissions_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_submissions,
                                    items: _vm.submissions_tableDataProvider,
                                    page: "auditaccount",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    defaultSortBy: "createdAt",
                                    dataProductCallback:
                                      _vm.openDataProductDialog,
                                    dataProductStatusChangedCallback:
                                      _vm.changeStatus,
                                    deleteCallback: _vm.deleteProduct,
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedTab == 4
                      ? _c(
                          "div",
                          [
                            _vm.searchWord &&
                            !_vm.purchases_tableDataProvider.length
                              ? _c("div", { staticClass: "loadingMessage" }, [
                                  _vm._v(_vm._s(_vm.$t("general.no_results")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.searchWord ||
                            (_vm.searchWord &&
                              _vm.purchases_tableDataProvider.length)
                              ? _c("DataTable", {
                                  attrs: {
                                    headers: _vm.columns_in_purchases,
                                    items: _vm.purchases_tableDataProvider,
                                    page: "auditaccount",
                                    linkBuilder: _vm.linktoDetails,
                                    linkTarget: "_self",
                                    defaultSortBy: "createdAt",
                                    dataProductStatusChangedCallback:
                                      _vm.changePurchaseStatus,
                                    deleteCallback: _vm.deleteProduct,
                                    sortDesc: true
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "FormDialog",
          {
            ref: "formDialog",
            attrs: {
              title: _vm.dataProductName,
              confirmCallBack: _vm.updateDataProduct,
              closeCallBack: _vm.closeDataProductForm,
              confirmLabel: "Update",
              closeLabel: "Cancel "
            }
          },
          [
            _c(
              "form",
              { attrs: { slot: "form" }, slot: "form" },
              [
                _c(
                  "label",
                  {
                    staticClass: "input-label",
                    staticStyle: { "margin-top": "20px" },
                    attrs: { for: "fieldDescription" }
                  },
                  [_vm._v("Describe this data product")]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dataProductDescription,
                      expression: "dataProductDescription"
                    }
                  ],
                  staticClass: "form-control",
                  staticStyle: { "min-height": "90px" },
                  attrs: { type: "text" },
                  domProps: { value: _vm.dataProductDescription },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.dataProductDescription = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "input-label",
                    staticStyle: { "margin-top": "20px" },
                    attrs: { for: "fieldTags" }
                  },
                  [
                    _vm._v(
                      'Choose one or more Tags (i.e. "people", "health", "business", "weather", ...)'
                    )
                  ]
                ),
                _vm._v(" "),
                _c("v-combobox", {
                  attrs: { clearable: "", solo: "", multiple: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function(data) {
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: { selected: data.selected, close: "" },
                              on: {
                                input: function($event) {
                                  return _vm.removeTag(data.item)
                                }
                              }
                            },
                            [_c("strong", [_vm._v(_vm._s(data.item))])]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dataProductTags,
                    callback: function($$v) {
                      _vm.dataProductTags = $$v
                    },
                    expression: "dataProductTags"
                  }
                })
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "content", attrs: { id: "users" } }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "card",
                {
                  staticClass: "strpied-tabled-with-hover",
                  attrs: { "body-classes": "table-full-width table-responsive" }
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("auditUsers.title")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-category" }, [
                      _vm._v(_vm._s(_vm.$t("auditUsers.subTitle")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "container-search-bar-and-create-button searchContainer"
                    },
                    [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.searchInputValue($event)
                            }
                          }
                        },
                        [
                          _c("BaseInput", {
                            ref: "searchInput",
                            attrs: {
                              addonRightIcon: "fa fa-search",
                              placeholder: "Search",
                              onClick: _vm.searchInputValue,
                              onKeyUp: _vm.searchInputValue
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { clear: "both" } }),
                  _vm._v(" "),
                  _vm.searchWord && !_vm.searchResults.length
                    ? _c("div", { staticClass: "loadingMessage" }, [
                        _vm._v(_vm._s(_vm.$t("general.no_results")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.table || !_vm.table.data
                    ? _c("div", { staticClass: "loadingMessage" }, [
                        _vm._v(_vm._s(_vm.$t("general.loading")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.table.data &&
                  (!_vm.searchWord ||
                    (_vm.searchWord && _vm.searchResults.length))
                    ? _c("data-table", {
                        attrs: {
                          items: _vm.table.data,
                          headers: _vm.table.columns,
                          page: "reportUsers"
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
  <div class="content" id="orgLibrary">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('organizationLibrary.title')}}</div>
            </template>              
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
                <div class="fieldGroup" style="    margin-top: 10px; text-align: right; color: #666;">
                <div style="display: inline-block;">Show: </div>
                <div style="display: inline-block;">
                  <select @change="onFilterChanged($event)" name="filter" id="fieldFilterInWallet" class="form-control small" v-model="filters.inWallet">
                    <option name="" value="all">All</option> 
                    <option name="" value="notInWallet">Not in my dWallet</option>
                  </select>
                </div>
              </div>
              </form>
            </div>
            <div style="clear: both;"/>
            <div style="margin-top: 20px; margin-bottom: 20px;">
              <v-tabs v-model="selectedTab" slider-color="#EC008C">
                <v-tab>
                  <div class="tabName">Datasets</div>
                  <div v-if="datasets_tableDataProvider"  class="tabCounter">{{datasets_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab>
                  <div class="tabName">Data Apps</div>
                  <div  v-if="views_tableDataProvider" class="tabCounter">{{views_tableDataProvider.length}}</div>
                </v-tab>
              </v-tabs>
            </div>

            <div v-if="selectedTab==0">
              <div v-if="searchWord && !datasets_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && datasets_tableDataProvider.length)"
                :headers="columns_in_datasets"
                :items="datasets_tableDataProvider"
                page="orgLibrary"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                :unpostCallback="unpostRow"
                :borrowCallback="borrowCallback"
                :unborrowCallback="unborrowRow"
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>

            <div v-if="selectedTab==1">
              <div v-if="searchWord && !views_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && views_tableDataProvider.length)"
                :headers="columns_in_views"
                :items="views_tableDataProvider"
                page="orgLibrary"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                :unpostCallback="unpostRow"     
                :borrowCallback="borrowCallback"       
                :unborrowCallback="unborrowRow"    
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>

          </card>
        </div>
      </div>
    </div>
    <TransferDialog ref="transferDialog" :onConfirmTransfer="onConfirmTransfer"/>
    <EmbedDialog ref="embedDialog" />
  </div>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import OrganizationLibraryService from 'src/services/organizationLibrary'
  import ViewService from 'src/services/view'
  import TransferService from 'src/services/transfer'
  import DataTable from 'src/components/DataTable.vue'
  import DelModal from 'src/components/DelModal.vue'
  import EmbedDialog from 'src/components/EmbedDialog.vue'
  import TransferDialog from 'src/components/TransferDialog.vue'
  import Swal from 'sweetalert2'
  import Utils from 'src/mixins/utils'
  import WalletUtils from 'src/mixins/walletUtils'

  export default {
    mixins: [Utils, WalletUtils],
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable,
      DelModal,
      TransferDialog,
      EmbedDialog
    },
    data () {
      return {
        columns_in_datasets: [
          { text: this.$t('dwallet.table.dimScore'), align: 'center', value: 'dimScoreTotal', sortable: true, tooltip: true,  tooltipText: this.$t('datasets.dimscore_info') },
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('dwallet.table.dataSource'), align: 'left', value: 'dataSourceName'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('dwallet.table.size'), value: 'size' },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        columns_in_views: [
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('dwallet.table.open'), value: 'dataApps', sortable: false },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        selectedTab: 0,
        datasets: [],
        views: [],
        datasets_tableDataProvider: null,
        views_tableDataProvider: null,
        editDelete: true,
        searchWord: '',
        autorefresh: null,
        refreshComponent: null,
        isBeingDestroyed: false,
        isTheSameUserOrg: true, 
        filters: {
          inWallet: 'all'
        }
      }
    },
    methods: {
      async searchInputValue(e) {
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.filtersApplied = this.filters.inWallet != "all";

        this.datasets_tableDataProvider = this.applyFilters( this.datasets );
        this.views_tableDataProvider = this.applyFilters( this.views );

        /* this.datasets_tableDataProvider = this.datasets.filter(row => row.name.toUpperCase().includes(this.searchWord));
        this.views_tableDataProvider = this.views.filter(row => row.name.toUpperCase().includes(this.searchWord)); */
      },
      applyFilters( list ){
        let results = list.filter(row => {
          let matchInWallet;
          if( this.filters.inWallet == "all" )
            matchInWallet = true;
          else if( this.filters.inWallet == "notInWallet" ){
            matchInWallet = !row.borrowedByMe && !row.isCurrentUser && !this.isItTransferedToUser(row);
          }
          let passesFilters = matchInWallet;
          return passesFilters;
        });
        if (this.searchWord) {
          results = results.filter(x => x.name.toUpperCase().includes(this.searchWord));
        }
        return results;
      },
      isItTransferedToUser(item) {
        const userPublicId = this.getUserPublicId();
        return item.transferredTo.reduce((boolDefault, row) => row.publicId == userPublicId || boolDefault, false);
      },
      async loadAllTables() {
        // starts all processes in parallel
        const d = this.loadOrganizationLibraryDatasets();
        const v = this.loadOrganizationLibraryViews();

        // but wait for them to finish before moving on
        await d;
        await v;

        this.isTheSameUserOrg = this.getOrganization().publicId == this.getOrganizationPublicId();
        this.searchInputValue();
      },

      async loadOrganizationLibraryDatasets() {
        try {
          let datasets = await OrganizationLibraryService.getOrganizationLibraryDatasets();
          this.parseDatasets(datasets);
          this.datasets_tableDataProvider = [...this.datasets];

        } catch (err) {
          if (this.isBeingDestroyed) {
            return;
          }
          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: 'Error loading Datasets',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async loadOrganizationLibraryViews() {
        try {
          let views = await OrganizationLibraryService.getOrganizationLibraryViews();
          this.parseViews(views);
          this.views_tableDataProvider = [...this.views];

        } catch (err) {
          if (this.isBeingDestroyed) {
            // do nothing
            return;
          }
        }
      },

      openEmbedDialog(item) {
        this.$refs.embedDialog.updateData(item);
      },

      

      async onConfirmTransfer(transferObject){
        try {
          Swal.fire({
              title: this.$t('share.processing'),
              onOpen: () => {
                  Swal.showLoading();
              }
          });
          let res = await TransferService.transferView(transferObject);
          await this.loadAllTables();
          return res;
        } catch(err) {
            Swal.fire({
                title: 'Error',
                text: this.$t('share.error'),
                type: 'error',
                confirmButtonText: 'Ok',
                onBeforeOpen: () => {
                    Swal.hideLoading();
                }
            });
          return err;
        }
      },
      async unpostRow(item){
        let result = await Swal.fire({
              title: this.$t('unpostFromLibrary.confirm.title'),
              html: this.$t('unpostFromLibrary.confirm.text'),
              type: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('unpostFromLibrary.confirm.confirmButton'),
              cancelButtonText: this.$t('unpostFromLibrary.confirm.cancelButton')
          });
        if (result.value) {
          let postPublicId = item.post.publicId;
          let result = await OrganizationLibraryService.unpostItem( postPublicId );
          await this.loadAllTables();
        }
      },
      async borrowCallback( item ){ 
        let postPublicId = item.post.publicId;
        let result = await OrganizationLibraryService.borrowPost( postPublicId );
        await this.loadAllTables();
        Swal.fire(
            'Success!',
            'The asset has been added to your dWallet!',
            'success'
          );
      },
      async unborrowRow(item){
        let result = await Swal.fire({
              title: this.$t('unborrowFromLibrary.confirm.title'),
              html: this.$t('unborrowFromLibrary.confirm.text', [item.name]),
              type: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('unborrowFromLibrary.confirm.confirmButton'),
              cancelButtonText: this.$t('unborrowFromLibrary.confirm.cancelButton')
          });
        if (result.value) {
          let postPublicId = item.post.publicId;
          let result = await OrganizationLibraryService.unborrowPost( postPublicId );
          await this.loadAllTables();

        Swal.fire(
            'Success!',
            'The asset has been removed from your dWallet!',
            'success'
          );
        }
      },
      onSelectedOrganizationChanged: function(e) {
        this.loadAllTables();
      }, 
      onFilterChanged: function(){
        this.searchInputValue()
      }
    },
    async mounted () {
      await this.loadAllTables();
    },
    beforeDestroy(){
      this.isBeingDestroyed = true;
      clearTimeout(this.refreshComponent);
    }
  }
</script>


<style>
  .container-search-bar-and-create-button{
    margin: 0px;
  }

  .searchContainer{
    float: right;
    margin-top: -50px;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .tabName{
    display: inline-block;
  }

  .tabCounter{
    background-color: #e4e4e4;
    margin-left: 6px;
    padding: 2px 7px;
    border-radius: 10px;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
  }

  .v-tabs__div{
    min-width: 160px;
  }

  .processingContainer{
    padding: 10px 10px;
    margin: 20px 10px;
    float: left;
    color: #666;
  }

  .connectButtonsContainer{
    float: right;
    /* width: 600px; */
    padding: 0px;
    text-align: right;
  }
  .connectButton{
    width: 140px !important;
    min-width: unset !important;
    margin-right: 0px !important;
  }
</style>

<template>
  <v-app>
  <div class="content" id="organizations">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('organizations.title')}}</div>
              <p class="card-category">{{$t('organizations.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput ref="searchInput" addonRightIcon="fa fa-search" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
              </form>
            </div>
            <BaseButton v-if="isCreateAllowed('organizations')" :buttonLabel="'+ '+$t('organizations.create')" style="padding: 12px 10px; margin: 20px 0px; float: right;" :onClick="createOrganization"></BaseButton>
            <div style="clear: both;"/>
            <div v-if="searchWord && !searchResults.length" class="loadingMessage">{{$t('general.no_results')}}</div>
            <div v-if="!data" class="loadingMessage">{{$t('general.loading')}}</div>
            <data-table v-if="data && (!searchWord || (searchWord && searchResults.length))" :items="data" :headers="columns" :editDelete="true" page="organizations" :editCallback="editCallback">
            </data-table>
          </card>
        </div>
      </div>
    </div>
  </div>
  <DelModal :onClickQuit="quitDelete" :onClickDelete="confirmDelete" :title="$t('organizations.delete')"></DelModal>
  <CreateEditOrganization ref="createEditOrganization" :onClickQuit="quitCreateEdit" :parentData="myForm" :onFormValidated="onFormValidated"></CreateEditOrganization>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import OrgService from 'src/services/organization'
  import SignedService from 'src/services/signed'
  import DataTable from 'src/components/DataTable.vue'
  import DelModal from 'src/components/DelModal.vue'
  import CreateEditOrganization from 'src/components/CreateEditOrganization.vue'
  import Swal from 'sweetalert2'
  import Utils from 'src/mixins/utils'

  export default {
    mixins: [Utils],
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable,
      DelModal,
      CreateEditOrganization
    },
    data () {
      return {
        columns: [
          { text: this.$t('organizations.table.name'), align: 'left', value: 'name' },
          { text: this.$t('organizations.table.subdomain'), value: 'subdomain' },
          { text: this.$t('organizations.table.usedLicenses'), value: 'formattedLicenses' },
          { text: this.$t('organizations.table.createdAt'), value: 'createdAt' },
          { text: this.$t('organizations.table.trialEndsAt'), value: 'trialEndsAt' }
        ],
        data: null,
        organizations: [],
        trialData: ['Não', 30, 45, 60],
        myForm: '',
        searchWord: '',
        searchResults: []
      }
    },
    methods: {
      searchInputValue(e) {
        let results = [];
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.organizations.map( (line, i) => {
        let matchName = line.name.toUpperCase().includes(this.searchWord);
        let matchSubdomain = line.subdomain.toUpperCase().includes(this.searchWord);
          if(matchName || matchSubdomain) {
            results.push(this.organizations[i]);
          }
        });
        this.searchResults = results;
        this.data = this.searchResults;
      },

      loadOrganizations() {
        document.getElementById("createEditOrganization").classList.add("invisible");
        OrgService.listOrganizations()
          .then(result => {
            result.forEach((item) => {
              // Sum up used/total for all license types, so that we can show it as a summary
              // in the organziations table
              let totalTotal = 0;
              let totalUsed = 0;
              if( item.licenses.length > 0 ){
                totalTotal = item.licenses
                .map( function(license){ return license.total } )
                .reduce( function(total, num){ return total + num } );

                totalUsed = item.licenses
                .map( function(license){ return license.used } )
                .reduce( function(total, num){ return total + num } );
              }

              item.formattedLicenses = totalUsed+"/"+totalTotal;
            });
            this.organizations = result
            this.data = result
          })
          .catch(err => {
            console.error(err)
            Swal.fire({
              title: 'Error!',
              text: 'Organizations not found',
              type: 'error',
              confirmButtonText: 'Ok'
            })
          })
      },
      createOrganization(){
        this.$router.push({name: 'OrganizationCreate'});
      },
      quitDelete() {
        document.getElementById("deleteModal").classList.add("invisible");
      },
      confirmDelete() {
        let results = [];
        this.organizations.map((row, index) => {
          if(row.publicId != document.getElementById("elementToBeDeleted").dataset.publicId){
            results.push(this.organizations[index]);
          } else {
            OrgService.deleteOrganization(row.publicId)
            .catch(err => {
              if(err[0] == 400 || err[0] == 500){
                console.error(err)
                Swal.fire({
                  title: 'Error!',
                  text: 'The application failed to delete the organization. Try again.',
                  type: 'error',
                  confirmButtonText: 'Ok',
                  onBeforeOpen: () => {
                    Swal.hideLoading();
                  }
                })
              }

              if(err[0] === 422) {
                let stringError = '';
                for(let message of err[1].errors){
                  stringError += message + '<br/>'
                }
                console.error(err)
                Swal.fire({
                  title: 'Error!',
                  html: stringError,
                  type: 'error',
                  confirmButtonText: 'Ok',
                  onBeforeOpen: () => {
                    Swal.hideLoading();
                  }
                })
              }
            })
            .then(() => {
                this.quitDelete();
                Swal.fire({
                    title: 'Organization Deleted!',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    onBeforeOpen: () => {
                      Swal.hideLoading();
                    }
                })
                this.loadOrganizations();
            })
          }
        })
        this.organizations = results;
        this.searchInputValue();
        document.getElementById("deleteModal").classList.add("invisible");
      },
      quitCreateEdit() {
        this.$refs.createEditOrganization.resetForm();
        document.getElementById("createEditOrganization").classList.add("invisible");
        document.getElementById("createEditForm").reset();
        document.getElementById('organizations').style.display = "block";
        this.$refs.createEditOrganization.resetData();
      },
      onFormValidated: async function( formData, edit=true, publicId=null ) {
        try {
          Swal.fire({
            title: 'Submitting',
            onOpen: () => {
              Swal.showLoading();
            }
          });
          if (formData.imgChosen) {
            let uploaded = await this.uploadImage(formData);
            if (uploaded) {
              formData.loginLogoUrl = formData.homeLogoUrl = uploaded;
              delete formData.cropImg;
            }
          } else {
            await OrgService.editOrganization(publicId, formData);
            this.quitCreateEdit();
          }

          await OrgService.editOrganization(publicId, formData);
          this.quitCreateEdit();

          Swal.fire({
              title: 'Organization Updated!',
              type: 'success',
              showConfirmButton: false,
              timer: 1000,
              onBeforeOpen: () => {
                Swal.hideLoading();
              }
          })
          this.loadOrganizations();
        } catch (e) {
          let error = e[1].errors[0];
          console.error(e)
          Swal.fire({
              title: 'Error!',
              text: error,
              type: 'error',
              confirmButtonText: 'Ok',
              onBeforeOpen: () => {
                  Swal.hideLoading();
              }
          });
        }
      },
      async uploadImage( formData ){
          try{
              let params = {
                  name: formData.subdomain,
                  fileName: 'login_logo.png',
                  content: formData.cropImg,
                  fileType: 'image/png',
                  url: '',
                  orgImage: true
              }
              params.url = await SignedService.upload(params);
              let blobData = this.dataURItoBlob(params.content);
              let uploaded = await this.uploadToS3(params.url, blobData, params.fileType);
              let fileUrl = uploaded.url.split("?")[0];
              return fileUrl;
          }catch(err){
              return false;
          }
      },

      dataURItoBlob(dataURI) {
          var binary = atob(dataURI.split(',')[1]);
          var array = [];
          for(var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i));
          }
          return new Blob([new Uint8Array(array)], {type: 'image/png'});
      },
      editCallback(item) {
        this.$refs.createEditOrganization.editItem(item)
        document.getElementById("createEditOrganizationTitle").innerHTML = this.$i18n.t('organizationCreate.editTitle')
        document.getElementById('createEditOrganization').classList.remove("invisible");
        document.getElementById('organizations').style.display = "none";
      }
    },
    mounted () {
      this.loadOrganizations();
    }
  }
</script>


<style>
  .form-group {
    margin: 0;
  }

  .searchContainer{
      float: right;
      margin-top: -50px;
      max-width: 300px;
      margin-bottom: 10px;
    }

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _c("div", { staticClass: "col-6 pull-left" }, [
              _vm._v(_vm._s(_vm.$t("general.error_message_title")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "pull-right",
                    on: {
                      click: function($event) {
                        _vm.dialog = false
                      }
                    }
                  },
                  [_vm._v("close")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-card-title", { staticClass: "headline" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message,
                    expression: "message"
                  }
                ],
                staticClass: "form-control",
                staticStyle: { "min-height": "180px" },
                attrs: {
                  readonly: true,
                  type: "text",
                  id: "errorMessage",
                  name: "errorMessage"
                },
                domProps: { value: _vm.message },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.message = $event.target.value
                  }
                }
              })
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("center", { staticStyle: { "padding-top": "80px" } }, [
    _c("div", { staticClass: "topBar" }, [
      _c("img", {
        staticStyle: { height: "40px" },
        attrs: { src: _vm.organizationLogo }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "o-page c-login-page" }, [
      _c("div", { staticClass: "c-login-page__content" }, [
        _c("div"),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticStyle: { "max-width": "320px", "margin-bottom": "20px" },
            attrs: { src: require("../assets/imgs/form-header.png") }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "logo_container" }, [
          _c("img", { attrs: { src: _vm.organizationLogo } })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "login_form",
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.submitForm($event)
              }
            }
          },
          [
            _c("div", [
              _c("div", [
                _c("h2", { staticClass: "title text-danger" }, [
                  _vm._v(_vm._s(_vm.$t("notFoundPage.title")))
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("notFoundPage.description")))
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "baseButton", on: { click: _vm.gotoHome } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("notFoundPage.button_label")) +
                        "\n              "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "c-login-page__footer__text" }, [
                _vm._v(_vm._s(_vm.$t("general.copyright")))
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="createEditOrganization" class="content screen-area">
      <div class="container-fluid ">
        <div class="row">
          <input
            ref="input"
            type="file"
            name="image"
            accept="image/*"
            @change="setImage"
          />

          <div class="col-12">
            <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
              <h4 id="createEditOrganizationTitle" class="mb-3"></h4>
              <form v-on:submit.prevent id="createEditForm" action="" method="post">
                  <div class="mb-3">
                      <label class="input-label" for="fieldName">{{$t('organizationCreate.form.name')}}</label>
                      <input v-model="nameValue" type="text" id="fieldName" name="name" class="form-control" required />
                  </div>
                  <div class="mb-3">
                      <label class="input-label" for="fieldSubdomain">{{$t('organizationCreate.form.subdomain')}}</label>
                      <input :readonly="isEdit ? true : false" v-model="subdomainValue" type="text" id="fieldSubdomain" name="subdomain" class="form-control" required />
                  </div>
                  <div class="mb-3" v-if="comingFromMyOrgPage">
                      <label class="input-label" for="fieldEmailDomains">{{$t('organizationCreate.form.domain')}}</label><br/>
                      <v-combobox
                        v-model="domains"
                        label="drumwave.com"
                        clearable
                        solo
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            @input="removeDomain(data.item)"
                          >
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                  </div>
                  <div class="mb-3" v-if="isActionAllowed('licensesManagement')" style="max-width: 200px;">
                      <label class="input-label" for="fieldTrialEndsAt">{{$t('organizationCreate.form.trialEndsAt')}}</label>
                      
                      <v-switch
                        v-model="trialNeverEnds"
                        :label="`Trial never ends`"
                      ></v-switch>

                      <v-menu
                        v-model="trialEndsAtMenu"
                        v-if="!trialNeverEnds"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="trialEndsAtValue"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            id="fieldTrialEndsAt"
                            name="trialEndsAt"
                          ></v-text-field>
                        </template>
                        
                        <v-date-picker v-model="trialEndsAtValue" @input="trialEndsAtMenu = false" />
                      </v-menu>
                  </div>
                  <div class="mb-3" v-if="isActionAllowed('licensesManagement') && !comingFromMyOrgPage">
                      <label class="input-label" for="fieldLicenses">{{$t('organizationCreate.form.licenses')}}</label><br/>
                      <div v-for="item in parsedLicenses">
                        <input  min="0" max="999" v-model="item.total" type="number"  class="form-control micro licenseNumberInput" required /> {{item['license-type'].name}} accounts
                      </div>
                  </div>
                  <hr/>
                  <div class="mb-3">
                    <label class="input-label" for="fieldLogo">{{$t('organizationCreate.form.logoLogin')}}</label>
                    <div class="mb-3">
                      <img v-show="imgSrc && !imgChosen" :src="imgSrc" style="width: 50%;"/>
                    </div>
                    <vue-cropper
                      v-show="imgSrc && imgChosen"
                      ref="cropper"
                      :src="imgSrc"
                      alt="Source Image"
                      :cropend="cropImage"
                      :cropmove="cropMoveEvent"
                      :width="'100%'"
                    >
                    </vue-cropper>
                    <div class="subNote logoSize" v-bind:class="{logoSizeOk: sizeOk}" v-show="imgChosen">{{ $t('organizationCreate.form.cropSize') + ': ' + cropSize }}</div>
                    <div>
                        <BaseButton style="" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.choose_image')" :onClick="showFileChooser"></BaseButton>
                    </div>
                    <div class="subNote" v-html="$t('organizationCreate.form.logoSize')"></div>
                  </div>
                  <hr class="mb-4">
                    <BaseButton style="float: right; color: green; border-color: green" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.confirm')" :onClick="validateForm" buttonType="submit"></BaseButton>
                    <BaseButton style="float: right" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.cancel')" :onClick="onClickQuit"></BaseButton>
                </form>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
  import BaseButton from './BaseButton.vue'
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import Swal from 'sweetalert2'
  import Utils from "src/mixins/utils"
  import OrgService from 'src/services/organization'
  import moment from 'moment'

  export default {
    mixins: [Utils],
    name: 'CreateEditOrganization',
    components: {
      'BaseButton': BaseButton,
      VueCropper
    },
    data() {
      return {
        nameValue: '',
        subdomainValue: '',
        trialNeverEnds: true,
        trialEndsAtValue: '',
        logoLoginValue: '',
        logoHomeValue: '',
        seatsValue: 0,
        publicId: '',
        isEdit: false,
        imgSrc: '',
        imgChosen: false,
        cropImg: null,
        cropSize: '',
        sizeOk: false,
        licenses: [],
        parsedLicenses: null,
        licenseTypes:[],
        domains: [],
        trialEndsAtMenu: false
      }
    },
    methods: {
      buildOrganizationLicensesObject(){
        OrgService.listLicenseTypes()
          .then(result => {
            this.licenseTypes = result;

            let parsedLicenses = [];
            let parsedLicensesById = {};

            this.licenseTypes.forEach( function(lType){
              let pLicense = {
                "license-type": {
                  publicId: lType.publicId,
                  name:lType.name
                },
                total: 0,
                used: 0
              };
              parsedLicensesById[lType.publicId] = pLicense;
              parsedLicenses.push( pLicense );
            } );
            this.licenses.forEach( function(license){
              let licenseId = license.licenseTypePublicId;
              let pLicense = parsedLicensesById[licenseId];
              pLicense.total = license.total;
              pLicense.used = license.used;
            });
            this.parsedLicenses = parsedLicenses;
          })
          .catch(err => {
            console.error("API ERROR", err);
          })
      },

      resetData () {
        Object.assign(this.$data, this.$options.data());
        this.buildOrganizationLicensesObject();
      },
      resetForm: function() {
        [...document.getElementsByClassName("form-control")].map( input => {
          input.classList.remove("invalidField");
        });
      },
      async validateForm() {
        Swal.fire({
          title: 'Validating',
          onOpen: () => {
            Swal.showLoading();
          }
        });
        if(this.nameValue == '' || this.subdomainValue == '' /* || this.logoLoginValue == '' || this.logoHomeValue == '' */) return;
        if(this.imgSrc == ''){
          Swal.fire({
            title: 'Error!',
            text: 'You must select a logo image',
            type: 'error',
            confirmButtonText: 'Ok'
          });
          return;
        }
        if( this.imgChosen && !this.sizeOk){
          Swal.fire({
            title: 'Error!',
            text: 'The selected logo image size is too small',
            type: 'error',
            confirmButtonText: 'Ok'
          });
          return;
        }

        let formNode = document.getElementById('createEditForm')
        let formJSON = {}
        for (let i = 0; i < formNode.length; i++) {
          formJSON[formNode[i].name] = formNode[i].value
        }
        if (this.comingFromMyOrgPage) {
          formJSON.emailDomains = this.domains;
        } else {
          formJSON.licenses = this.parsedLicenses.map(function(license){
            return {
              licenseTypePublicId: license['license-type'].publicId,
              quantity: license.total
            };
          });
        }

        formJSON.imgChosen = this.imgChosen;
        formJSON.cropImg = this.cropImg;
        formJSON.loginLogoUrl = this.logoLoginValue;
        formJSON.homeLogoUrl = this.logoHomeValue;
        formJSON.trialEndsAt = this.trialNeverEnds ?  null : this.trialEndsAtValue
        this.onFormValidated( formJSON, this.isEdit, this.publicId );
      },

      editItem(item){
        let scoper = this;
        OrgService.getOrganization( item.publicId )
        .then(result => {
            scoper.isEdit = true;
            scoper.nameValue = result.name;
            scoper.trialNeverEnds = result.trialEndsAt == null;
            scoper.trialEndsAtValue = result.trialEndsAt != null ? moment(result.trialEndsAt).format('YYYY-MM-DD') : null;
            scoper.subdomainValue = result.subdomain;
            scoper.logoLoginValue = result.loginLogoUrl;
            scoper.logoHomeValue = result.homeLogoUrl;
            scoper.publicId = result.publicId;
            scoper.seatsValue = result.seats;
            scoper.licenses = result.licenses;
            scoper.imgSrc = scoper.logoLoginValue;
            scoper.$refs.cropper.replace(scoper.logoLoginValue);
            scoper.parsedLicenses = scoper.buildOrganizationLicensesObject();
            scoper.domains = result.emailDomains ? result.emailDomains : [];
        });
      },
      setImage(e) {
        const file = e.target.files[0];

        if (file.type.indexOf('image/') === -1) {
          alert('Please select an image file');
          return;
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          let scoper = this;

          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result);
            this.imgChosen = true;
            setTimeout(function(){
              scoper.cropImage();
            }, 500);
          };

          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      showFileChooser() {
        this.$refs.input.click();
      },
      cropImage() {
        this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.updateCropSize()
      },
      cropMoveEvent(e){
        this.updateCropSize();
      },
      updateCropSize(){
        var cropData = this.$refs.cropper.getData();
        this.cropSize = Math.floor(cropData.width) + ' x ' + Math.floor(cropData.height) + ' px';
        this.sizeOk = cropData.width >= 600 && cropData.height >= 100;
      },
      removeDomain (item) {
        this.domains.splice(this.domains.indexOf(item), 1)
        this.domains = [...this.domains]
      }
    },

    mounted(){
      this.buildOrganizationLicensesObject();

    },
    props: {
      onClickQuit: {
        type: Function,
        required: true
      },
      onFormValidated: {
        type: Function,
        required: true
      },
      comingFromMyOrgPage: {
        type: Boolean,
        default: false,
        required: false
      }
    }
  }
</script>


<style scoped lang="scss">
#createEditOrganization{
    color: #999;
    padding: 0px 30px;
}
.modal-container {
  display: block;
  padding-left: 10px
}
.invisible {
     display: none;
 }
 input[type="file"] {
  display: none;
}
.subNote{
  color: #999;
}
.logoSize{
  color: #e03030;
  text-align: right;
}
.logoSizeOk{
  color: #509050 !important;
}
.licenseNumberInput{
  display: inline-block;
  margin: 0px 10px 10px 0px;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mainContainer" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "titleValue" }, [
        _vm._v(_vm._s(_vm.dataList.length))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "titleName" }, [_vm._v(" " + _vm._s(_vm.title))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "listContainer" },
      _vm._l(_vm.dataList.slice(0, 1000), function(item) {
        return _c("div", { staticClass: "listItem" }, [
          _c("div", { staticClass: "listItemName" }, [
            _vm._v(_vm._s(item.name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "listItemDate" }, [
            _vm._v(_vm._s(_vm.moment(item.updatedAt).calendar()))
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="!items" class="loadingMessage">{{$t('general.loading')}}</div>
    <v-data-table
      v-if="items"
      :headers="headers"
      :items="items"
      :pagination.sync="pagination"
      :rows-per-page-items="rowsPerPageItems"
      select-all
      item-key="name"
      class="walletTable"
    >
      <template v-slot:headers="props">
        <tr>
          <th
            v-for="header in props.headers"
            :class="['column', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.sortable === false ? '' : 'sortable']"
            @click="if( header.sortable!==false ) changeSort(header.value)"
          >
            <v-icon small v-if="header.sortable!==false">arrow_upward</v-icon>
            {{ header.text }}


            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon small v-if="header.tooltip" data-toggle="tooltip" data-placement="top" style="opacity: unset; transition: none;">info</v-icon>
                  </span>
              </template>
              <div v-html="header.tooltipText"  style="padding: 15px;"/>
            </v-tooltip>


          </th>
        </tr>
      </template>
      <template v-slot:items="props">
        <tr>
          <td v-for="(header) in headers" :key="props.item.publicId+header.value">
            <div v-if="header.value=='dataActions'" :style="'padding-left: '+(header.sortable===false?0:20)+'px;'">
              <span>
                <DataActions 
                  :item="props.item" 
                  :transferCallback="transferCallback" 
                  :deleteItemCallback="deleteCallback" 
                  :embedCallback="embedCallback"
                  :dataProductCallback="dataProductCallback"
                  :postCallback="postCallback" 
                  :unpostCallback="unpostCallback" 
                  :borrowCallback="borrowCallback" 
                  :unborrowCallback="unborrowCallback" 
                  :dataProductStatusChangedCallback="dataProductStatusChangedCallback"
                  :buyDataProductCallback="buyDataProductCallback"
                  :canTransfer="canTransfer(props.item)" 
                  :isCurrentUser=" props.item.isCurrentUser" 
                  :page="page" 
                />
              </span>
            </div>
            <div v-else-if="props.item[header.value] != null" :style="'padding-left: '+(header.sortable===false?0:20)+'px;'">
              <a v-if="linkBuilder && linkBuilder(header.value, props.item)" :href="linkBuilder(header.value, props.item)" :target="linkTarget">
                <span v-if="checkData(props.item[header.value]) === true">{{convertData(props.item[header.value])}}</span>
                <span v-else-if="header.value=='dataApps'">
                  <DataApps :item="props.item" />
                </span>
                <span v-else>
                  {{props.item[header.value]}}
                </span>
              </a>
              <span v-else>
                  <span v-if="checkData(props.item[header.value]) === true">{{convertData(props.item[header.value])}}</span>
                  <span v-else-if="header.value=='dimScoreTotal'">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <div v-on="on" class="dimColumnText">
                          <span>{{props.item[header.value]}}</span>
                          </div>
                      </template>
                      <span><b>D</b>: {{props.item.dimScore.D.total.toFixed(2)}}</span> &nbsp;&nbsp;
                      <span><b>I</b>: {{props.item.dimScore.I.total.toFixed(2)}}</span> &nbsp;
                      <span><b>M</b>: {{props.item.dimScore.M.total.toFixed(2)}}</span>
                    </v-tooltip>

                  </span>
                  <span v-else-if="header.value=='pii'">
                    <img :src="getPIIIcon(props.item)" class="piiIcon"/>
                  </span>
                  <span v-else-if="header.value=='dataApps'">
                    <DataApps :item="props.item" />
                  </span>
                  <span v-else-if="header.value=='wrangler'" :class="{ bolded: props.item.isCurrentUser }">
                    {{props.item[header.value]}}
                  </span>
                  <span v-else-if="header.value=='size' || header.value=='numberOfCells' || header.value=='numberOfDatasets'">
                    {{ abbreviateNumber( props.item[header.value] ) }}
                  </span>
                  <span v-else 
                    :style="(props.item['type'] === 'datasource' && props.item['status'] == 'error') ? { color: 'red', cursor: 'pointer' } : {color: 'black'} "
                    @click="(props.item['type'] === 'datasource' && props.item['status'] == 'error') ? errorMessage(props.item) : ''"
                  >
                    {{props.item[header.value]}}
                  </span>
              </span>
            </div>
            <div class="empty-field" :style="'padding-left: '+(header.sortable===false?0:20)+'px;'" v-else>
              -
            </div>
          </td>
          <td v-if="editDelete == true" class="justify-center layout">
            <v-icon
              v-if="props.item.updateAllowed"
              small
              class="mr-2"
              @click="editItem(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              v-if="props.item.deleteAllowed"
              small
              @click="deleteItem(props.item)"
            >
              delete
            </v-icon>
            <i v-if="props.item.deletedAt != null"
              class="fa fa-arrow-circle-up v-icon v-icon mr-2 theme--ligh v-icon--link"
              style="font-size: 16px"
              aria-hidden="true"
              @click="reactiveItem(props.item)"
            >
            </i>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>


<script>
  import Utils from '../mixins/utils';
  import DataActions from './DataActions.vue'
  import DataApps from './DataApps.vue'
  import moment from 'moment';

  const config = require('config');

  const YesIcon = require('../assets/imgs/yes_icon.png');
  const NoIcon = require('../assets/imgs/no_icon.png');
  const UnknownIcon = require('../assets/imgs/unknown_icon.png');

  export default {
    components: {
      DataActions,
      DataApps
    },
    mixins: [Utils],
    name: 'data-table',
    props: {
      defaultSortBy: {
        type: String,
        defaut: 'name'
      },
      sortDesc: Boolean,
      headers: {
        type: Array
      },
      items: {
        type: Array
      },
      editDelete: Boolean,
      namesToLinks: Boolean,
      page: String,
      linkBuilder: Function,
      linkTarget: String,
      editCallback: Function,
      postCallback: Function,
      unpostCallback: Function,
      borrowCallback: Function,
      unborrowCallback: Function,
      transferCallback: Function,
      deleteCallback: Function,
      embedCallback: Function,
      dataProductCallback: Function,
      errorMessageCallback: Function,
      dataProductStatusChangedCallback: Function,
      buyDataProductCallback: Function
    },
    data() {
      return {
        rowsPerPageItems: [10, 50, 100],
        pagination: {
          sortBy: this.defaultSortBy || 'name',
          descending: this.sortDesc,
          rowsPerPage: 100,
        }
      }
    },

    methods: {
      toggleAll () {
        if (this.selected.length) this.selected = []
        else this.selected = this.desserts.slice()
      },
      changeSort (column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      },
      editItem (item) {
        this.editCallback(item);
      },
      deleteItem (item) {
        document.getElementById("elementToBeDeleted").dataset.publicId = item.publicId;
        document.getElementById("elementToBeDeleted").innerHTML = item.name ? item.name : item.email;
        document.getElementById('deleteModal').classList.remove("invisible");
      },
      reactiveItem (item) {
        document.getElementById("elementToBeReactivated").dataset.publicId = item.publicId;
        document.getElementById("elementToBeReactivated").innerHTML = item.name ? item.name : item.email;
        document.getElementById('reactivateModal').classList.remove("invisible");
      },
      checkData (date) {
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]+)?Z$/.test(date)) {
          return true
        } else {
          return false
        }
      },
      errorMessage(item) {
        this.errorMessageCallback(item);
      },
      convertData (date) {
        let dateParse = new Date(date);
        let locale = moment(dateParse).format('DD MMM YYYY');
        return(locale);
      },
      canTransfer(item) {
        return (this.page == 'dwallet' || this.page == 'exchange' || this.page == 'orgLibrary')
            && (item.type == "dataset" || item.type == "view")
            && item.isCurrentUser;
      },
      getDIMColor(item){
        if( item.dimScoreTotal >= 0.5 )
          return "#FFDE17";
        else if( item.dimScoreTotal >= 0.02 )
          return "#E88F77";
        else if( item.dimScoreTotal >= 0.01 )
          return "#D1D3D4";
        else
          return "#000000";
      },
      getPIIIcon( item ){
        if( item.pii == 'yes' )
          return YesIcon;
        else if( item.pii == 'no' )
          return NoIcon;
        else
          return UnknownIcon;

      }
    }
  }
</script>

<style>
  .loadingMessage{
    text-align: center;
    font-weight: bold;
    color: #666;
    margin-top: 60px;
    margin-bottom: 100px;
  }
  table.v-table tbody td, table.v-table thead th{
    font-size: 14px;
  }
  .bolded{
    font-weight: bold;
  }
  .piiIcon{
    width: 16px;
  }
  .v-tooltip__content {
    background: #ffffff;
    color: #666;
    max-width: 400px;
    /* pointer-events: none; */
    opacity: 1 !important;
    -webkit-box-shadow: 2px 2px 14px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 14px -4px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 14px -4px rgba(0,0,0,0.75);
  }
  .v-tooltip__content:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 5px;
    margin-top: -5px;
  }

  .walletTable table th, .walletTable table td {
      padding: 0px 10px !important;
  }

  .v-tabs__item:not(.v-tabs__item--active) {
      opacity: 0.3;
      border-bottom: solid 1px #999;
  }
  .v-tabs__item:not(.v-tabs__item--active):hover {
      opacity: 0.7;
  }

  .dimColumnText{
    font-size: 16px;
    color: #EC008C;
    font-family: Roboto;
    font-weight: 400;
    width: min-content;
  }


</style>

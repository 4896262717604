var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _c("div", { staticClass: "col-6 pull-left" }, [
              _vm._v(_vm._s(_vm.$t("dwallet.public_access.title", [_vm.name])))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "pull-right",
                    on: {
                      click: function($event) {
                        _vm.dialog = false
                      }
                    }
                  },
                  [_vm._v("close")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-card-title", { staticClass: "headline" }, [
            _c("div", { staticClass: "col-12" }, [_vm._v("Link")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.publicLink,
                    expression: "publicLink"
                  }
                ],
                staticClass: "form-control medium",
                attrs: {
                  readonly: true,
                  type: "text",
                  id: "publicLink",
                  name: "publicLink"
                },
                domProps: { value: _vm.publicLink },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.publicLink = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2" }, [
              _c("img", {
                staticStyle: {
                  height: "20px",
                  "margin-left": "10px",
                  opacity: "0.6",
                  cursor: "pointer"
                },
                attrs: {
                  src: require("../assets/imgs/dataApps/copy-to-clipboard.png"),
                  title: "Copy"
                },
                on: { click: _vm.copyLink }
              }),
              _vm._v(" "),
              _c("span", {
                staticStyle: { "vertical-align": "super", "font-size": "12px" },
                attrs: { id: "copiedLinkToClipboard" }
              })
            ])
          ]),
          _vm._v(" "),
          _c("v-card-title", { staticClass: "headline" }, [
            _c("div", { staticClass: "col-12" }, [_vm._v("Embed Code")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-10" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.embedCode,
                    expression: "embedCode"
                  }
                ],
                staticClass: "form-control medium",
                staticStyle: { "min-height": "180px" },
                attrs: {
                  readonly: true,
                  type: "text",
                  id: "embedCode",
                  name: "embedCode"
                },
                domProps: { value: _vm.embedCode },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.embedCode = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2" }, [
              _c("img", {
                staticStyle: {
                  height: "20px",
                  "margin-left": "10px",
                  opacity: "0.6",
                  cursor: "pointer"
                },
                attrs: {
                  src: require("../assets/imgs/dataApps/copy-to-clipboard.png"),
                  title: "Copy"
                },
                on: { click: _vm.copyCode }
              }),
              _vm._v(" "),
              _c("span", {
                staticStyle: { "vertical-align": "super", "font-size": "12px" },
                attrs: { id: "copiedCodeToClipboard" }
              })
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }